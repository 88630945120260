<template>
  <!-- 消息物流填入弹出框 -->
  <div id="logistics-dialog">
    <el-drawer v-model="newAffairVisible" :before-close="beforeClose" :title="$t('ClienteleQuery.fillInLogistics')" :size="pcMediaSize?600:'100%'">
 
      <el-form :rules="rules" ref="ruleForm" :model="newAffairForm" label-width="130px" label-position="top">
        <el-form-item :label="$t('ClienteleQuery.afterOrderNumber')">
          <el-input v-model="newAffairForm.afterOrderNumber" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item :label="$t('ClienteleQuery.ditchStatus')" prop="ditchStatus">
          <el-select filterable v-model="newAffairForm.ditchStatus" :placeholder="$t('ClienteleQuery.ditchStatusPlaceholder')" :disabled="true" style="width: 100%">
            <el-option v-for="item in selectData.ditchStatusList" :key="item.dictValue" :label="item.dictKey" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('ClienteleQuery.logistics')" prop="expressage" v-if="signForVisible">
          <el-input v-model="newAffairForm.expressage" :placeholder="$t('ClienteleQuery.logisticsPlaceholder')" :readonly="isReadonly"></el-input>
        </el-form-item>
        <el-form-item :label="$t('ClienteleQuery.logisticsNumber')" prop="expressageNumber" v-if="signForVisible">
          <el-input v-model="newAffairForm.expressageNumber" :placeholder="$t('ClienteleQuery.logisticsNumberPlaceholder')" :readonly="isReadonly"></el-input>
        </el-form-item>
        <el-form-item :label="$t('ClienteleQuery.sendoutDate')" prop="sendoutDate" v-if="signForVisible">
          <el-date-picker v-model="newAffairForm.sendoutDate" :placeholder="$t('ClienteleQuery.sendoutDatePlaceholder')" :disabled="isReadonly" type="date" :format="dateFormat" value-format="YYYY-MM-DD" style="width: 100%"/>
        </el-form-item>
        <el-form-item :label="$t('ClienteleQuery.signDate')" prop="sendoutDate" v-if="!signForVisible">
          <el-date-picker v-model="newAffairForm.sendoutDate" :placeholder="$t('ClienteleQuery.signDatePlaceholder')" :disabled="isReadonly" type="date" :format="dateFormat" value-format="YYYY-MM-DD" style="width: 100%"/>
        </el-form-item>
        <el-form-item :label="$t('ClienteleQuery.remarks')">
          <el-input type="textarea" v-model="newAffairForm.remarks" :placeholder="$t('ClienteleQuery.remarksPlaceholder')" :readonly="isReadonly"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="newAffairClick">{{ $t('common.button.back') }}</el-button>
        <el-button type="primary" @click="newAffairAdd" v-if="!isReadonly && signForVisible">{{ $t('common.button.submit') }}</el-button>
        <el-button type="primary" @click="newsignFor" v-if="!signForVisible">{{ $t('common.button.sign') }}</el-button>
      </span>
      </template>
    </el-drawer>
  </div>
</template>

<script>

import {getAffairDetailByIdClientele, saveAffairClientele, signForClientele} from "@/http/authApi/clienteleApi";
import {ElNotification} from "element-plus";
import {getNewYmdStr} from "@/util/DateUtil";
import {getDictListByCode} from "@/util/longin";
import {formatByLanguage} from "@/util/system";
import { useMedia } from 'vue-hooks-plus'

export default {
  name: "logistics-dialog",
  // data: 对象就是要渲染到页面上的数据
  data: function () {
    return {
      dateFormat: formatByLanguage(),
      newAffairVisible: false,
      isFormReadonly: true,
      isReadonly: false,
      pcMediaSize:useMedia(['(min-width: 768px)'], [true], false),
      row: {},
      selectData: {
        ditchStatusList: [],
      },
      newAffairForm: {
        createStatus: 1,
        affairId: 0,
        affairDetailId: 0,
        afterOrderNumber: '',
        ditchStatus: '',
        expressage: '',
        expressageNumber: '',
        sendoutDate: getNewYmdStr(new Date()),
        remarks: '',
      },
      // 表单验证规则
      rules: {
        ditchStatus: [
          {required: true, message: this.$t('ClienteleQuery.ditchStatusPlaceholder'), trigger: 'change'},
        ],
        expressage: [
          {required: true, message: this.$t('ClienteleQuery.logisticsPlaceholder'), trigger: 'blur'},
        ],
        expressageNumber: [
          {required: true, message: this.$t('ClienteleQuery.logisticsNumberPlaceholder'), trigger: 'blur'},
        ],
        sendoutDate: [
          {required: true, message: this.$t('ClienteleQuery.sendoutDatePlaceholder'), trigger: 'change'},
        ],
      },
      // 签收表单隐藏
      signForVisible: true,
    }
  },

  // moubted: 页面加载完毕后执行
  mounted() {

  },

  // methods: 对象中的方法，可以在页面中调用
  methods: {
    // 获取下拉数据
    async getSelectData() {
      // 事件状态
      this.selectData.ditchStatusList = await getDictListByCode('GDWX');
      // getDictPullDownClientele({dictCode: 'GDWX'}).then(res => {
      //   if (res.data.status === 200) {
      //     this.selectData.ditchStatusList = res.data.data;
      //   }
      // }).catch((erro) => {
      // });
    },

    // 打开物流填入框
    goAffairClick() {
      this.newAffairForm.affairId = this.row.affairId;
      this.newAffairForm.flowId = this.row.id;
      this.newAffairForm.ditchStatus = 'GDWX-002';
      this.newAffairForm.afterOrderNumber = this.row.afterOrderNumber;
      this.getSelectData();
    },
    // 提交用户的物流信息
    newAffairAdd() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.newAffairForm.expressageStatus = 1;
          this.newAffairForm.createStatus = 1;
          saveAffairClientele(this.newAffairForm).then(res => {
            if (res.data.status === 200) {
              // ElNotification({title: this.$t('common.message.successTitle'), message: res.data.msg, type: 'success',});
              this.$emit('childClick');
              this.newAffairClick();
            }
          }).catch((erro) => { });
        } else {
          return false;
        }
      });
    },

    // 查看物流信息
    lookLogistics() {
      this.newAffairForm.affairDetailId = this.row.affairDetailId;
      this.getSelectData();
      this.isReadonly = true;
      // 根据id查询物流信息
      getAffairDetailByIdClientele({affairDetailId: this.newAffairForm.affairDetailId}).then(res => {
        if (res.data.status === 200) {
          this.newAffairForm = res.data.data;
          this.newAffairForm.afterOrderNumber = this.row.afterOrderNumber;
        }
      });
    },

    // 打开签收框
    signForClick() {
      this.getSelectData();
      this.isReadonly = false;
      this.signForVisible = false;
      this.newAffairForm.afterOrderNumber = this.row.afterOrderNumber;
      this.newAffairForm.ditchStatus = 'GDWX-007';
      this.newAffairForm.affairDetailId = this.row.affairDetailId;
      this.newAffairForm.affairId = this.row.affairId;
      this.newAffairForm.expressage = null;
      this.newAffairForm.expressageNumber = null;
    },
    // 签收
    newsignFor() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          signForClientele(this.newAffairForm).then(res => {
            if (res.data.status === 200) {
              // ElNotification({title: this.$t('common.message.successTitle'), message: res.data.msg, type: 'success',});
              this.$emit('childClick');
              this.newAffairClick();
            }
          }).catch((erro) => { });
        } else {
          return false;
        }
      });
    },

    // 点击遮罩和x时执行
    beforeClose(done) {
      this.newAffairClick();
      done(); // 必须调用 done，以继续关闭对话框
    },
    // 关闭
    newAffairClick() {
      this.newAffairVisible = false;
      this.isReadonly = false;
      this.newAffairForm = {
        createStatus: 1,
        affairId: 0,
        affairDetailId: 0,
        afterOrderNumber: '',
        ditchStatus: '',
        expressage: '',
        expressageNumber: '',
        sendoutDate: getNewYmdStr(new Date()),
        remarks: '',
      };
      this.$refs.ruleForm.resetFields(); // 重置表单验证
      // 延迟1秒执行
      setTimeout(() => {this.signForVisible = true;}, 300)
    },
  },
}
</script>

<!--组件css样式 scoped:表示该样式只在此组件生效-->
<style scoped>
/deep/ .el-drawer__header {
  margin-bottom: 0 !important;
}
</style>
