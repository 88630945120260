import request from '../request'

// 删除文件
export function deleteFileStrById(ids) {
    return request({
        url: '/fileStr/deleteFileStrById',
        method: 'post',
        data: ids, // 将ids放入data对象中
    });
}
