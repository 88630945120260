/**
 * 流程回复类型
 * FLOW(1,"回复消息"),
 * FlOW_END(2,"后台消息(仅后台可见)");
 * @type {{}}
 */
export const FlowType = {
    FLOW: 1,
    FlOW_END: 2,
}
