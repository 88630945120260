<template>
  <div class="ticket-detail card-flow">
    <p class="ticket-detail-title">{{$t("ClienteleQuery.divider")}}</p>
    <div class="ticket-detail-info">
      <div
        class="ticket-detail-statusInfo"
        style="border-right: 1px solid #e6e6e6"
      >
        <div style="margin-bottom: 3px">
          {{$t("AfterSpu.afterStatus")}}
        </div>
        <el-tag :type="[,'primary','success','warning','info','danger','info'][ticketInfo.afterStatus]||'danger'">{{ statusText }}</el-tag>
      </div>

      <div class="ticket-detail-createTime">
<!--        <p>{{date}}</p>-->
        <p>{{transformTime(ticketInfo.createTime)}}</p>

      </div>
    </div>

    <div class="ticket-detail-creator">
      <p class="ticket-detail-creator-title" style="font-weight:500">{{$t("UserTicket.userName")}}</p>
      <p class="ticket-detail-stuff-content">
        {{ ticketInfo.familyName }}
      </p>
    </div>
    <div class="ticket-detail-staff">
      <p class="ticket-detail-stuff-title" style="font-weight:500">{{$t("UserTicket.sysUser")}}</p>
      <p class="ticket-detail-stuff-content">
        {{ ticketInfo.disposeUserNmae??'——' }}
      </p>
    </div>

    <div class="ticket-detail-status">
      <p class="ticket-detail-status-title" style="font-weight:500">{{$t("UserTicket.ticketNow")}}</p>
      <p class="ticket-detail-stuff-content">
        {{ statusText }}
      </p>
    </div>

    <!-- <div class="ticket-detail-desc">
                <el-checkbox v-model="checked3" label="Option 1" style=" margin: 0; height: 15px"/>
                Automatically delete attachments when a ticket is closed.
    </div>

    <div class="control-box">

    </div> -->
  </div>
</template>

<script setup>
import { ref, reactive, toRefs, onMounted,computed  } from "vue";
import { useI18n } from "vue-i18n";
import {convertShanghaiTimeToTimeZone, transformTime} from "@/util/zoneDate.js";
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData' // nodejs
import {getShowTicketStatusText,baseStatusList} from '@/components/common/selectTicketStatus/util.js'
dayjs.extend(localeData)

// 获取当前时区
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const { t } = useI18n();
let $t = t;
let props = defineProps({
  ticketInfo: {
    type: Object,
    default: () => ({}),
  },
});
let statusText=computed(()=>{
    let baseList=baseStatusList($t).map(item=>({value:item.id,label:item.name}))
    let text=getShowTicketStatusText(props.ticketInfo.afterStatus,props.ticketInfo.stateId,baseList,props.ticketInfo.stateName)

    return text
})

const date=computed(()=>{
      let createTimeZoneDate= convertShanghaiTimeToTimeZone(props.ticketInfo.createTime, timeZone)
createTimeZoneDate=dayjs(createTimeZoneDate)
let month=dayjs.months()[createTimeZoneDate.month()]
let day=createTimeZoneDate.day()
let year=createTimeZoneDate.year()
let time = createTimeZoneDate.format('HH:mm')
return `${day} ${month} ${year} ${time}`
})
const time=computed(()=>{
  let createTimeZoneDate= convertShanghaiTimeToTimeZone(props.ticketInfo.createTime, timeZone)
  createTimeZoneDate=dayjs(createTimeZoneDate)
  let todayTimeZoneDate= dayjs()

   let weekDiffNum=todayTimeZoneDate.diff(createTimeZoneDate,'week')
   let dayDiffNum=todayTimeZoneDate.diff(createTimeZoneDate,'day')
   let showTime=''
 showTime=  weekDiffNum>4?createTimeZoneDate.format('YYYY-MM-DD HH:mm:ss'):weekDiffNum==0?dayDiffNum>0?`${dayDiffNum} days ago`:createTimeZoneDate.format('YYYY-MM-DD HH:mm:ss'):`${weekDiffNum} weeks ago`
  return showTime
})

</script>
<style scoped lang="scss">
.ticket-detail {
  width: 100%;

  background-color: white;
  border: 1px solid #e6e6e6;
}
.ticket-detail-title {
  width: 100%;
  padding: 5px;
  border-bottom: 1px solid #e6e6e6;
  margin: 0px;
}
.ticket-detail-info {
  display: flex;
  width: 100%;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  > div {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
  }
}
.ticket-detail-creator {
  padding: 10px;
  p {
    margin: 5px;
  }
}
.ticket-detail-staff {
  padding: 10px;
  p {
    margin: 5px;
  }
}

.ticket-detail-status {
  padding: 10px;
  p {
    margin: 5px;
  }
}
.ticket-detail-desc {
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
}
.ticket-detail-stuff-content {
}
</style>
