<template>
  <div class="user-profile card-flow">
    <div class="user-profile-title">
      <p class="user-profile-title-text" style="flex: 1; margin: 0px">
        {{ $t("AfterSpu.familyTitle") }}
      </p>
      <el-tag type="info" v-if="userTicketDetail.accountState === 2">{{
        $t("common.button.Cancelled")
      }}</el-tag>
    </div>
    <div class="user-profile-content">
      <el-avatar :size="90" :src="ticketInfo.userPhotoUrl" />
      <div class="user-profile-content-info">
        <p class="user-profile-content-name">
          {{ userTicketDetail.name }}
        </p>
        <p class="user-profile-content-date" style="margin: 5px 0px">
          {{ $t("UserTicket.userMsg") }}
          <!--          {{ date }}-->
          {{ transformTime(userTicketDetail.registerDate) }}
        </p>
        <p class="user-profile-content-email">
          {{ ticketInfo.email }}
        </p>
      </div>
    </div>
    <div class="ticket-numbers">
      <div class="ticket-numbers-item">
        <p class="ticket-numbers-item-num">{{ userTicketDetail.allNum }}</p>
        <p class="ticket-numbers-item-text">{{ $t("UserTicket.total") }}</p>
      </div>
      <div class="ticket-numbers-item">
        <p class="ticket-numbers-item-num">{{ userTicketDetail.doingNum }}</p>
        <p>{{ $t("UserTicket.open") }}</p>
      </div>
      <div class="ticket-numbers-item">
        <p class="ticket-numbers-item-num">{{ userTicketDetail.endNum }}</p>
        <p>{{ $t("UserTicket.closed") }}</p>
      </div>
    </div>

    <div class="ticket-status-content">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item
          :name="index"
          v-for="(item, index) in ticketStatusGroupData"
          :key="index"
        >
          <template #title>
            <el-tag
              style="color: white; width: 100px"
              :color="
                [
                  ,
                  '#409eff',
                  '#67c23a',
                  '#e6a23c',
                  '#909399',
                  '#f56c6c',
                  '#b1b5f7',
                  '#CBC3F589',
                ][item[0].afterStatus] || '#ecf5ff'
              "
              >{{ statusText(item[0].afterStatus) }}</el-tag
            >
          </template>
          <div class="ticket-status-container">
            <p
              class="ticket-status-container-item"
              v-for="ticket in item"
              :key="ticket.id"
              @click="goToTicketDetail(ticket)"
            >
              <el-icon
                ><OfficeBuilding v-if="ticket.ticketType == 3" /> <User v-else
              /></el-icon>
              <el-divider direction="vertical" />
              {{ ticket.afterOrderNumber }}
            </p>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs, onMounted, computed, defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import {
  convertShanghaiTimeToTimeZone,
  transformTime,
} from "@/util/zoneDate.js";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData"; // nodejs
import { groupByProperty } from "@/util/MyUtil.js";
import { useRouter } from "vue-router";
import { resourceIsUser } from "@/util/resource.js";
import useRouterStore from "@/stores/routerStore.js";
import { ElMessage } from "element-plus";

let routerStore = useRouterStore();

let router = useRouter();
dayjs.extend(localeData);

let emits = defineEmits(["goToTicketDetail"]);

// 获取当前时区
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const { t } = useI18n();
let $t = t;
let props = defineProps({
  ticketInfo: {
    type: Object,
    default: () => ({}),
  },
  AllTicketList: {
    type: Array,
    default: () => [],
  },
  userTicketDetail: {
    type: Object,
    default: () => ({}),
  },
});

const date = computed(() => {
  let createTimeZoneDate = convertShanghaiTimeToTimeZone(
    props.ticketInfo.registerDate,
    timeZone
  );
  createTimeZoneDate = dayjs(createTimeZoneDate);
  let month = dayjs.months()[createTimeZoneDate.month()];
  let day = createTimeZoneDate.day();
  let year = createTimeZoneDate.year();
  let time = createTimeZoneDate.format("HH:mm");
  return `${day} ${month} ${year}`;
});

let ticketStatusGroupData = computed(() => {
  console.log(groupByProperty(props.AllTicketList, "afterStatus"));
  return groupByProperty(props.AllTicketList, "afterStatus");
});

function statusText(afterStatus) {
  return (
    [
      ,
      $t("pullDown.afterStatus.allocation"),
      $t("pullDown.afterStatus.pending"),
      $t("pullDown.afterStatus.processing"),
      $t("pullDown.afterStatus.completed"),
      $t("pullDown.afterStatus.revoked"),
      $t("pullDown.afterStatus.maintain"),
    ][afterStatus] || $t("pullDown.unknown")
  );
}



function goToTicketDetail(ticket) {
  if (ticket.ticketType === 3) {
    if (!resourceIsUser("sidebAfter.select")) {
      ElMessage({
        message: $t("HomeView.logOutDialog.noPageAuth"),
        type: "warning",
      });
      return;
    }
    // routerStore.setParams({
    //     ticket:ticket,
    //     action:'openTicketDetailAction',
    //     actionPagePath:"/business/ticket/list"
    //   } )

    // router.push({
    //   name:'sidebAfter'
    // })

    // 开启只读 | 深拷贝创建了目标对象的一个完整副本
      // 获取当前页面的 URL
      let currentUrl = window.location.origin+"/#";

      // 定义要添加的查询参数
      let id = ticket.id; // 这里替换为你需要的值
      let selcetUrl = ticket.selcetUrl; // 这里替换为你需要的值
      let userId = ticket.userId;
      // 检查当前 URL 是否已经包含查询参数
      currentUrl += `/business/ticket/detail?id=${id}&selcetUrl=${selcetUrl}&userId=${userId}&openTicketWin=true`;

      window.open(currentUrl, "_blank");
  } else {
    emits("goToTicketDetail", ticket);
  }
}
</script>
<style scoped lang="scss">
.user-profile {
  width: 100%;
  background-color: white;
  border: 1px solid #e6e6e6;
}
.user-profile-title {
  width: 100%;
  padding: 5px;
  border-bottom: 1px solid #e6e6e6;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.user-profile-content {
  display: flex;
  width: 100%;
  padding: 10px;
  // justify-content: space-between;
}
.user-profile-content-info {
  width: 100px;
  flex: 1;
  margin: 0px 20px;
  p {
    margin: 0px;
    word-wrap: break-word;
  }
}
.ticket-numbers {
  width: 100%;
  // margin: 0px 10px;
  border-top: 2px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 10px 0px;
  display: flex;
  > div {
    flex: 1;
    text-align: center;
    .ticket-numbers-item-num {
      font-size: 24px;
      font-weight: 500;
    }
    .ticket-numbers-item-text {
    }
    p {
      margin: 0px;
    }
  }
}
.ticket-status-content {
  width: 100%;
  padding: 10px;
}
.ticket-status-container-item {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.ticket-status-container-item:hover {
  border-bottom: 1px solid #e6e6e6;
}
</style>
