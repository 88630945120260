import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import pinia from "@/stores/index.js";
import ElementPlus from 'element-plus';
import "element-plus/dist/index.css";
import 'element-plus/theme-chalk/dark/css-vars.css';
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import axios from 'axios';
// import ECharts from 'vue-echarts'
// import "echarts";
import {createI18n} from "vue-i18n";
import zh from "@/langurage/zh_CN";
import en from "@/langurage/en_US";
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import Prism from 'prismjs';
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import hljs from 'highlight.js';
import VMdPreviewHtml from '@kangc/v-md-editor/lib/preview-html';
import '@kangc/v-md-editor/lib/style/preview-html.css';
// 导入视频播放组件
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
// 代码高亮显示
import 'highlight.js/styles/atom-one-dark.css'
import hljsCommon from 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'
import 'animate.css';
// import 'bootstrap/dist/css/bootstrap.min.css'
// import '@/style/styles.scss'
// Import our custom CSS
import '@/plugin/swiper-bundle.min.css'
import '@/plugin/swiper-bundle.min.js'
import 'element-plus/theme-chalk/display.css'

// Import all of Bootstrap's JS
// import * as bootstrap from 'bootstrap'
// 引入所有自定义指令
import directives from '@/directives/index.js';
import "@/util/autoSize.js"
import "@/style/base.css"
VueMarkdownEditor.use(vuepressTheme, {
    Prism,
});
VMdPreview.use(githubTheme, {
    Hljs: hljs,
});
 
// 注册代码高亮组件
hljsCommon.highlightAuto('<h1>Highlight.js has been registered successfully!</h1>').value


// 组合语言包对象
const messages = {
    en,
    zh
}
// 创建 i18n 实例对象
export const i18n = createI18n({
    legacy: false,  // 设置为 false，启用 composition API 模式
    messages,
    locale: navigator.language // 获取浏览器的语言
})
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
// 注册所有自定义指令
Object.keys(directives).forEach(key => {
    app.directive(key, directives[key]);
});
app.use(router);
app.use(pinia);
app.use(hljsVuePlugin)
app.use(ElementPlus);
app.provide('$axios',axios);
// app.component('ECharts',ECharts);
app.use(i18n);
app.use(VueMarkdownEditor);
app.use(VMdPreview);
app.use(VMdPreviewHtml);
// 视频播放组件
app.use(VueVideoPlayer)
app.mount("#app");
