<template>
  <div class="conversation-item">
    <el-avatar shape="square" :size="60" :src="conversationInfo.createStatus==2?ticketInfo.disposeUserPhotoUrl:ticketInfo.userPhotoUrl" />
    <div class="conversation-info">
      <el-badge :value="$t('common.message.privateMsg')" class="badge-item" :hidden="conversationInfo.flowType != 2" color="rgb(148.6, 212.3, 117.1)">
      <div class="name" :style="{color:conversationInfo.flowType === 2?'#e6a23c':''}">
<!--        {{-->
<!--          ["", conversationInfo.familyName, conversationInfo.disposeUserNmae][-->
<!--            conversationInfo.createStatus-->
<!--          ]-->
<!--        }}-->
        <span v-if="!isZh() && conversationInfo.titleEn">
          {{conversationInfo.titleEn}}
        </span>
        <span v-else>
          {{conversationInfo.title}}
        </span>
      </div>
</el-badge>

      <v-md-preview-html
        class="content"
        :html="(!isZh() && conversationInfo.contentEn) ? conversationInfo.contentEn : conversationInfo.content"
        preview-class="vuepress-markdown-body"
      ></v-md-preview-html>

      <div style="width: 100%;display: flex;margin-top: 10px;">
        <el-link
            type="primary" target="_blank"
            v-for="(path, index) in conversationInfo.imgs"
            download
            :href="HTTP_URL_API+path"
   
        >
          <el-icon><Download /></el-icon>
          {{ $t('file.fromText') }}{{index + 1}}.{{ extractFileExtension(path) }}
               <el-divider direction="vertical" v-if="index != conversationInfo.imgs.length - 1"/>

        </el-link>
      </div>
    </div>

    <p class="conversation-time">{{time}}</p>

    <el-tooltip
        v-if="conversationInfo.flowType === 2"
        class="box-item"
        effect="light"
        :content="$t('common.message.txtFlow')"
        placement="right"
    >
      <el-icon style="margin-left: 10px; margin-top: 5px"><Notification /></el-icon>
    </el-tooltip>
  </div>
</template>

<script setup>
import {computed } from "vue";
import {formatDateTime, transformTimeZone} from "@/util/zoneDate.js";
import {HTTP_URL_API} from "@/enumbag/StyleEnum";
import {extractFileExtension} from "@/util/MyUtil";
import {isZh} from "@/util/system";
import {useI18n} from "vue-i18n";

const { t, locale } = useI18n();
let $t = t;

// 获取当前时区
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
let props = defineProps({
  conversationInfo: {
    type: Object,
    default: () => ({}),
  },
   ticketInfo: {
    type: Object,
    default: () => ({
    })
  },
});

const time=computed(()=>{

  // console.log('时间: ', props.conversationInfo.createTime, formatDateTime(props.conversationInfo.createTime))
  return formatDateTime(props.conversationInfo.createTime)

  // return transformTimeZone(props.conversationInfo.createTime)
//   let createTimeZoneDate= convertShanghaiTimeToTimeZone(props.conversationInfo.createTime, timeZone)
//   createTimeZoneDate=dayjs(createTimeZoneDate)
//   let todayTimeZoneDate= dayjs()
//
//    let weekDiffNum=todayTimeZoneDate.diff(createTimeZoneDate,'week')
//    let dayDiffNum=todayTimeZoneDate.diff(createTimeZoneDate,'day')
//    let showTime=''
//   if(dayDiffNum>=2&&weekDiffNum<=1){
//     showTime=`${dayDiffNum} Days Ago`
//
//    }else if(dayDiffNum>=7&&weekDiffNum<=4){
//     showTime=`${weekDiffNum} Weeks Ago`
//    }else if(dayDiffNum>4){
//     showTime=convertDateTimeFormat(createTimeZoneDate.format('YYYY/MM/DD HH:mm:ss'))
// }else if(dayDiffNum==1){
//   showTime='Yesterday'
// }else if(dayDiffNum==0){
//   showTime='Today'
// }
//   return showTime
})

</script>
<style scoped lang="scss">
.conversation-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 30px 10px;
  border-bottom: 2px solid #e6e6e6;

}
.conversation-info {
  // width: 90%;
  flex:1;
      word-wrap: break-word;
    word-break: normal;
    overflow: hidden;
  margin: 0 10px;
  .name {
    margin-bottom: 10px;
  }
}
.conversation-time {
  width: auto;
  text-align: right;
}
.content{
  width: 100%;
}

.badge-item {
  margin-top: 10px;
  margin-right: 0px;
}
</style>
