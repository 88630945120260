import {createPinia,defineStore} from 'pinia'

 const useRouterStore = defineStore('router', {
    state: () => ({ 
        _params:{}
     }),
    getters: {
      params: (state) => state._params,
    },
    actions: {
      setParams(data) {
        this._params=data
      },
    },
  })

  export default useRouterStore;