<!-- 图片/视频预览 -->
<template>
    <el-dialog
        v-model="dialogFormVisible"
        :before-close="beforeClose"
        :title="$t('common.dialog.preview')"
        align-center draggable
    >
      <div v-if="data.type === typeVo.picture" style="width: 100%; max-height: 70vh; display: flex; justify-content: center; align-content: center">
        <el-image :src="data.url" fit="contain" />
      </div>
      <video-view v-if="data.type === typeVo.Video" :set-video-src="data.url"></video-view>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="closeButton">{{ $t('common.button.cancel') }}</el-button>
        </div>
      </template>
    </el-dialog>
</template>

<script>
import {PICTURE, VIDEO} from "@/util/MyUtil";
import VideoView from "@/components/file/VideoView";

export default {
  components: {VideoView},
  name: "fileDialog",
  // data: 对象就是要渲染到页面上的数据
  data: function () {
    return {
      // 控制对话框大开闭
      dialogFormVisible: false,
      data: {
        url: '',
        type: 0,
      },
      typeVo: {
        picture: PICTURE,
        Video: VIDEO,
      }
    }
  },

  // moubted: 页面加载完毕后执行
  mounted() {

  },

  // methods: 对象中的方法，可以在页面中调用
  methods: {
    // 点击遮罩和x时执行
    beforeClose(done) {
      this.closeButton();
      done(); // 必须调用 done，以继续关闭对话框
    },
    // 取消按钮
    closeButton() {
      this.dialogFormVisible= false;
      // this.data = {
      //   url: '',
      //   type: 0,
      // }
    },
    // 确认按钮
    submitButton() {
      this.dialogFormVisible= false;
    }
  },
}
</script>

<!--组件css样式 scoped:表示该样式只在此组件生效-->
<style scoped>

</style>
