import { useI18n } from "vue-i18n";

/**
 * 获取当前订单真正显示的文本状态
 * @param {String} baseStatus
 * @param {String} customStatus
 * @param {Array} baseStatusList 
 * @param {String} customStateName
 * @returns
 */

export function getShowTicketStatusText(
  baseStatus,
  customStatus,
  baseStatusList,
  customStateName
) {
  if ([2, 3].includes(baseStatus) && customStatus) {
    return customStateName;
  }
  return baseStatusList.find((item) => item.value == baseStatus)?.label;
}

export function baseStatusList($t) {
  return [
    {
      id: 1,
      name: $t("pullDown.afterStatus.allocation"),
      statusType: "base",
    },
    { id: 2, name: $t("pullDown.afterStatus.pending"), statusType: "base" },
    {
      id: 3,
      name: $t("pullDown.afterStatus.processing"),
      statusType: "base",
    },
    {
      id: 4,
      name: $t("pullDown.afterStatus.completed"),
      statusType: "base",
    },
    { id: 5, name: $t("pullDown.afterStatus.revoked"), statusType: "base" },
    { id: 6, name: $t("pullDown.afterStatus.maintain"), statusType: "base" },
  ];
}
