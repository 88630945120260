import request from '../request'

// ---------- 下拉选择框数据请求 --------------
// 获取角色下拉数据
export function getRolePullDown() {
    return request({
        url: '/role/getRolePullDown',
        method: 'get'
    })
}

// 获取权限树下拉数据
export function getResourceInfoPullDown(params) {
    return request({
        url: '/resourceInfo/getResourceInfoPullDown',
        method: 'get',
        params: params
    })
}

// 获取用户下拉数据
export function getUserAdminPullDown() {
    return request({
        url: '/userAdmin/getUserAdminPullDown',
        method: 'get'
    })
}
// 获取前台用户下拉数据
export function getFrontUserPullDown(params) {
    return request({
        url: '/UserFront/getFrontAllPullDown',
        method: 'get',
        // params: params
    })
}
// 获取用户带条件的选入框数据
export function listUserAdminSelected(data) {
    return request({
        url: '/userAdmin/listUserAdminSelected',
        method: 'post',
        data
    })
}

// 获取系列下拉数据
export function getSeriesPullDown() {
    return request({
        url: '/series/getSeriesPullDown',
        method: 'get'
    })
}

// 获取功能下拉数据
export function getFunctionPullDown() {
    return request({
        url: '/function/getFunctionPullDown',
        method: 'get'
    })
}

// 获取产品下拉数据
export function getSpuInfoPullDown() {
    return request({
        url: '/spuInfo/getSpuInfoPullDown',
        method: 'get'
    })
}

// 获取字典Code下拉数据
export function getDictPullDown(params) {
    return request({
        url: '/dict/getDictPullDown',
        method: 'get',
        params: params
    })
}

// 获取币种资料的下拉
export function getCurrencyPullDown() {
    return request({
        url: '/currency/getCurrencyPullDown',
        method: 'get'
    })
}
