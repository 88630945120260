<template>
  <!-- 维修事件流程新增弹出框 -->
  <div id="maintain-dialog">
    <el-drawer v-model="newAffairVisible" :title="newTitleName" :before-close="affairBeforeClose" :size="pcMediaSize?600:'100%'">
      <el-form :rules="affairRules" ref="affairForm" :model="newAffairForm" label-position="top" label-width="130px">
        <el-form-item :label="$t('AfterSpu.afterOrderNumber')">
          <el-input v-model="newAffairForm.afterOrderNumber" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item :label="$t('AfterSpu.affair.ditchStatus')" prop="ditchStatus">
          <el-select @change="dsclick" filterable v-model="newAffairForm.ditchStatus" :placeholder="$t('AfterSpu.affair.ditchStatusPlaceholder')" style="width: 100%">
            <el-option v-for="item in selectData.ditchStatusList" :key="item.dictValue" :label="item.dictKey" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>

        <!-- 发起物流 -->
        <el-form-item :label="$t('AfterSpu.affair.expressage')" prop="expressage" v-if="newAffairForm.ditchStatus == 'GDWX-002' || newAffairForm.ditchStatus == 'GDWX-006'">
          <el-input v-model="newAffairForm.expressage" :placeholder="$t('AfterSpu.affair.expressagePlaceholder')" :readonly="isReadonly"></el-input>
        </el-form-item>
        <el-form-item :label="$t('AfterSpu.affair.expressageNumber')" prop="expressageNumber" v-if="newAffairForm.ditchStatus == 'GDWX-002' || newAffairForm.ditchStatus == 'GDWX-006'">
          <el-input v-model="newAffairForm.expressageNumber" :placeholder="$t('AfterSpu.affair.expressageNumberPlaceholder')" :readonly="isReadonly"></el-input>
        </el-form-item>
        <el-form-item :label="$t('AfterSpu.affair.sendoutDateNew')" prop="sendoutDate" v-if="newAffairForm.ditchStatus == 'GDWX-002' || newAffairForm.ditchStatus == 'GDWX-006'">
          <el-date-picker v-model="newAffairForm.sendoutDate" :disabled="isReadonly" :placeholder="$t('AfterSpu.affair.sendoutDateNewPlaceholder')" type="date" :format="dateFormat" value-format="YYYY-MM-DD" style="width: 100%"/>
        </el-form-item>
        <el-form-item :label="$t('AfterSpu.affair.freight')" prop="money" v-if="newAffairForm.ditchStatus == 'GDWX-006'">
<!--          <el-input-number v-model="newAffairForm.money" :placeholder="$t('AfterSpu.affair.freightPlaceholder')" :readonly="isReadonly" class="mx-4" :step="10" :min="0" controls-position="right" style="width: 100%; text-align: left;"/>-->
          <el-input-number v-model="newAffairForm.money" :placeholder="$t('AfterSpu.affair.freightPlaceholder')"
                           :readonly="isReadonly" :step="10" :min="0"
                           controls-position="right" style="width: 68%; text-align: left;"
          />
          <el-select v-model="newAffairForm.currency" style="width: 30%">
            <el-option v-for="item in selectData.currencyPullDown" :key="item.id" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>

        <!-- 客户签收-单号/事件 -->
        <el-form-item :label="$t('AfterSpu.affair.expressageNumber')" prop="affairDetailId" v-if="newAffairForm.ditchStatus === 'GDWX-003' || newAffairForm.ditchStatus == 'GDWX-007'">
          <el-select filterable v-model="newAffairForm.affairDetailId" :placeholder="$t('AfterSpu.affair.numberPlaceholder')" style="width: 100%">
            <el-option v-for="item in selectData.affairDetailList" :key="item.id" :label="item.expressage + '-' + item.expressageNumber" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('AfterSpu.affair.signDate')" prop="sendoutDate" v-if="newAffairForm.ditchStatus == 'GDWX-003' || newAffairForm.ditchStatus == 'GDWX-007'">
          <el-date-picker v-model="newAffairForm.sendoutDate" :disabled="isReadonly" :placeholder="$t('AfterSpu.affair.signDatePlaceholder')" type="date" :format="dateFormat" value-format="YYYY-MM-DD" style="width: 100%"/>
        </el-form-item>

        <el-form-item :label="$t('AfterSpu.affair.remarks')">
          <el-input type="textarea" v-model="newAffairForm.remarks" :placeholder="$t('AfterSpu.affair.remarksPlaceholder')" :readonly="isReadonly"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer" style="float: left">
        <el-button type="primary" @click="newAffairAdd" v-if="!isReadonly">
          {{ $t('common.button.submit') }}
        </el-button>
        <el-button @click="newAffairClick">
          {{ $t('common.button.back') }}
        </el-button>
      </span>
      </template>
    </el-drawer>
  </div>
</template>

<script>
import {ElLoading, ElNotification} from "element-plus";
import {getAffairDetailByIdAndExp, saveMaintainAffairDetail} from "@/http/afterTicket/afterSpu/affairApi";
import {getNewYmdStr} from "@/util/DateUtil";
import {getDictListByCode} from "@/util/longin";
import {getCurrencyPullDown} from "@/http/home/PullDownApi";
import {formatByLanguage} from "@/util/system";
import { useMedia } from 'vue-hooks-plus'
export default {
  name: "maintain-dialog",
  // data: 对象就是要渲染到页面上的数据
  data: function () {
    return {
      pcMediaSize:useMedia(['(min-width: 768px)'], [true], false),
      dateFormat: formatByLanguage(),
      newTitleName: this.$t('AfterSpu.affair.eventFormTitle'),
      newAffairVisible: false,
      isReadonly: false,

      newAffairForm: {
        sendoutDate: getNewYmdStr(new Date()),
        ditchStatus: '',
        money: 0,
      },
      // 下拉数据
      selectData: {
        affairDetailList: [], // 事件未签收的物流数据
        costList: [], // 成本与费用
        currencyPullDown: [], // 币种资料下拉数据
      },
      // 物流数据
      expData:{
        yhList: [], // 用户未签收的物流数据
        gsList: [], // 公司未签收的物流数据
      },
      // 表单验证规则
      affairRules: {
        ditchStatus: [
          {required: true, message: this.$t('AfterSpu.affair.ditchStatusPlaceholder'), trigger: 'blur'},
        ],
        affairDetailId: [
          {required: true, message: this.$t('AfterSpu.affair.numberPlaceholder'), trigger: 'change'},
        ],
        expressage: [
          {required: true, message: this.$t('AfterSpu.affair.expressagePlaceholder'), trigger: 'blur'},
        ],
        expressageNumber: [
          {required: true, message: this.$t('AfterSpu.affair.expressageNumberPlaceholder'), trigger: 'blur'},
        ],
        sendoutDate: [
          {required: true, message: this.$t('AfterSpu.affair.sendoutDateNewPlaceholder'), trigger: 'change'},
        ],
        money: [
          {required: true, message: this.$t('AfterSpu.affair.freightPlaceholder'), trigger: 'blur'},
        ],
      },
    }
  },

  // moubted: 页面加载完毕后执行
  mounted() {

  },

  // methods: 对象中的方法，可以在页面中调用
  methods: {
    // 点击遮罩和x时执行
    affairBeforeClose(done) {
      this.newAffairClick();
      done(); // 必须调用 done，以继续关闭对话框
    },
    // 关闭
    newAffairClick() {
      this.newAffairVisible = false;
      this.isReadonly = false;
      this.newAffairForm = {
        sendoutDate: getNewYmdStr(new Date()),
        ditchStatus: '',
      };
      this.$refs.affairForm.resetFields(); // 重置表单验证
    },
    // 获取下拉数据
    async getSelectData() {
      // 事件状态
      this.selectData.ditchStatusList = await getDictListByCode('GDWX');
      // 货币资料
      getCurrencyPullDown().then(res => {
        if (res.data.status === 200) {
          this.selectData.currencyPullDown = res.data.data;
          this.newAffairForm.currency = this.selectData.currencyPullDown[0].id;
        }
      });
      // 获取事件内部为签收的物流数据
      getAffairDetailByIdAndExp({
        affairId: this.newAffairForm.affairId,
      }).then(res => {
        if (res.data.status === 200) {
          this.expData = res.data.data;
          this.dsclick();
        }
      })

    },
    // 事件状态改变
    dsclick() {
      if (this.newAffairForm.ditchStatus == 'GDWX-003') {
        this.selectData.affairDetailList = this.expData.yhList;
      } else if (this.newAffairForm.ditchStatus == 'GDWX-007'){
        this.selectData.affairDetailList = this.expData.gsList;
      }
    },
    // 提交用户的物流信息
    newAffairAdd() {
      // 提交校验
      this.$refs.affairForm.validate((valid) => {
        if (valid) {
          const loading = ElLoading.service({
            lock: true, text: this.$t('common.loadingText'), background: 'rgba(0, 0, 0, 0.7)',}
          );
          this.newAffairForm.createStatus = '2';
          // this.newAffairForm.webUrl = process.env.VUE_APP_BACKEND_WEB_URL + "/selectAfter/";
          // this.newAffairForm.webUrl = window.location.origin + "/#" + "/selectAfter/";
          this.newAffairForm.webUrl = window.location.origin + "/#" + "/ticket/";
          saveMaintainAffairDetail(this.newAffairForm).then(res => {
            if (res.data.status === 200) {
              ElNotification({title: this.$t('common.message.successTitle'), message: res.data.msg, type: 'success',});
              this.$emit('childClick');
              this.newAffairClick();
              this.getAffair();
            }
          }).finally(() => {
            // 请求无论成功/失败 都执行关闭loading
            loading.close();
          });
        } else {
          return false;
        }
      });
    },
  },
}
</script>

<!--组件css样式 scoped:表示该样式只在此组件生效-->
<style scoped>

</style>
