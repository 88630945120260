// 导入Day.js和必要的插件
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {convertDateTimeFormat, formatDate} from "@/util/DateUtil";
import { useI18n } from "vue-i18n";
import {i18n} from '@/main';
import {getSysLanguage} from "@/util/system";

// 扩展Day.js以支持UTC和时区功能
dayjs.extend(utc);
dayjs.extend(timezone);

// 定义中国时区的时区标识
export const chinaZone = 'Asia/Shanghai';


/**
 * 将给定的Asia/Shanghai时区的时间转换为指定时区的时间。
 *
 * @param {string} shanghaiTime - Asia/Shanghai时区的时间字符串，格式如'YYYY-MM-DD HH:mm:ss'
 * @param {string} targetTimeZone - 目标时区ID，例如 'America/New_York'
 * @returns {string} 转换后的目标时区时间字符串
 */
export function convertShanghaiTimeToTimeZone(shanghaiTime, targetTimeZone) {
    // 解析上海时间并设置时区
    const dateInShanghai = dayjs.tz(shanghaiTime, chinaZone);
    // 转换为目标时区
    const dateInTargetTimeZone = dateInShanghai.tz(targetTimeZone);
    // 返回格式化后的时间字符串
    return dateInTargetTimeZone.format('YYYY-MM-DD HH:mm:ss');
}

/**
 * 将目前系统的时区时间的转换为Asia/Shanghai时区的时间。
 *
 * @param {string} shanghaiTime
 * @returns {string} 转换后的目标时区时间字符串 YYYY-MM-DD HH:mm:ss
 */
export function convertTimeZoneToShanghai(shanghaiTime) {
    // 获取当前时区
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // 判断是否为上海时区 如果是则直接返回 反之则进行转换
    if (timeZone === chinaZone) return shanghaiTime
    // 解析上海时间并设置时区
    const dateInShanghai = dayjs.tz(shanghaiTime, timeZone);
    // 转换为目标时区
    const dateInTargetTimeZone = dateInShanghai.tz(chinaZone);
    // 返回格式化后的时间字符串
    return dateInTargetTimeZone.format('YYYY-MM-DD HH:mm:ss');
}

/**
 * 格式化日期时间
 * 中国上海时区转化为当前时区的时间
 * @param {string} dateTimeStr - 日期时间字符串，'YYYY-MM-DD HH:mm:ss'等格式
 * return {string} 格式化后的时间字符串
 * [
 *     刚刚: 三分钟内 / x分钟前 / x小时前 /
 *     今天: 超过12小时的
 *     昨天 / x天前 / x周前 / 直接返回时间: 超过一个月前
 * ]
 */
export function formatDateTime(dateTimeStr) {
    // 获取当前时区 非上海时区 则进行转换
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timeZone !== 'Asia/Shanghai') {
        dateTimeStr = convertShanghaiTimeToTimeZone(dateTimeStr, timeZone);
    }
    // 获取现在时间
    const now = dayjs();
    // 判断时间是否在今天
    const isToday = now.isSame(dayjs(dateTimeStr), 'day');
    if (isToday) {
        // 判断是否在三分钟之内
        const diffInMinutes = now.diff(dayjs(dateTimeStr), 'minute');
        if (diffInMinutes < 3) {
            return i18n.global.t('time.just');
        } else if (diffInMinutes < 60) {
            return i18n.global.t('time.minute', {num: diffInMinutes});
        } else if (diffInMinutes < 60 * 12) {
            // return [Math.floor(diffInMinutes / 60)] + `小时前`;
            return i18n.global.t('time.hour', {num: Math.floor(diffInMinutes / 60)});
        } else{
            // return '今天';
            return i18n.global.t('time.today');
        }
    }
    // 判断是否在昨天
    const isYesterday = now.subtract(1, 'day').isSame(dayjs(dateTimeStr), 'day');
    if (isYesterday) {
        // return '昨天';
        return i18n.global.t('time.yesterday');
    } else {
        // 判断是否在昨天之前
        const diffInDays = now.diff(dayjs(dateTimeStr), 'day');
        if (diffInDays < 7) {
            // return [diffInDays] + '天前';
            return i18n.global.t('time.day', {num: diffInDays});
        } else if (diffInDays < 30) {
            // return [Math.floor(diffInDays / 7)] + '周前';
            return i18n.global.t('time.week', {num: Math.floor(diffInDays / 7)});
        } else {
            // 判断语言环境
            if (getSysLanguage() !== 'zh-CN') {
                return convertDateTimeFormat(dateTimeStr);
            }
            return dateTimeStr;
        }
    }
}

/**
 * @description 将指定时间和当前时间作比较 显示不同的时间格式 例如 今天 昨天 前天 12-12 12:12
 * @returns
 */
export function transformTimeZone(needTransformTime) {
    const { t, locale } = useI18n();
let $t = t;
    // 获取当前时区
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let createTimeZoneDate= convertShanghaiTimeToTimeZone(needTransformTime, timeZone)
    createTimeZoneDate=dayjs(createTimeZoneDate)
    let todayTimeZoneDate= dayjs()

     let weekDiffNum=todayTimeZoneDate.diff(createTimeZoneDate,'week')
     let dayDiffNum=todayTimeZoneDate.diff(createTimeZoneDate,'day')
     let showTime=''
     let timeFormat=locale.value=='en'?'DD/MM/YYYY ' :'YYYY-MM-DD '
    let isHour= /[\d\D]+? ([\d]{2}):([\d]{2}):([\d]{2})/
    timeFormat=isHour.test(needTransformTime)?timeFormat+' HH:mm:ss':timeFormat
     if(dayDiffNum>=2&&weekDiffNum<=1){
      showTime=`${dayDiffNum} Days Ago`

     }else if(dayDiffNum>=7&&weekDiffNum<=4){
      showTime=`${weekDiffNum} Weeks Ago`
     }else if(dayDiffNum>4){
      showTime= createTimeZoneDate.format(timeFormat)
  }else if(dayDiffNum==1){
    showTime='Yesterday'
  }else if(dayDiffNum==0){
    showTime='Today'
  }

  return showTime
}
export function transformTime(needTransformTime) {


  let showTime=""
  try{
    
  const { t, locale } = useI18n();
  let $t = t;
    // 获取当前时区
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let createTimeZoneDate= convertShanghaiTimeToTimeZone(needTransformTime, timeZone)
    createTimeZoneDate=dayjs(createTimeZoneDate)
    let todayTimeZoneDate= dayjs()
    let timeFormat=locale.value=='en'?'DD/MM/YYYY ' :'YYYY-MM-DD '
    let isHour= /[\d\D]+? ([\d]{2}):([\d]{2}):([\d]{2})/
    timeFormat=isHour.test(needTransformTime)?timeFormat+' HH:mm:ss':timeFormat
    showTime= createTimeZoneDate.format(timeFormat)
  }catch(e){
    console.log(e)
  
  }

    // console.log('前', needTransformTime)
    // console.log('时区', timeZone)
    // console.log('后', showTime)
return showTime
}

// 使用示例
// const shanghaiTime = '2024-12-02 11:01:32';
// const newYorkTime = convertShanghaiTimeToTimeZone(shanghaiTime, 'America/New_York');
// console.log(`Shanghai time: ${shanghaiTime}`);
// console.log(`New York time: ${newYorkTime}`);
