// 系统路由
import { loginUrl } from "@/enumbag/StyleEnum";
import afterSpuDetail from "@/views/afterTicket/afterSpu/afterSpu-detail";
export const sysRoutes = [
  {
    //客户订单详情页
    path: "/customer/ticket/detail",
    name: "afterSpuDetail",
    component: afterSpuDetail,
    meta: {
      keepAlive: true,
    },
  },
  {
    //经销商订单详情页
    path: "/business/ticket/detail",
    name: "businessDetail",
    component: () => import("../views/sideTicket/sidebAfter/sidebAfter-detail"),
    meta: {
      keepAlive: true,
    },
  },
  
  // 后台路由
  {
    path: loginUrl,
    name: "ifi-login",
    component: () => import("../views/auth/login/LoginView.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/auth/home/index.vue"),
    children: [
      {
        path: "",
        redirect: "/dashboard",
      },
      {
        path: "/dashboard",
        name: "HomePageView",
        component: () => import("../views/auth/home/page/page.vue"),
        meta: {
          keepAlive: true,
        },
      },

      // 测试路由
      // {path: '/sys/user', name: 'user', component: () => import('../views/home/minModule/Tab-Box')},

      // 产品工单 页面
      {
        path: "/customer/ticket/list",
        name: "afterSpu",
        component: () => import("../views/afterTicket/afterSpu/afterSpuView"),
        meta: {
          keepAlive: true,
        },
      },

      {
        path: "/customer/ticket/transfer",
        name: "afterCareof",
        component: () =>
          import("../views/afterTicket/afterCareof/afterCareofView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/customer/ticket/chart",
        name: "spuChart",
        component: () => import("../views/afterTicket/spuChart/spuChartView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/customer/ticket/pending",
        name: "allotAfter",
        component: () =>
          import("../views/afterTicket/allotAfter/allotAfterView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/customer/ticket/dashboard",
        name: "boardAfter",
        component: () =>
          import("../views/afterTicket/boardAfter/boardAfterView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/customer/ticket/customerList",
        name: "userC",
        component: () => import("../views/afterTicket/customer/userC"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/customer/ticket/statistics",
        name: "statistics",
        component: () =>
          import("@/views/ticketChart/statistics/statisticsView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/customer/ticket/Recover",
        name: "RecoverChart",
        component: () => import("@/views/ticketChart/Recover/index.vue"),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: "/customer/ticket/UserTime",
        name: "UserTimeChart",
        component: () => import("@/views/ticketChart/UserTime/index.vue"),
        meta: {
          keepAlive: false,
        },
      },
      // 供应商售后
      {
        path: "/business/ticket/list",
        name: "sidebAfter",
        component: () =>
          import("../views/sideTicket/sidebAfter/sidebAfterView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/business/ticket/pending",
        name: "sideAllot",
        component: () => import("../views/sideTicket/sideAllot/sideAllotView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/business/tick/transfer",
        name: "sideCareof",
        component: () =>
          import("../views/sideTicket/sideCareof/sideCareofView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/business/ticketDistributorList",
        name: "userB",
        component: () => import("../views/sideTicket/distributorUser/userB"),
        meta: {
          keepAlive: true,
        },
      },

      // 图表
      {
        path: "/chart/productPeak",
        name: "productPeak",
        component: () => import("../views/ticketChart/productPeak/index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/chart/ticketTime",
        name: "ticketTime",
        component: () => import("../views/ticketChart/ticketTime/index.vue"),
        meta: {
          keepAlive: true,
        },
      },

      // 系统管理 页面
      {
        path: "/sys/user",
        name: "user",
        component: () => import("@/views/system/user/userAdminView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/sys/role",
        name: "role",
        component: () => import("@/views/system/role/RoleView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/sys/menu",
        name: "resource",
        component: () => import("@/views/system/ResourceInfo/ResourceInfoView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/sys/dir",
        name: "dict",
        component: () => import("@/views/system/dict/dictView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/sys/log",
        name: "log",
        component: () => import("@/views/system/devLog/devLog.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/sys/information",
        name: "myCentre",
        component: () => import("@/views/system/myCentre/myCentreView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/sys/notification",
        name: "notice",
        component: () => import("@/views/system/notice/noticeView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/sys/config",
        name: "sysConfig",
        component: () => import("@/views/system/config/configView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/auth/monitor",
        name: "authMonitor",
        component: () => import("@/views/system/session/index"),
        meta: {
          keepAlive: true,
        },
      },

      // 产品管理 页面
      {
        path: "/product/serie",
        name: "series",
        component: () => import("../views/spuData/series/seriesView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/product/function",
        name: "function",
        component: () => import("../views/spuData/function/functionView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/product/list",
        name: "spu",
        component: () => import("../views/spuData/spu/spuView"),
        meta: {
          keepAlive: true,
        },
      },

      // 基础管理
      {
        path: "/resource/type",
        name: "problem",
        component: () => import("../views/toolModule/problem/problemView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/resource/notification",
        name: "message",
        component: () => import("../views/toolModule/message/messagePushView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/resource/region",
        name: "region",
        component: () => import("../views/toolModule/region/regionView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/resource/email",
        name: "mailbox",
        component: () => import("../views/toolModule/mailbox/mailboxView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/resource/customer",
        name: "clientSideb",
        component: () =>
          import("../views/toolModule/clientSideb/clientSidebView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/resource/q&a",
        name: "issueRule",
        component: () => import("../views/toolModule/issueRule/issueRuleView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/resource/currency",
        name: "currency",
        component: () => import("../views/toolModule/currency/currencyView"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/sys/devMonitor",
        name: "devMonitor",
        component: () => import("@/views/system/devMonitor/index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
          path: "/ticket/state",
          name: "ticketStatus",
          component: () => import("../views/system/ticketStatus/customTicketStatus"),
      },
    ],
  },
  {
    path: "/get/ifi/data/no/404",
    name: "noData",
    component: () => import("../views/auth/home/page/tool/DataNoError"),
  },
  {
    path: "/get/ifi/data/no/authError",
    name: "authError",
    component: () => import("../views/auth/home/page/tool/AuthError.vue"),
  },
];

/**
 * 后台路由
 */
export const sysRouteList = [
  // 主页
  "/home",
  "/dashboard",
  // 系统管理 页面
  "/sys/user",
  "/sys/role",
  "/sys/menu",
  "/sys/dir",
  "/sys/log",
  "/sys/information",
  "/sys/notification",
  "/sys/devMonitor",
  "/sys/config",
  // 产品管理 页面
  "/product/serie",
  "/product/function",
  "/product/list",
  // 基础管理
  "/resource/type",
  "/resource/notification",
  "/resource/region",
  "/resource/email",
  "/resource/customer",
  "/resource/q&a",
  "/resource/currency",

  // 产品工单 页面
  "/customer/ticket/list",
  "/customer/ticket/detail",
  "/customer/ticket/transfer",
  "/customer/ticket/chart",
  "/customer/ticket/pending",
  "/customer/ticket/dashboard",
  "/customer/ticket/customerList",
  // B端售后页面
  "/business/ticket/list",
  "/business/ticket/pending",
  "/business/tick/transfer",

  // 图表
  "/chart/productPeak",
  "/chart/ticketTime",
  "/customer/ticket/Recover",
  "/customer/ticket/UserTime",
];
