// 动态计算 rem 基准值
const setHtmlFontSize = () => {
    const designWidth = 1920; // 设计稿宽度
    const baseSize = 100; // 与 postcss.config.js 的 rootValue 一致
    const scale = document.documentElement.clientWidth / designWidth;
    document.documentElement.style.fontSize = `${baseSize * Math.min(scale, 2)}px`; // 限制最大缩放倍数
  };
  
  // 初始化执行
  setHtmlFontSize();
  
  // 监听窗口变化
  window.addEventListener('resize', setHtmlFontSize);