<template>
  <div class="Invoice">
       <el-link type="primary" :underline="false" @click="getTicketInvoice" v-if="ticketInfo.invoiceList && ticketInfo.invoiceList.length > 0">
        {{ $t('common.button.checkInvoice') }}
      </el-link>
      <el-text v-else type="info" class="mx-1">{{ $t('AfterSpu.viewInvoiceNo') }}</el-text>

      <span v-if=" ticketInfo.pid<1">
        <el-divider direction="vertical" />
        <el-link type="primary" :underline="false" @click="uploadTicketInvoiceFunc" >
          {{ $t('common.button.uploadInvoice') }}
        </el-link>
      </span>

      
  <FileUploadDrawer ref="fileUploadDrawerRef" @childClick="uploadTicketInvoiceResult"/>
  <FileOperate ref="fileOperateRef"/>
  </div>
</template>

<script setup >
import { ref, reactive, toRefs, onMounted,defineProps,defineEmits} from 'vue'
import FileOperate from "@/components/file/FileOperate.vue";
import FileUploadDrawer from "@/components/file/FileUploadDrawer.vue";
import {getAfterById, uploadTicketInvoice} from "@/http/afterTicket/afterSpu/afterApi";
import { useI18n } from "vue-i18n";
import {
  ElLoading,
  ElMessage,
  ElMessageBox,
  ElNotification,
} from "element-plus";
let props = defineProps({
  ticketInfo: {
    type: Object,
    default: () => ({}),
  },
});
let emits=defineEmits(['updateTicket'])

const { t } = useI18n();
let $t = t;
let fileOperateRef=ref()
let fileUploadDrawerRef=ref()

  // 打开工单发票列表框
    function getTicketInvoice() {
      fileOperateRef.value.initData(props.ticketInfo.invoiceList);
    }


     // 打开上传发票的抽屉
  function  uploadTicketInvoiceFunc() {
      fileUploadDrawerRef.value.fileData.folder = 'AfterFile';
      fileUploadDrawerRef.value.initData();
    }
  function  uploadTicketInvoiceResult(fileList) {
      uploadTicketInvoice({
        id: props.ticketInfo.id,
        fileList: fileList
      }).then(res => {
        if (res.data.status === 200) {
        //   this.getDataById(this.form.id, false)
          ElNotification({title: $t('common.message.successTitle'), message: res.data.msg, type: 'success',})
          emits('updateTicket')
        }
      });
    }
</script>
<style scoped lang="scss">
</style>