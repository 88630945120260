import request from '../request'


// 获取列表
export function listSpuInfo(data) {
    return request({
        url: '/spuInfo/listSpuInfo',
        method: 'post',
        data
    })
}

// 新增
export function saveSpuInfo(data) {
    return request({
        url: '/spuInfo/saveSpuInfo',
        method: 'post',
        data
    })
}

// 修改
export function updateSpuInfo(data) {
    return request({
        url: '/spuInfo/updateSpuInfo',
        method: 'post',
        data
    })
}

// 删除
export function deleteSpuInfoById(ids) {
    return request({
        url: '/spuInfo/deleteSpuInfoById',
        method: 'post',
        data: ids, // 将ids放入data对象中
    });
}

// 根据id查询对象数据
export function getSpuInfoById(params) {
    return request({
        url: '/spuInfo/getSpuInfoById',
        method: 'get',
        params: params
    });
}

// 导出表格 - 导出Excel
export function exportExcel(data) {
    return request({
        url: '/spuInfo/exportExcel',
        method: 'post',
        data: JSON.stringify(data), // 将数据转换为 JSON 字符串
        headers: {
            'Content-Type': 'application/json' // 指定内容类型为 JSON
        },
        responseType: 'blob'
    })
}

// 导入表格 - 导入Excel
export function importExcel(file) {
    const formData = new FormData();
    formData.append('file', file);
    return request({
        url: '/spuInfo/importExcel',
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data' // 设置请求头为multipart/form-data
        }
    })
}
