<!-- 富文本编辑器删减版 -->
<template>
  <div style="height: 100%" class="bor-box">
    <Toolbar
      class="bor-box"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="height: 170px"
      class="bor-box"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
    />
  </div>

  <el-text
    v-if="flowTypeIs"
    class="mx-1"
    type="info"
    style="margin-top: 5px; font-size: 12px"
  >
    <el-icon><Link /></el-icon> {{ $t("flowMessage.flowRemark") }}
  </el-text>

  <!--  <el-form-item :label="$t('flowMessage.remark')" v-if="remarkDescIs" style="margin-top: 10px">-->
  <!--    <el-input v-model="remarkDesc" type="textarea" :rows="4" :placeholder="$t('flowMessage.remarkPlaceholder')"/>-->
  <!--  </el-form-item>-->
  <editor-mgs ref="editorMgs" @setEditor="getMsg" />
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import {
  DomEditor,
  i18nChangeLanguage,
  SlateTransforms,
  SlateEditor,
  SlateNode,
} from "@wangeditor/editor";
import { getSysLanguage } from "@/util/system";
import { defaultExcludeKeys, simpleExcludeKeys } from "@/enumbag/StyleEnum";
import EditorMgs from "@/views/toolModule/flowMessage/Editor-Mgs";
// 导入自定义菜单配置 不能删除
import { editorMgsConf } from "@/util/editor/editorMgs";
import { editorRemarkConf } from "@/util/editor/editorRemark";
import { editorTxtConf } from "@/util/editor/editorTxt";
import { getUserData } from "@/util/resource";

export default {
  components: { Editor, Toolbar, EditorMgs, editorTxtConf },
  props: {
    htmlTxt: {
      type: String,
      required: false,
      default:""
    },
    modeStr: {
      type: String,
      required: false,
    },
    insertKeys:{
      type:Object,
      default:()=>{
        return {
          index: 31, // 插入的位置，基于当前的 toolbarKeys
          keys: ["menuMgs", "menuRemark", "menuTxt"],
        }
      }
    }
  },
  data() {
    return {
      conf: [editorMgsConf, editorRemarkConf],
      editor: null,
      html: "",
      toolbarConfig: {},
      editorConfig: { placeholder: this.$t("elTag.editorTip") },
      mode: "simple",

      toolbar: null,
      curToolbarConfig: null,

      flowTypeIs: false,

      remarkDescIs: false,
      remarkDesc: "",

      txtStr: "<p>Hi,</p><p></p><p>Regards,</p><p>{}</p>",
    };
  },

  mounted() {
    // 初始化设置国际化和主题
    this.setI18nAndTheme();
  },

  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy();
  },

  methods: {
    //监听用户按键
    onUserKeydownEvent(params) {
      // 添加 Ctrl + K 键盘事件监听器
      document.addEventListener("keydown", (event) => {
     
      });
      document.addEventListener("keydown",async (event) => {
        
        if (event.ctrlKey && event.key === "k") {
          event.preventDefault(); // 阻止默认行为

          let text = this.editor.getSelectionText();
          let copyLink = await navigator.clipboard.readText()
          if(!text) return 

          // SlateTransforms.removeNodes(this.editor);
          this.editor.insertNode(
            {
              type: "link",
              url: copyLink,
              children: [{ text: this.editor.getSelectionText() }],
            }
          ); 
          // this.editor.move(3) // 移动 3 个字符

        }
           if (event.ctrlKey && event.key === "a") {
            
          console.log(this.editor.children);
        }
      });
    },   
     setI18nAndTheme() {
      let language = getSysLanguage();
      if (language === "en-US") {
        i18nChangeLanguage("en");
      } else if (language === "zh-CN") {
        i18nChangeLanguage("zh-CN");
      }
      let themeSwitch = window.localStorage.getItem("themeSwitch");
      if (themeSwitch === "true") {
        import("@/style/editor-dark.css");
      }
    },
    onCreated(editor) {
            this.html = this.htmlTxt;
      this.editor = Object.seal(editor);
      JSON.stringify(this.insertKeys)!='{}'&&(this.toolbarConfig.insertKeys = this.insertKeys)
      this.mode = this.modeStr;

      if (this.mode === "default") {
        this.toolbarConfig.excludeKeys = defaultExcludeKeys;
      } else {
        this.toolbarConfig.excludeKeys = simpleExcludeKeys;
      }
      this.$nextTick(() => {
        this.toolbar = DomEditor.getToolbar(editor);
        this.curToolbarConfig = this.toolbar.getConfig();
   
        // console.log(this.toolbar.getConfig().toolbarKeys, 'this.toolbar.getConfig().toolbarKeys')
      });
      // 监听事件 消息预设
      editor.on("formulaClick", () => {
        this.$refs.editorMgs.dialogFormVisible = true;
        this.$refs.editorMgs.getData();
      });
      // 监听事件 私密消息
      editor.on("menuRemarkClick", () => {
        this.flowTypeIs = !this.flowTypeIs;
        // if (!this.remarkDescIs) this.remarkDesc = '';
      });
      // 监听事件 模板消息
      editor.on("editorTxtClick", () => {
        this.setTxtStr();
      });

      console.log(editor.getMenuConfig("fontFamily")); // 获取 uploadImage 的当前配置
      this.onUserKeydownEvent();
    },
    // 获取预设消息
    getMsg(item) {
      if (item) {
        // 将 item.content 插入到编辑器的光标位置
        // console.log('item.content', item.content)
        // this.editor.insertText('<p>' + item.content + '</p>')
        if (this.editor.isEmpty()) {
          this.html = "<p>" + item.content + "</p>";
        } else {
          this.html = this.html + "<p>" + item.content + "</p>";
        }
      }
      // Editor 输入聚焦
      this.editor.focus();
    },

    // 消息模板
    setTxtStr() {
      const user = getUserData();
      const str = this.txtStr.replace("{}", user.nickName);
      this.editor.dangerouslyInsertHtml(str);
    },
  },
};
</script>

<!-- 导入样式 -->
<style
  src="../../../node_modules/@wangeditor/editor/dist/css/style.css"
></style>
<!-- 组件样式 -->
<style scoped>
.bor-box {
  border: 1px solid #f1f1f1;
  font-family: "display_semibold", "text_regular", sans-serif !important;
}

</style>
<style>
.w-e-modal{
      padding: 20px 15px !important;
}
.w-e-modal .button-container{
  margin: 10px 0px 0px !important;
}
.w-e-modal .btn-close svg{
  display: none !important;
}
</style>