import {Boot} from '@wangeditor/editor';

/**
 * 自定义菜单 (消息预设按钮)
 */
class editorRemarkButton {
    constructor() {
        // 自定义菜单标题
        this.title = 'Private Note';
        // 菜单显示图标
        this.iconSvg = '<svg data-v-8fd61fd6="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="currentColor" d="M128 320v576h576V320zm-32-64h640a32 32 0 0 1 32 32v640a32 32 0 0 1-32 32H96a32 32 0 0 1-32-32V288a32 32 0 0 1 32-32M960 96v704a32 32 0 0 1-32 32h-96v-64h64V128H384v64h-64V96a32 32 0 0 1 32-32h576a32 32 0 0 1 32 32M256 672h320v64H256zm0-192h320v64H256z"></path></svg>' // 可选
        this.tag = 'button';
    }

    // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
    getValue(editor) {
        return '';
    }

    // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
    isActive(editor) {
        return false;
    }

    // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
    isDisabled(editor) {
        return false;
    }

    // 点击菜单时触发的函数
    exec(editor, value) {
        if (this.isDisabled(editor)) return;
        editor.insertText(value); // 插入文本
        // 触发 Vue 组件中的事件 | 在编辑器的@onCreated="onCreated"中进行监听
        editor.emit("menuRemarkClick")
    }
}

export const editorRemarkConf = {
    key: 'menuRemark', // 定义 menu key：要保证唯一、不重复（重要）
    factory() {
        return new editorRemarkButton(); // 实例化菜单
    }
};
Boot.registerMenu(editorRemarkConf);
