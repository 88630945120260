/**
 * 本地储存键
 * @type {{issueRule: string}}
 */
export const windowEnum = {
    // 服务支持登录校验定向 url 储存键 值: 1_服务支持  2_页面提交
    issueRule: 'ISSUE_RULE_KEY',
    // 字典缓存键
    dict: 'DICT_KEY',
};

/**
 * 文件储存文件夹名称
 */
export const filePath = {
    // 产品储存文件夹名称
    SPU_FOLDER: 'SpuFile',
    // 工单流程文件夹
    FLOW_FOLDER: 'FlowFile',

    // 客户工单文件夹 (工单附件、工单发票、购买证明)
    AFTER_FOLDER: 'AFTER_FOLDER',

    // 经销商工单附件
    SIDEB_AFTER: 'SidebAfterFile',
    // 经销商工单子表产品文件夹 (工单发票、购买证明)
    SIDEB_INVOICE: 'SidebInvoice',
}

/**
 * 系统配置 - 枚举 sysEnum: sysEnum,
 * @type {{}}
 */
export const sysEnum = {
    // 系统配置
    system: {
        code: 'SYSPZ-001',
        ipc_str: 'IPC_STR',
        ipc_url: 'IPC_URL',
        defaultPassword: 'DEFAULT_PASSWORD',
    },
    // 条款配置
    Clause: {
        code: 'SYSPZ-002',
        privacy: 'PRIVACY',
        cancel: 'FONT_CANCEL',
    },
    // 文件配置
    file: {
        code: 'SYSPZ-003',
    },
    // 配置列表
    ConfigList: {
        code: 'SYSPZ-005',
    },
    // 前台配置
    frontConfig: {
        code: 'SYSPZ-006',
        img: 'INDEX_IMG',
        title: 'INDEX_TITLE',
        txt: 'INDEX_TXT',
        colorText:'COLOR_TEXT',
        bgColor:'FRONT_BG_COLOR'
    },
    // 前台配置
    statisticsConfig: {
        code: 'SYSPZ-007',
        UK: 'UK',
        US: 'US',
        EU: 'EU',
        HK: 'HK',
    },
    // 邮件配置
    emailConfig: {
        code: 'SYSPZ-004',
        emailTitle: 'EMAIL_TITLE',
        RegisterTab: 'REGISTER_TAB',

        OFFICE_TENANT_ID: 'OFFICE_TENANT_ID',
        OFFICE_CLIENT_ID: 'OFFICE_CLIENT_ID',
        OFFICE_CLIENT_SECRET: 'OFFICE_CLIENT_SECRET',
        OFFICE_MAIL_FROM: 'OFFICE_MAIL_FROM',
    },
    // 留言配置
    flowConfig: {
        code: 'SYSPZ-008',
    },
};
