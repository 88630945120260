<template>
  <div id="affair" >
    <!-- 弹出窗 -->
    <el-dialog v-model="dialogFormVisible" :before-close="beforeClose" :title="titleName" width="1500"    draggable >
      <div class="radioBox">
        <!-- 售后流程 、 成本和费用 -->
        <!-- <el-radio-group v-model="radio" @click="getcharData" size="default" >
          <el-radio-button :label="$t('AfterSpu.affair.event')"/>
        </el-radio-group> -->

        <!-- 发起售后流程弹出框 待分配和结束撤销的状态无法发起 -->
        <span v-if="[2,3,6].includes(row.afterStatus)&&row.pid<1">
          <!-- <el-divider direction="vertical" /> -->
          <el-button
              type="warning" icon="Plus"
              @click="this.$refs.saveNotes.initData(this.row)"
          >
            {{ $t('common.button.after') }}
          </el-button>
        </span>
      </div>

      <div class="tableBox" >
        <!-- 事件与记录-表格 default-expand-all 默认展开所有-->
        <el-table :expand-row-keys="expandRowKeys" :row-key="'id'" v-if="radio === this.$t('AfterSpu.affair.event')" :data="tableData" :border="true" height="480" stripe border fit highlight-current-row :empty-text="$t('common.table.noData')" style="width: 100%">
          <el-table-column type="expand">
            <template #default="props">
              <div m="4">
                <el-table :data="props.row.detailVoList" :border="true" stripe border fit highlight-current-row :empty-text="$t('common.table.noData')">
                  <el-table-column fixed type="index" align="center" :label="$t('common.table.number')" width="90"/>
                  <el-table-column :label="$t('AfterSpu.affair.ditchStatus')" prop="ditchStatusStr" width="250" show-overflow-tooltip/>
                  <el-table-column :label="$t('AfterSpu.affair.expressage')" prop="expressage" width="115" show-overflow-tooltip/>
                  <el-table-column :label="$t('AfterSpu.affair.expressageNumber')" prop="expressageNumber" width="120" show-overflow-tooltip/>
                  <el-table-column :label="$t('AfterSpu.affair.sendoutDate')" prop="sendoutDate" width="110" show-overflow-tooltip/>
                  <el-table-column :label="$t('AfterSpu.affair.expressageStatus')" prop="expressageStatus" width="170" show-overflow-tooltip>
                    <template #default="scope">
                      <el-tag v-if="scope.row.expressageStatus == 1" type="success">{{ $t('pullDown.expressageStatus.processing') }}</el-tag>
                      <el-tag v-else-if="scope.row.expressageStatus == 2" type="danger">{{ $t('pullDown.expressageStatus.completed') }}</el-tag>

                      <el-tag v-else-if="scope.row.expressageStatus == 3" type="warning">{{ $t('pullDown.expressageStatus.accountNo') }}</el-tag>
                      <el-tag v-else-if="scope.row.expressageStatus == 4" type="success">{{ $t('pullDown.expressageStatus.accountYes') }}</el-tag>
                      <el-tag v-else type="info">{{ $t('pullDown.noData') }}</el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('AfterSpu.affair.remarks')" prop="remarks" show-overflow-tooltip/>
                  <el-table-column :label="$t('AfterSpu.affair.createTime')" prop="createTime" width="180" show-overflow-tooltip/>
<!--                  <el-table-column fixed="right" :label="$t('common.table.operation')" width="180" align="center">-->
<!--                    <template #default="scope">-->
<!--                      <el-button type="success" plain size="small" > {{ $t('common.button.edit') }} </el-button>-->
<!--                    </template>-->
<!--                  </el-table-column>-->
                </el-table>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('AfterSpu.affair.affairNumber')" width="210" show-overflow-tooltip>
            <template #default="scope">
              <el-tag>{{ scope.row.affairNumber }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column :label="$t('AfterSpu.affair.ditchName')" prop="affairDitchName" width="250" show-overflow-tooltip/>
          <el-table-column :label="$t('AfterSpu.affair.remarks')" prop="remarks" show-overflow-tooltip/>
          <el-table-column :label="$t('AfterSpu.affair.createTime')" prop="createTime" width="180" show-overflow-tooltip/>
          <el-table-column fixed="right" :label="$t('common.table.operation')" width="220" align="center">
            <template #default="scope">
              <div v-show="scope.row.affairStatus == 1" >
                <el-button @click="addAffairDatil(scope.$index, scope.row)" type="primary" size="small"> {{ $t('common.button.add') }} </el-button>
                <el-button @click="finishAffairDatil(scope.$index, scope.row)" type="danger" size="small" > {{ $t('common.button.end') }} </el-button>
              </div>
              <el-tag v-show="scope.row.affairStatus == 2" type="danger">{{ $t('AfterSpu.affair.endTag') }}</el-tag>
            </template>
          </el-table-column>
        </el-table>

        <!-- 成本与费用-表格 -->
        <el-table default-expand-all v-if="radio === this.$t('AfterSpu.affair.cost')" :data="costTableData" :border="true" height="480" stripe border fit highlight-current-row :empty-text="$t('common.table.noData')" style="width: 100%">
          <el-table-column type="expand">
            <template #default="props">
              <div m="4">
                <el-table :data="props.row.costVoList" :summary-method="getSummaries" :border="true" show-summary stripe border fit highlight-current-row :empty-text="$t('common.table.noData')">
                  <el-table-column fixed type="index" align="center" :label="$t('common.table.number')" width="90"/>
                  <el-table-column :label="$t('AfterSpu.affair.ditchNameStr')" prop="ditchNameStr" width="165" show-overflow-tooltip/>
                  <el-table-column :label="$t('AfterSpu.affair.money')" prop="money" width="150" show-overflow-tooltip/>
                  <el-table-column :label="$t('AfterSpu.affair.currencyName')" prop="currencyName" width="150" show-overflow-tooltip/>
                  <el-table-column :label="$t('AfterSpu.affair.remarks')" prop="remarks" show-overflow-tooltip/>
                  <el-table-column :label="$t('AfterSpu.affair.createTime')" prop="createTime" width="180" show-overflow-tooltip/>
                  <el-table-column fixed="right" :label="$t('common.table.operation')" width="180" align="center">
                    <template #default="scope">
                      <el-button type="success" @click="upateCostById(scope.row)" size="small" > {{ $t('common.button.edit') }} </el-button>
                      <el-button type="danger" @click="deleteCostById(scope.row)" size="small" >  {{ $t('common.button.delete') }}  </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('AfterSpu.affair.costType')" align="center" width="250" show-overflow-tooltip>
            <template #default="scope">
              <el-tag v-show="scope.row.id">{{ $t('AfterSpu.affair.ticketIncidents') }}</el-tag>
              <el-tag v-show="!scope.row.id" type="success">{{ $t('AfterSpu.affair.ticketOther') }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column :label="$t('AfterSpu.affair.remarks')" align="center" show-overflow-tooltip>
            <template #default="scope">
              <div v-show="scope.row.id">
                {{scope.row.affairNumber}}
<!--                {{scope.row.affairNumber}} / {{scope.row.ditchName}} / {{scope.row.createTime}}-->
              </div>
              <div v-show="!scope.row.id">{{ $t('AfterSpu.affair.supplement') }}</div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" :label="$t('common.table.operation')" width="150" align="center">
            <template #default="scope">
                <el-button @click="addAffairCost(scope.$index, scope.row)" type="primary" size="small">{{ $t('common.button.addCost') }}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 底部操作按钮 -->
      <div class="dialog-button">
        <el-button @click="closeButton()">{{ $t('common.button.cancel') }}</el-button>
      </div>
    </el-dialog>

    <!-- 操作页面 -->
    <!-- 发起售后流程申请 -->
    <SaveNotes ref="saveNotes" />

    <!-- 新增流程数据 -->
    <el-drawer v-model="newAffairVisible" :title="newTitleName" :before-close="affairBeforeClose" size="500px">
      <el-form :rules="affairRules" ref="affairForm" :model="newAffairForm" label-position="top" label-width="130px">
        <el-form-item :label="$t('AfterSpu.afterOrderNumber')">
          <el-input v-model="newAffairForm.afterOrderNumber" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item :label="$t('AfterSpu.affair.ditchStatus')" prop="ditchStatus">
          <el-select @change="dsclick" filterable v-model="newAffairForm.ditchStatus" :placeholder="$t('AfterSpu.affair.ditchStatusPlaceholder')" style="width: 100%">
            <el-option v-for="item in selectData.ditchStatusList" :key="item.dictValue" :label="item.dictKey" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('AfterSpu.affair.expressageNumber')" prop="affairDetailId" v-if="newAffairForm.ditchStatus == 'LCZT-003' || newAffairForm.ditchStatus == 'LCZT-005'">
          <el-select filterable v-model="newAffairForm.affairDetailId" :placeholder="$t('AfterSpu.affair.numberPlaceholder')" style="width: 100%">
            <el-option v-for="item in selectData.affairDetailList" :key="item.id" :label="item.expressage + '-' + item.expressageNumber" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('AfterSpu.affair.expressage')" prop="expressage" v-if="newAffairForm.ditchStatus == 'LCZT-002' || newAffairForm.ditchStatus == 'LCZT-004'">
          <el-input v-model="newAffairForm.expressage" :placeholder="$t('AfterSpu.affair.expressagePlaceholder')" :readonly="isReadonly"></el-input>
        </el-form-item>
        <el-form-item :label="$t('AfterSpu.affair.expressageNumber')" prop="expressageNumber" v-if="newAffairForm.ditchStatus == 'LCZT-002' || newAffairForm.ditchStatus == 'LCZT-004'">
          <el-input v-model="newAffairForm.expressageNumber" :placeholder="$t('AfterSpu.affair.expressageNumberPlaceholder')" :readonly="isReadonly"></el-input>
        </el-form-item>
        <el-form-item :label="$t('AfterSpu.affair.sendoutDateNew')" prop="sendoutDate" v-if="newAffairForm.ditchStatus == 'LCZT-002' || newAffairForm.ditchStatus == 'LCZT-004'">
          <el-date-picker v-model="newAffairForm.sendoutDate" :disabled="isReadonly" :placeholder="$t('AfterSpu.affair.sendoutDateNewPlaceholder')" type="date" :format="dateFormat" value-format="YYYY-MM-DD" style="width: 100%"/>
        </el-form-item>
        <el-form-item :label="$t('AfterSpu.affair.signDate')" prop="sendoutDate" v-if="newAffairForm.ditchStatus == 'LCZT-003' || newAffairForm.ditchStatus == 'LCZT-005'">
          <el-date-picker v-model="newAffairForm.sendoutDate" :disabled="isReadonly" :placeholder="$t('AfterSpu.affair.signDatePlaceholder')" type="date" :format="dateFormat" value-format="YYYY-MM-DD" style="width: 100%"/>
        </el-form-item>
        <el-form-item :label="$t('AfterSpu.affair.freight')" prop="money" v-if="newAffairForm.ditchStatus == 'LCZT-004'">
          <el-input-number v-model="newAffairForm.money" :placeholder="$t('AfterSpu.affair.freightPlaceholder')"
                           :readonly="isReadonly" :step="10" :min="0"
                           controls-position="right" style="width: 68%; text-align: left;"
          />
          <el-select v-model="newAffairForm.currency" style="width: 30%">
            <el-option v-for="item in selectData.currencyPullDown" :key="item.id" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('AfterSpu.affair.remarks')">
          <el-input type="textarea" v-model="newAffairForm.remarks" :placeholder="$t('AfterSpu.affair.remarksPlaceholder')" :readonly="isReadonly"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="newAffairAdd" v-if="!isReadonly">
          {{ $t('common.button.submit') }}
        </el-button>
        <el-button @click="newAffairClick">
          {{ $t('common.button.back') }}
        </el-button>
      </span>
      </template>
    </el-drawer>

    <!-- 新增费用数据 -->
    <el-drawer v-model="costAffairVisible" :title="costTitleName" :before-close="costBeforeClose" size="500px">
      <el-form ref="costform" :rules="costRules" :model="costAffairForm" label-position="top" label-width="130px">
        <el-form-item :label="$t('AfterSpu.affair.costType')" v-if="costAffairForm.isDisplay">
          <el-input v-model="costAffairForm.classify" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item :label="$t('AfterSpu.affair.ditchNameStr')"  prop="ditchName">
          <el-select filterable v-model="costAffairForm.ditchName" :disabled="costIsReadonly" :placeholder="$t('AfterSpu.affair.ditchNameStrPlaceholder')" style="width: 100%">
            <el-option v-for="item in selectData.costList" :key="item.dictValue" :label="item.dictKey" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('AfterSpu.affair.money')" prop="money">
          <el-input-number v-model="costAffairForm.money" :placeholder="$t('AfterSpu.affair.freightPlaceholder')"
                           :readonly="isReadonly" :step="10" :min="0"
                           controls-position="right" style="width: 68%; text-align: left;"
          />
          <el-select v-model="costAffairForm.currency" style="width: 30%">
            <el-option v-for="item in selectData.currencyPullDown" :key="item.id" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('AfterSpu.affair.remarks')">
          <el-input type="textarea" v-model="costAffairForm.remarks" :placeholder="$t('AfterSpu.affair.remarksPlaceholder')" :readonly="costIsReadonly"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="newCostAdd" v-if="!costIsReadonly">{{ $t('common.button.submit') }}</el-button>
        <el-button @click="backtrackClick">{{ $t('common.button.back') }}</el-button>
      </span>
      </template>
    </el-drawer>

    <!-- 维修事件流程新增弹出框 -->
    <maintainDialog ref="maintainDialog" @childClick="getAffair"/>
  </div>
</template>

<script>
import spuSelected from "@/views/spuData/spu/spu-selected";
import {
  getAffairDetailByIdAndExp,
  listAffair,
  saveAffairDetail,
  updateAffair
} from "@/http/afterTicket/afterSpu/affairApi";
import {ElLoading, ElNotification} from "element-plus";
import {
  deleteAffairCostById,
  listAffairCost,
  saveAffairCost,
  updateAffairCost
} from "@/http/afterTicket/afterSpu/affairCostApi";
import maintainDialog from "@/views/afterTicket/afterSpu/dialog/maintain-dialog";
import {getNewYmdStr} from "@/util/DateUtil";
import {formatArray, setDictByValue} from "@/util/MyUtil";
import {getDictListByCode} from "@/util/longin";
import {getCurrencyPullDown} from "@/http/home/PullDownApi";
import {formatByLanguage} from "@/util/system";
import SaveNotes from "@/views/afterTool/notes/saveTicketNotes.vue";

export default {
  name: "affair",
  components: {SaveNotes, spuSelected, maintainDialog},
  // data: 对象就是要渲染到页面上的数据
  data: function () {
    return {
      dateFormat: formatByLanguage(),
      // 控制对话框大开闭
      dialogFormVisible: false,
      titleName: this.$t('common.dialog.defaultTitle') + '- ',
      // 标签切换页
      radio: this.$t('AfterSpu.affair.event'),
      // 默认展开的行集合id
      expandRowKeys: [],
      // 行数据
      row: {},

      // 表格数据
      tableData: [],
      costTableData: [],

      // 下拉数据
      selectData: {
        affairDetailList: [], // 事件未签收的物流数据
        costList: [], // 成本与费用
        currencyPullDown: [], // 成本与费用
      },
      // 物流数据
      expData:{
        yhList: [], // 用户未签收的物流数据
        gsList: [], // 公司未签收的物流数据
      },

      // 新增流程数据
      newTitleName: this.$t('AfterSpu.affair.eventFormTitle'),
      newAffairVisible: false,
      isReadonly: false,
      newAffairForm: {
        sendoutDate: getNewYmdStr(new Date()),
        // ditchStatus: 'LCZT-002',
        ditchStatus: '',
        money: 0,
        currency: 1,
      },
      // 表单验证规则
      affairRules: {
        ditchStatus: [
          {required: true, message: this.$t('AfterSpu.affair.ditchStatusPlaceholder'), trigger: 'blur'},
        ],
        affairDetailId: [
          {required: true, message: this.$t('AfterSpu.affair.numberPlaceholder'), trigger: 'change'},
        ],
        expressage: [
          {required: true, message: this.$t('AfterSpu.affair.expressagePlaceholder'), trigger: 'blur'},
        ],
        expressageNumber: [
          {required: true, message: this.$t('AfterSpu.affair.expressageNumberPlaceholder'), trigger: 'blur'},
        ],
        sendoutDate: [
          {required: true, message: this.$t('AfterSpu.affair.sendoutDateNewPlaceholder'), trigger: 'change'},
        ],
        money: [
          {required: true, message: this.$t('AfterSpu.affair.freightPlaceholder'), trigger: 'changes'},
        ],
      },

      // 成本费用表单数据
      costTitleName: this.$t('AfterSpu.affair.costFormTitle'),
      costAffairVisible: false,
      costIsReadonly: false,
      costAffairForm: {
        money: 0,
      },
      // 表单验证规则
      costRules: {
        ditchName: [
          {required: true, message: this.$t('AfterSpu.affair.ditchNameStrPlaceholder'), trigger: 'change'},
        ],
        money: [
          {required: true, message: this.$t('AfterSpu.affair.moneyPlaceholder'), trigger: 'blur'},
        ],
      },
    }
  },
  // moubted: 页面加载完毕后执行
  mounted() {

  },
  // methods: 对象中的方法，可以在页面中调用
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('common.button.total');
          return;
        }
        if (index === 1 || index === 3 || index === 4 || index === 5 || index === 6) {
          sums[index] = '';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += '';
        } else {
          sums[index] = 'N/A';
        }
      });

      return sums;
    },
    // 点击遮罩和x时执行
    beforeClose(done) {
      this.closeButton();
      done(); // 必须调用 done，以继续关闭对话框
    },
    // 取消按钮
    closeButton() {
      this.$emit('childClick');
      this.dialogFormVisible = false;
      this.tableData = [];
      this.expandRowKeys = [];
      this.costTableData = [];
    },
    // 获取下拉数据
    async getSelectData() {
      // 事件状态
      this.selectData.ditchStatusList = await getDictListByCode('LCZT');
      // 成本与费用
      this.selectData.costList = await getDictListByCode('CBFY');
      // 货币资料
      getCurrencyPullDown().then(res => {
        if (res.data.status === 200) {
          this.selectData.currencyPullDown = res.data.data;
          this.newAffairForm.currency = this.selectData.currencyPullDown[0].id;
        }
      });
    },
    // 父组件进入子组件时执行
    gotoExecute() {
      this.titleName = this.$t('AfterSpu.afterOrderNumber') + ' - ' + this.row.afterOrderNumber;
      this.getAffair();
      this.getCost();
    },
    // 获取工单事件数据
    getAffair() {
      // 根据售后工单查询事件
      listAffair(this.row).then(res => {
        if (res.data.status === 200) {
          res.data.data.forEach((item) => {
            if (item.affairStatus === 1) {
              this.expandRowKeys.push(item.id);
            }
            item.detailVoList = formatArray(item.detailVoList);
          })
          this.tableData = formatArray(res.data.data);
        }
      })
    },
    // 获取成本费用数据
    getCost() {
      // 根据售后工单查询事件
      listAffairCost(this.row).then(res => {
        if (res.data.status === 200) {
          res.data.data.forEach((item) => {
            item.costVoList = formatArray(item.costVoList);
          })
          this.costTableData = res.data.data;
        }
      })
    },
    // 切换标签页
    getcharData() {
      this.getSelectData();
      if (this.radio === this.$t('AfterSpu.affair.event')) {
        this.getCost();
      } else if (this.radio === this.$t('AfterSpu.affair.cost')) {
        this.getAffair();
      }
    },

    // 点击遮罩和x时执行
    affairBeforeClose(done) {
      this.newAffairClick();
      done(); // 必须调用 done，以继续关闭对话框
    },
    // 关闭
    newAffairClick() {
      this.newAffairVisible = false;
      this.isReadonly = false;
      this.newAffairForm = {
        sendoutDate: getNewYmdStr(new Date()),
        // ditchStatus: 'LCZT-002',
        ditchStatus: '',
      };
      this.$refs.affairForm.resetFields(); // 重置表单验证
    },

    // -------------- 事件方法 ----------------
    // 不同状态下的数据下拉
    dsclick() {
      if (this.newAffairForm.ditchStatus === 'LCZT-003') {
        this.selectData.affairDetailList = this.expData.yhList;
      } else if (this.newAffairForm.ditchStatus === 'LCZT-005'){
        this.selectData.affairDetailList = this.expData.gsList;
      }
    },
    // 新增事件 index:行号  row:行数据
    addAffairDatil(index, row) {
      if (row.ditchName === 'SHSJ-003') {
        // 打开维修事件的新增流程窗口
        this.$refs.maintainDialog.newAffairVisible = true;
        this.$refs.maintainDialog.newAffairForm.ditchStatus = setDictByValue(row.detailVoList[row.detailVoList.length-1].ditchStatus);
        this.$refs.maintainDialog.newAffairForm.afterOrderNumber = row.afterOrderNumber;
        this.$refs.maintainDialog.newAffairForm.affairId = row.id;
        this.$refs.maintainDialog.getSelectData();
        return;
      }
      this.getSelectData();
      this.newTitleName = this.$t('AfterSpu.affair.addEvent');
      this.newAffairVisible = true;
      this.newAffairForm.ditchStatus = setDictByValue(row.detailVoList[row.detailVoList.length-1].ditchStatus);
      this.newAffairForm.afterOrderNumber = row.afterOrderNumber;
      this.newAffairForm.affairId = row.id;
      // 获取事件内部为签收的物流数据
      getAffairDetailByIdAndExp({
        affairId: row.id
      }).then(res => {
        if (res.data.status === 200) {
          this.expData = res.data.data;
          this.dsclick();
        }
      })
    },
    // 结束事件 index:行号  row:行数据
    finishAffairDatil(index, row) {
      this.$confirm(this.$t('AfterSpu.affair.finishTxt'),
          this.$t('common.dialog.title'), {confirmButtonText: this.$t('common.button.confirm'), cancelButtonText: this.$t('common.button.cancel'), type: 'warning'
      }).then(() => {
        // 后台请求删除数据库数据
        row.affairStatus = 2;
        updateAffair(row).then(res => {
          if (res.data.status === 200) {
            ElNotification({title: this.$t('common.message.successTitle'), message: res.data.msg, type: 'success',})
            this.getAffair();
          }
        }).catch(error => { });
      });
    },
    // 提交用户的物流信息
    newAffairAdd() {
      // 提交校验
      this.$refs.affairForm.validate((valid) => {
        if (valid) {
          const loading = ElLoading.service({
            lock: true, text: this.$t('common.loadingText'), background: 'rgba(0, 0, 0, 0.7)',}
          );
          this.newAffairForm.createStatus = '2';
          this.newAffairForm.webUrl = window.location.origin + "/#" + "/ticket/";
          saveAffairDetail(this.newAffairForm).then(res => {
            if (res.data.status === 200) {
              ElNotification({title: this.$t('common.message.successTitle'), message: res.data.msg, type: 'success',});
              this.newAffairClick();
              this.getAffair();
            }
          }).finally(() => {
            // 请求无论成功/失败 都执行关闭loading
            loading.close();
          });
        } else {
          return false;
        }
      });
    },

    // -------------- 成本费用方法 ----------------
    addAffairCost(index, row) {
      this.getSelectData();
      this.costTitleName = this.$t('AfterSpu.affair.addCost');
      this.costAffairForm.isDisplay = true;
      if (row.id) {
        this.costAffairForm.classify = row.affairNumber + ' / ' + row.ditchName + ' / ' + row.createTime;
        this.costAffairForm.affairId = row.id;
      } else {
        this.costAffairForm.classify = this.$t('AfterSpu.affair.ticketIncidents');
        this.costAffairForm.affairId = 0;
      }
      this.costAffairForm.afterOrderNumber = this.row.afterOrderNumber;
      this.costAffairForm.money = 0;
      this.costAffairForm.currency = this.selectData.currencyPullDown[0].id;
      this.costAffairForm.remarks = '';
      this.costAffairVisible = true;
    },
    // 提交按钮 - 新增费用 - 修改费用
    newCostAdd() {
      this.$refs.costform.validate((valid) => {
        if (valid) {
          const loading = ElLoading.service({
            lock: true, text: this.$t('common.loadingText'), background: 'rgba(0, 0, 0, 0.7)',}
          );
          if (this.costAffairForm.isDisplay) {
            saveAffairCost(this.costAffairForm).then(res => {
              if (res.data.status === 200) {
                ElNotification({title: this.$t('common.message.successTitle'), message: res.data.msg, type: 'success',});
                this.backtrackClick();
                this.getCost();
              }
            }).finally(() => {
              // 请求无论成功/失败 都执行关闭loading
              loading.close();
            });
          } else {
            updateAffairCost(this.costAffairForm).then(res => {
              if (res.data.status === 200) {
                ElNotification({title: this.$t('common.message.successTitle'), message: res.data.msg, type: 'success',});
                this.backtrackClick();
                this.getCost();
              }
            }).finally(() => {
              // 请求无论成功/失败 都执行关闭loading
              loading.close();
            });
          }
        } else {
          return false;
        }
      });
    },
    // 点击遮罩和x时执行
    costBeforeClose(done) {
      this.backtrackClick();
      done(); // 必须调用 done，以继续关闭对话框
    },
    // 关闭 费用页面
    backtrackClick() {
      this.costAffairVisible = false;
      this.costAffairForm = {};
      this.$refs.costform.resetFields(); // 重置表单验证
    },
    upateCostById(row) {
      this.costTitleName = this.$t('AfterSpu.affair.upCost');
      this.costAffairVisible = true;
      this.costIsReadonly = false;
      this.costAffairForm = row;
      this.costAffairForm.isDisplay = false;
    },
    deleteCostById(row) {
      this.$confirm(this.$t('common.dialog.deleteTips') , this.$t('common.dialog.deleteTitle'), {
        confirmButtonText: this.$t('common.button.confirm'), cancelButtonText: this.$t('common.button.cancel'), type: 'warning'
      }).then(() => {
        deleteAffairCostById({id:row.id}).then(res => {
          if (res.data.status === 200) {
            ElNotification({title: this.$t('common.message.successTitle'), message: res.data.msg, type: 'success',})
            this.getCost();
          }
        })
      });
    },
  },
}
</script>

<!--组件css样式 scoped:表示该样式只在此组件生效-->
<style scoped>
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.tableBox {
  height: 480px;
  overflow-y: auto;
}
.dialog-button {
  text-align: right;
  margin-top: 20px;
}
/deep/ .el-dialog__body {
  padding-top: 5px;
}
.radioBox {
  text-align: left;
  padding-bottom: 3px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

/deep/ .el-input-number .el-input__inner {
  text-align: left;
}

.dialog-footer {
  float: left;
}

/deep/ .el-dialog{
  margin:7vh auto !important;
}
</style>
<style lang="scss" >


</style>
