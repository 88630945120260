import request from '../request'


// 获取列表
export function listSidebAfter(data) {
    return request({
        url: '/sidebAfter/listSidebAfter',
        method: 'post',
        data
    })
}

// 新增
export function saveSidebAfter(data) {
    return request({
        url: '/sidebAfter/saveSidebAfter',
        method: 'post',
        data
    })
}

// 修改
export function updateSidebAfter(data) {
    return request({
        url: '/sidebAfter/updateSidebAfter',
        method: 'post',
        data
    })
}

// 删除
export function deleteSidebAfterById(params) {
    return request({
        url: '/sidebAfter/deleteSidebAfterById',
        method: 'get',
        params: params
    });
}

// 根据id查询对象数据
export function getSidebAfterById(params) {
    return request({
        url: '/sidebAfter/getSidebAfterById',
        method: 'get',
        params: params
    });
}

/**
 * 根据工单的经销商ID查询所有相关联的工单数据
 * data： {
 *   "userId": 54 经销商用户Id
 * }
 */
export function getAllTicketSidByUserId(data) {
    return request({
        url: '/sidebAfter/getAllTicketSidByUserId',
        method: 'post',
        data
    });
}

// 领取工单
export function getAfterUser(data) {
    return request({
        url: '/sidebAfter/getAfterUser',
        method: 'post',
        data
    })
}

// 指定工单负责人
export function assignAfterUser(data) {
    return request({
        url: '/sidebAfter/assignAfterUser',
        method: 'post',
        data
    })
}


// 导入表格 - 导入Excel
export function importExcel(file) {
    const formData = new FormData();
    formData.append('file', file);
    return request({
        url: '/sidebAfter/importExcel',
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data' // 设置请求头为multipart/form-data
        }
    })
}

// 导出表格 - 导出Excel
export function exportExcel(data) {
    return request({
        url: '/sidebAfter/exportExcel',
        method: 'post',
        data: JSON.stringify(data), // 将数据转换为 JSON 字符串
        headers: {
            'Content-Type': 'application/json' // 指定内容类型为 JSON
        },
        responseType: 'blob'
    })
}

// 根据供应商工单UUID查询售后单以及流程
export function getSideFlowBySelcetUrl(data) {
    return request({
        url: '/sidebAfter/getSideFlowBySelcetUrl',
        method: 'post',
        data
    })
}

// 结束工单
export function finishSideb(data) {
    return request({
        url: '/sidebAfter/finishSideb',
        method: 'post',
        data
    })
}

// 回复经销商留言
export function setSidebFlow(data) {
    return request({
        url: '/sidebAfter/setSidebFlow',
        method: 'post',
        data
    })
}

// 发起经销商新增事件前的询问
export function saveSideAffairInquire(data) {
    return request({
        url: '/sidebAfter/saveSideAffairInquire',
        method: 'post',
        data
    })
}

// 新增经销商退货退款事件流程
export function saveSideAffairDetail(data) {
    return request({
        url: '/sidebAfter/saveSideAffairDetail',
        method: 'post',
        data
    })
}

// 新增经销商维修事件流程
export function saveSideMaintainAffairDetail(data) {
    return request({
        url: '/sidebAfter/saveSideMaintainAffairDetail',
        method: 'post',
        data
    })
}

// 获取经销商的主页待分配的数量花点
export function getHomeCharData(data) {
    return request({
        url: '/sidebAfter/getHomeCharData',
        method: 'post',
        data
    })
}


// 上传经销商产品发票
export function uploadSidebInvoice(data) {
    return request({
        url: '/sidebAfter/uploadSidebInvoice',
        method: 'post',
        data
    })
}
// 根据子表id查询产品的发票列表
export function getSidebDetailInvoiceById(params) {
    return request({
        url: '/sidebAfter/getSidebDetailInvoiceById',
        method: 'get',
        params: params
    });
}

// 根据ID恢复工单状态
export function reopenSidTicket(params) {
    return request({
        url: '/sidebAfter/reopenSidTicket',
        method: 'get',
        params: params
    });
}
