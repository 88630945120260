<template>
  <div class="order-detail">
    <div class="left">
      <div style="background-color: #ffffff">
        <div style="padding: 20px 2px 0 20px">
          <div class="order-header">
            <p class="order-title">{{ $t("ClienteleQuery.divider") }}</p>
          </div>
          <el-tabs
            v-if="childrenTicketList?.length > 0"
            v-model="ticketDetail.id"
            @tab-change="changeTabSelect"
            class="demo-tabs"
            style="padding-right: 10px"
          >
            <el-tab-pane
              v-for="item in childrenTicketList"
              :key="item.id"
              :label="item.afterOrderNumber"
              :name="item.id"
            ></el-tab-pane>
          </el-tabs>
        </div>
        <afterDetail
          :ticketDetail="ticketDetail"
          @updateTicket="getTicketAllData(ticketDetail)"
        ></afterDetail>
      </div>

      <!-- <div class="order-content">
        <div
          class="order-info-item"
          v-for="(item, index) in ticketInfoData"
          :key="index"
        >
          <p class="order-info-title">{{ item.key }}</p>
          <p class="order-info-content" v-if="item.key != 'proveList'">
            {{ item.value }}
          </p>
          <div v-else>
            <p :key="prove.id" v-for="(prove, proveIndex) in item.value">
              <el-link
                type="primary"
                target="_blank"
                download
                :href="HTTP_URL_API + prove.file"
              >
                {{ prove.name }}
              </el-link>
            </p>
          </div>
        </div>
        <Invoice
          :ticketInfo="ticketDetail"
          @updateTicket="getTicketAllData(ticketDetail)"
        >
        </Invoice>
      </div> -->

      <!--      <p class="order-title">处理记录</p>-->
      <div class="conversation-record" style="margin-top: 20px">
        <ConversationItem
          v-for="(item, index) in conversationList"
          :key="item.id"
          :ticketInfo="ticketDetail"
          :conversationInfo="item"
          :style="{
            borderBottom:
              index != conversationList.length - 1
                ? '2px solid #e6e6e6'
                : 'none',
          }"
        ></ConversationItem>
      </div>

      <div
        class="reply-box"
        v-if="
          ticketDetail.pid < 1 && ![1, 4, 5].includes(ticketDetail.afterStatus)
        "
      >
        <EditorGetView ref="setEditorRef" :modeStr="'simple'"></EditorGetView>
      </div>

      <FileUpload
        ref="fileUploadRef"
        :fileFolder="'FlowFile'"
        :fileType="1"
        @startUpload="fileUploadStatus = true"
        @endUpload="fileUploadStatus = false"
        v-if="
          ticketDetail.pid < 1 && ![1, 4, 5].includes(ticketDetail.afterStatus)
        "
      ></FileUpload>

      <FootControl
        @wordConfirmClick="wordConfirmClick"
        @updateAfterStatus="updateAfterStatus"
        @refresh="getTicketAllData(ticketDetail)"
        :fileUploadStatus="fileUploadStatus"
        :ticketFormItemInfo="ticketDetail"
        @affairAndNew="getTicketAllData(ticketDetail)"
      ></FootControl>
    </div>

    <div class="right">
      <div class="ticket-detail">
        <TicketDetail :ticketInfo="ticketDetail"></TicketDetail>
      </div>

      <div class="user-profile">
        <UserProfile
          :ticketInfo="ticketDetail"
          :AllTicketList="allTicketList"
          :userTicketDetail="userTicketDetail"
          @goToTicketDetail="getTicketAllData"
        ></UserProfile>
      </div>
      <!-- <div class="time-track">

      </div> -->
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  toRefs,
  onMounted,
  defineProps,
  computed,
  defineEmits,
} from "vue";
import { formatByLanguage, getSysLanguage, stripHtmlTags } from "@/util/system";
import afterDetail from "./component/afterDetail.vue";
import TicketDetail from "./component/TicketDetail.vue";
import { FlowType } from "@/enumbag/DataEnum";
import UserProfile from "./component/UserProfile.vue";
import ConversationItem from "./component/ConversationItem.vue";
import EditorGetView from "@/components/editor/EditorGetView";
import { getAllTicketByUserId } from "@/http/afterTicket/afterSpu/afterApi";
import Invoice from "./component/Invoice.vue";
import {
  getAfterById,
  updateAfterDisposeUser,
} from "@/http/afterTicket/afterSpu/afterApi";
import { getUserTicketById } from "@/http/authApi/userTicketApi";
import FootControl from "./component/FootControl.vue";
import { HTTP_URL_API } from "@/enumbag/StyleEnum";
import {
  finishAfter,
  listAfterFlowBySelcetUrl,
  saveAfterFlow,
  updateAfterById,
} from "@/http/afterTicket/afterSpu/afterApi";
import { cloneDeep } from "lodash";
import { useI18n } from "vue-i18n";
import FileUpload from "@/components/file/FileUpload";
import {
  ElLoading,
  ElMessage,
  ElMessageBox,
  ElNotification,
} from "element-plus";


let emits = defineEmits(["cancelClick", "operateClick"]);
const { t, locale } = useI18n();
let $t = t;

let ticketFormItemInfo = ref({
  userId: "",
  id: "",
  selcetUrl: "",
  afterStatus: "",
});
function getCurUrlParam() {
  // 获取当前页面的 URL
  const currentUrl = window.location.hash;
  const paramsStr = currentUrl.split("?")[1];
  const params = new URLSearchParams(paramsStr);
  // 获取查询参数 openTicketWin 的值
  const selcetUrl = params.get("selcetUrl");
  const userId = params.get("userId");
  const ticketId = params.get("id");
  ticketFormItemInfo.value.userId = userId;
  ticketFormItemInfo.value.id = ticketId;
  ticketFormItemInfo.value.selcetUrl = selcetUrl;
}
getCurUrlParam()

let fileUploadStatus = ref(false); //文件上传状态

function changeTabSelect(curTicketId) {
  let selcetUrl, id;
  if (childrenTicketList.value.length > 0) {
    let curTicket = childrenTicketList.value.find(
      (item) => item.id == curTicketId
    );
    selcetUrl = curTicket.selcetUrl;
    id = curTicket.id;
  } else {
    id = ticketInfo.id;
    selcetUrl = ticketInfo.selcetUrl;
  }

  ticketDetail.value.id = id;
  ticketDetail.value.selcetUrl = selcetUrl;
  getTicketAllData(ticketDetail.value);
}

let childrenTicketList = ref([]);

//获取该工单创建人所有的工单
let allTicketList = ref([]);
function getAllTicket(userId = 86) {
  //  let userId=ticketFormItemInfo.value.userId

  return getAllTicketByUserId({ userId: userId }).then((res) => {
    allTicketList.value = res.data.data;
  });
}

//获取该工单详情
let ticketDetail = ref({});
function getTicketDetail(id = 97) {
  return getAfterById({ id }).then((res) => {
    ticketDetail.value = res.data.data;
    ticketDetail.value.extJson = JSON.parse(res.data.data.extJson ?? "{}");
    console.log(res.data.data.extJson);
    if (res.data.data?.pid == -1) {
      childrenTicketList.value = res.data.data?.ticketList;
      childrenTicketList.value.unshift({
        id: ticketDetail.value.id,
        selcetUrl: ticketDetail.value.selcetUrl,
        afterOrderNumber: ticketDetail.value.afterOrderNumber,
      });
    }

    if (res.data.data?.pid == 0) {
      childrenTicketList.value = [
        {
          id: ticketDetail.value.id,
          selcetUrl: ticketDetail.value.selcetUrl,
          afterOrderNumber: ticketDetail.value.afterOrderNumber,
        },
      ];
    }
  });
}

//获取留言数据
let conversationList = ref([]);
function getConversationData(selcetUrl = "c2e535ffe18a4a70b102fcb894c56a44") {
  return listAfterFlowBySelcetUrl({ selcetUrl }).then((res) => {
    conversationList.value = res.data.data?.afterFlowVoList;
  });
}

//获取用户订单的详情数据
let userTicketDetail = ref({});
function getUserTicketDetail(userId = 86) {
  return getUserTicketById({ userId }).then((res) => {
    userTicketDetail.value = res.data.data;
  });
}

let ticketInfoData = computed(() => {
  let arr = [
    {
      key: "detail",
      value: "",
    },
    {
      key: "summary",
      value: "",
    },
    {
      key: "email",
      value: "",
    },
    {
      key: "spuName",
      value: "",
    },
    {
      key: "spuSequence",
      value: "",
    },
    {
      key: "buyTime",
      value: "",
    },
    {
      key: "buyDitchName",
      value: "",
    },
    {
      key: "regionName",
      value: "",
    },
    {
      key: "proveList",
      value: "",
    },
  ];

  return arr.map((item) => {
    return {
      ...item,
      value: ticketDetail.value[item.key],
    };
  });
});

//获取订单所有数据
let ElLoadingInstance = null;
function getTicketAllData(ticketInfo) {
  ElLoadingInstance && ElLoadingInstance.close();
  ElLoadingInstance = ElLoading.service({ fullscreen: true });
  let requestList = [
    getTicketDetail(ticketInfo.id),
    getConversationData(ticketInfo.selcetUrl),
    getUserTicketDetail(ticketInfo.userId),
    getAllTicket(ticketInfo.userId),
  ];

  Promise.allSettled(requestList)
    .then((res) => {
      console.log(res);
    })
    .finally(() => {
      console.log("finally");
      ElLoadingInstance && ElLoadingInstance.close();
      ElLoadingInstance = null;
    });
}
getTicketAllData(ticketFormItemInfo.value || {});
// getTicketAllData({});

let setEditorRef = ref();
let fileUploadRef = ref();
// 消息回复按钮
function wordConfirmClick() {
  let ticketInfo = cloneDeep(ticketDetail.value);
  let str = stripHtmlTags(setEditorRef.value.html);
  if (str && str !== "" && str !== undefined) {
    ticketInfo.content = setEditorRef.value.html;
  }
  ticketInfo.remarks = setEditorRef.value.remarkDesc;
  ticketInfo.flowType = setEditorRef.value.flowTypeIs
    ? FlowType.FlOW_END
    : FlowType.FLOW;
  ticketInfo.imgs = fileUploadRef.value.fileList;
  if (!ticketInfo.content && ticketInfo.imgs.length === 0) return;
  const loading = ElLoading.service({
    lock: true,
    text: $t("common.loadingText"),
    background: "rgba(0, 0, 0, 0.7)",
  });
  let userInfo = JSON.parse(window.localStorage.getItem("account"));
  ticketInfo.title =
    locale.value === "zh-CN" ? userInfo.name : userInfo.nickName;
  ticketInfo.createStatus = 2;
  ticketInfo.webUrl = window.location.origin + "/#" + "/ticket/";
  // 提交表单
  return saveAfterFlow(ticketInfo)
    .then((res) => {
      if (res.data.status === 200) {
        ticketInfo.afterStatus = 3;

        // this.dialogFormVisible = false;
        ticketInfo.title = "";
        ticketInfo.content = "";
        fileUploadRef.value.files = [];
        fileUploadRef.value.fileList = [];

        setEditorRef.value.html = "";
        setEditorRef.value.remarkDesc = "";
        // this.$refs.replyForm.resetFields(); // 重置表单验证
        getTicketAllData(ticketInfo);
      }
      return res;
    })
    .finally(() => {
      // 请求无论成功/失败 都执行关闭loading
      loading.close();
    });
}

// 结束售后工单 type_true 回复消息后再结束工单
function updateAfterStatus(type) {
  let ticketInfo = cloneDeep(ticketDetail.value);
  ElMessageBox.confirm(
    $t("ClienteleQuery.finishTxt"),
    $t("common.dialog.title"),
    {
      confirmButtonText: $t("common.button.confirm"),
      cancelButtonText: $t("common.button.cancel"),
      type: "warning",
    }
  ).then(async () => {
    if (type) {
      await wordConfirmClick();
    }
    // 结束售后工单请求
    finishAfter(ticketInfo).then((res) => {
      if (res.data.status === 200) {
        ElNotification({
          title: $t("common.message.successTitle"),
          message: $t("AfterSpu.flow.endTag"),
          type: "success",
        });
        cancelClick();
      }
    });
  });
}

// 关闭抽屉 取消按钮
function cancelClick() {
  // 初始化富文本数据
  setEditorRef.value.html = "";
  setEditorRef.value.remarkDesc = "";
  setEditorRef.value.remarkDescIs = false;
  setEditorRef.value.flowTypeIs = false;
  // emits("operateClick");
  // emits("cancelClick");
  // 关闭新打开的窗口
window.close();
}
</script>
<style scoped lang="scss">
.order-detail {
  width: 100%;
  height: 100%;
  // overflow: auto;
  display: flex;
  background-color: #f5f5f5;
}
.order-detail {
  scrollbar-width: unset !important;
}
.order-detail div {
  scrollbar-width: unset !important;
}
.order-title {
  font-size: 18px;
  margin: 10px;
}

.left {
  width: 80%;
  margin-right: 1%;
  // border: 1px solid red;
}
.order-content {
  width: 100%;
  padding: 15px 10px;
  background-color: white;
  border: 1px solid #ededed;
  margin: 10px 0px;
}
.order-info-title {
  font-size: 20px;
  font-weight: 500;
}
.order-info-content {
  word-wrap: break-word;
}

.conversation-record {
  width: 100%;
  background-color: white;
  padding: 10px 5px;
  border: 1px solid #ededed;
  margin-bottom: 20px;
}

.right {
  width: 18%;
  //margin-right: 5%;
  // border: 1px solid blue;
}
.user-profile {
  margin: 20px 0px;
}

// :deep(.el-tabs__item) {
//   border-radius: 25px;
//   margin-right: 5px;
// }
// :deep(.el-tabs__item.is-active, .el-tabs__item:hover)  {
//   color: #409eff;
//   background-color: #f3f4f6;
// }
// :deep(.el-tabs__nav-wrap:after) {
//   background-color: var(--el-border-color-light);
//   width: 0;
// }
// :deep(.el-tabs__active-bar) {
//   background-color: rgba(0, 0, 0, 0);
// }
// :deep(.el-tabs--card>.el-tabs__header .el-tabs__nav) {
//   border: 0 solid var(--el-border-color-light);
// }
// :deep(.el-tabs--card>.el-tabs__header .el-tabs__item) {
//   border-left: 0 solid var(--el-border-color-light);
// }
// :deep(.el-tabs--card>.el-tabs__header) {
//   border-bottom: 0 solid var(--el-border-color-light);
// }
</style>
