<template>
  <!-- 消息物流填入弹出框 -->
  <div id="account-dialog">
    <el-drawer v-model="newAffairVisible" :before-close="beforeClose" :title="$t('ClienteleQuery.refundTitle')" :size="pcMediaSize?600:'100%'" >
      <el-form :rules="rules" ref="ruleForm" :model="newAffairForm" label-width="130px" label-position="top">

        <el-form-item :label="$t('ClienteleQuery.afterOrderNumber')">
          <el-input v-model="newAffairForm.afterOrderNumber" :readonly="true"></el-input>
        </el-form-item>

        <el-form-item :label="$t('ClienteleQuery.ditchStatus')" prop="ditchStatus">
          <el-select filterable v-model="newAffairForm.ditchStatus" :placeholder="$t('ClienteleQuery.ditchStatusPlaceholder')" :disabled="true" style="width: 100%">
            <el-option v-for="item in selectData.ditchStatusList" :key="item.dictValue" :label="item.dictKey" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('ClienteleQuery.refund')" prop="remarks">
          <el-input type="textarea" v-model="newAffairForm.remarks" :placeholder="$t('ClienteleQuery.refundPlaceholder')" :readonly="isReadonly"></el-input>
        </el-form-item>

      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="newAffairClick">{{ $t('common.button.back') }}</el-button>
        <el-button type="primary" @click="newAffairAdd" v-if="!isReadonly">{{ $t('common.button.submit') }}</el-button>
      </span>
      </template>
    </el-drawer>
  </div>
</template>

<script>

import {getAffairDetailByIdClientele, updateAffairDetail} from "@/http/authApi/clienteleApi";
import {ElNotification} from "element-plus";
import {getDictListByCode} from "@/util/longin";
import { useMedia } from 'vue-hooks-plus'
export default {
  name: "account-dialog",
  // data: 对象就是要渲染到页面上的数据
  data: function () {
    return {
      pcMediaSize:useMedia(['(min-width: 768px)'], [true], false),
      newAffairVisible: false,
      isReadonly: false,
      selectData: {
        ditchStatusList: [],
      },
      newAffairForm: {},
      // 表单验证规则
      rules: {
        remarks: [
          {required: true, message: this.$t('ClienteleQuery.refundPlaceholder'), trigger: 'blur'},
        ],
      },
    }
  },

  // moubted: 页面加载完毕后执行
  mounted() {

  },

  // methods: 对象中的方法，可以在页面中调用
  methods: {
    // 获取下拉数据
    async getSelectData() {
      let code = '';
      if (this.newAffairForm.ditchStatus === 'LCZT-007') {
        code = 'LCZT';
      } else {
        code = 'GDWX';
      }
      // 事件状态
      this.selectData.ditchStatusList = await getDictListByCode(code);
      // getDictPullDownClientele({dictCode: code}).then(res => {
      //   if (res.data.status === 200) {
      //     this.selectData.ditchStatusList = res.data.data;
      //   }
      // });
    },

    // 提交用户的物流信息
    newAffairAdd() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = {
            id: this.newAffairForm.affairDetailId,
            expressageStatus: 4,
            remarks: this.newAffairForm.remarks,
          }
          updateAffairDetail(data).then(res => {
            if (res.data.status === 200) {
              ElNotification({title: this.$t('common.message.successTitle'), message: res.data.msg, type: 'success',});
              this.$emit('childClick');
              this.newAffairClick();
            }
          });
        } else {
          return false;
        }
      });
    },

    // 查看物流信息
    lookLogistics() {
      this.getSelectData();
      this.isReadonly = true;
      // 根据id查询物流信息
      getAffairDetailByIdClientele({
        affairDetailId: this.newAffairForm.affairDetailId
      }).then(res => {
        if (res.data.status === 200) {
          this.newAffairForm.remarks = res.data.data.remarks;
        }
      });
    },

    // 点击遮罩和x时执行
    beforeClose(done) {
      this.newAffairClick();
      done(); // 必须调用 done，以继续关闭对话框
    },
    // 关闭
    newAffairClick() {
      this.newAffairVisible = false;
      this.isReadonly = false;
      this.newAffairForm = {};
      this.$refs.ruleForm.resetFields(); // 重置表单验证
    },
  },
}
</script>

<!--组件css样式 scoped:表示该样式只在此组件生效-->
<style scoped>
/deep/ .el-drawer__header {
  margin-bottom: 0 !important;
}
</style>
