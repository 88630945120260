<template>
  <div style="width: 100%; background-color: white; padding: 20px">
    <el-descriptions :title="$t('AfterSpu.AfterSalesWorkOrder')" border :column="4" :extra="( ticketDetail.createDay + '  ' + $t('AfterSpu.createDayUnit') )">
      <el-descriptions-item label-align="right" span="2"  :label="$t('AfterSpu.afterOrderNumber') + '：'">{{
        ticketDetail.afterOrderNumber
      }}</el-descriptions-item>
      <el-descriptions-item label-align="right" span="2" :label="$t('file.invoice') + '：'">
        <div>
          <Invoice :ticketInfo="ticketDetail" @updateTicket="updateTicket">
          </Invoice>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label-align="right" span="2" :label="$t('AfterSpu.familyName') + '：'">{{
        ticketDetail.familyName
      }}</el-descriptions-item>
      <el-descriptions-item label-align="right"
        :label="$t('ClienteleQuery.email') + '：'"
        span="2"
        >{{ ticketDetail.email }}</el-descriptions-item
      >

      <el-descriptions-item label-align="right" span="2" :label="$t('ClienteleQuery.problemTitle') + '：'">{{
        ticketDetail.problemTitle
      }}</el-descriptions-item>
      <el-descriptions-item label-align="right"
        :label="$t('ClienteleQuery.buyTime') + '：'"
        span="2"
        >{{
          ticketDetail.buyTime
            ? transformTime(ticketDetail.buyTime)
            : "--/--/--"
        }}</el-descriptions-item
      >

      <el-descriptions-item label-align="right" span="2" :label="$t('ClienteleQuery.region') + '：'">{{
        ticketDetail.regionName
      }}</el-descriptions-item>
      <el-descriptions-item label-align="right"
        :label="$t('ClienteleQuery.buyDitchName') + '：'"
        span="2"
        >
        <el-popover
            class="box-item"
            effect="light"
            :width="400"
            :content="ticketDetail.ditchRemark"
            v-if="ticketDetail.ditchRemark"
            placement="right"
        >
         <template #reference>
<!--          <el-text type="warning">{{ ticketDetail.buyDitchName }}</el-text>-->
          <el-text>
            {{ ticketDetail.channel }}
<!--            {{ ticketDetail.buyDitchName }}-->
            <el-divider direction="vertical" />
            <el-icon><ChatLineRound /></el-icon>
          </el-text>
         </template>
        </el-popover>
        <span v-else>
        {{ ticketDetail.channel }}
<!--        {{ ticketDetail.buyDitchName }}-->
        </span>
      </el-descriptions-item>

      <el-descriptions-item span="2" label-align="right" :label="$t('ClienteleQuery.spuName') + '：'"
        >{{ ticketDetail.spuName }} <el-divider direction="vertical" />{{
          ticketDetail.functionName
        }}
        <el-divider direction="vertical" />{{
          ticketDetail.seriesName
        }}</el-descriptions-item
      >
      <el-descriptions-item label-align="right"
        :label="$t('ClienteleQuery.spuSequence') + '：'"
        span="6"
        >{{ ticketDetail.spuSequence }}</el-descriptions-item
      >

      <el-descriptions-item label-align="right" span="2" :label="$t('AfterSpu.createTime') + '：'">{{
        ticketDetail.createTime
          ? transformTime(ticketDetail.createTime)
          : "--/--/--"
      }}</el-descriptions-item>
      <el-descriptions-item label-align="right" span="2" :label="$t('AfterSpu.revampTime') + '：'">{{
        ticketDetail.revampTime
          ? transformTime(ticketDetail.revampTime)
          : "--/--/--"
      }}</el-descriptions-item>
      <!-- 工单状态为完成和撤销才显示 而且只存在一个显示 -->
      <el-descriptions-item label-align="right" span="2"
        :label="$t('AfterSpu.finishTime') + '：'"
        v-if="ticketDetail.finishTime && ticketDetail.afterStatus === 4"
        >{{
          ticketDetail.finishTime
            ? transformTime(ticketDetail.finishTime)
            : "--/--/--"
        }}</el-descriptions-item
      >
      <el-descriptions-item label-align="right" span="2"
        :label="$t('AfterSpu.revokedTime') + '：'"
        v-if="ticketDetail.revokedTime && ticketDetail.afterStatus === 5"
        >{{
          ticketDetail.revokedTime
            ? transformTime(ticketDetail.revokedTime)
            : "--/--/--"
        }}</el-descriptions-item
      >
    </el-descriptions>
    <el-descriptions
     class="AfterSpuProve"
     style="margin-top: 20px"
      :title="$t('AfterSpu.AfterSalesInformation')"
      :column="1"
      border
    >
      <el-descriptions-item label-align="right" :label="$t('AfterSpu.prove') + '：'">
        <p
          :key="prove.id"
          v-for="(prove, proveIndex) in ticketDetail.proveList"
        >
          <el-link
            type="primary"
            target="_blank"
            download
            :href="HTTP_URL_API + prove.file"
          >
            {{ prove.name }}
          </el-link>
        </p>
        <span class="el-text el-text--info" v-if="!ticketDetail.proveList?.length">{{ $t('AfterSpu.proveNo') }}</span>
      </el-descriptions-item>


           <el-descriptions-item label-align="right" :label="$t('AfterSpu.remark') + '：'">
       {{ticketDetail.remarks}}
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
     style="margin-top: 20px"
      :title="$t('AfterSpu.IssueFeedback')"
      :column="1"
      border
    >
      <el-descriptions-item label-align="right" :label="$t('AfterSpu.summary') + '：'">{{
        ticketDetail.summary
      }}</el-descriptions-item>
      <el-descriptions-item label-align="right" :label="$t('AfterSpu.detail') + '：'">
        {{ ticketDetail.detail }}
      </el-descriptions-item>

      <!-- <el-descriptions-item label-align="right" :label="$t('file.fromLabel')">
        <div >
          <el-link type="primary" :underline="false">附件1.xlsx</el-link>
        <el-divider direction="vertical" />
        </div>

      </el-descriptions-item> -->
    </el-descriptions>

    <el-descriptions
      :column="1"

      border>
          <el-descriptions-item label-align="right" :label="$t('file.extraInfo') + '：'">
        <div>
          {{ ticketDetail.extJson?.firmware ? $t('pullDown.whether.yes') : $t('pullDown.whether.no') }}.
          <el-text style="margin-left: 10px">
            {{ $t("Clientele.firmwareStr") }}
          </el-text>
        </div>

      </el-descriptions-item>
        <el-descriptions-item label-align="right">
        <div>
          {{ ticketDetail.extJson?.mobile ? $t('pullDown.whether.yes') : $t('pullDown.whether.no') }}.
          <el-text style="margin-left: 10px">
            {{ $t("Clientele.mobileStr") }}
          </el-text>
        </div>
        </el-descriptions-item>
        <el-descriptions-item label-align="right">
        <div>
          {{ ticketDetail.extJson?.driver ? $t('pullDown.whether.yes') : $t('pullDown.whether.no') }}.
          <el-text style="margin-left: 10px">
            {{ $t("Clientele.driverStr") }}
          </el-text>
        </div>
        </el-descriptions-item>

        <!-- <el-descriptions-item label-align="right">
        <div>
          {{ ticketDetail.extJson?.Neo ? $t('pullDown.whether.yes') : $t('pullDown.whether.no') }}.
          <el-text style="margin-left: 10px">
            {{ $t("Clientele.NeoStr") }}
          </el-text>
        </div>
        </el-descriptions-item> -->
    </el-descriptions>

    <el-descriptions  style="margin-top: 20px" border :column="1" >
      <el-descriptions-item label-align="right"
        :label="$t('file.fromLabel') + '：'"
        v-if="ticketDetail.fileList?.length > 0"
      >
        <div style="display: flex">
          <p
            style="margin-bottom: 0px"
            :key="fileIndex"
            v-for="(file, fileIndex) in ticketDetail.fileList"
          >
            <el-link
              type="primary"
              target="_blank"
              download
              :href="HTTP_URL_API + file"
            >
              {{ $t("file.fromText") + (fileIndex+1) }}

                          <el-divider direction="vertical" v-if=" fileIndex != ticketDetail.fileList?.length - 1  "/>

            </el-link>
          </p>
        </div>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script setup name="afterDetailTest">
import {
  ref,
  reactive,
  toRefs,
  watch,
  onMounted,
  defineProps,
  defineEmits,
} from "vue";
import { HTTP_URL_API } from "@/enumbag/StyleEnum";
import InvoiceFile from "@/views/sideTicket/sidebAfter/tool/invoiceFile.vue";
import { transformTime } from "@/util/zoneDate.js";
import { formatByLanguage, getSysLanguage, stripHtmlTags } from "@/util/system";
import Invoice from "./Invoice.vue";
import {
  ELSETYPE,
  extractFileExtension,
  extractFileName,
  formatArray,
  getFileType,
  PICTURE,
  VIDEO,
} from "@/util/MyUtil";
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
let $t = t;
let props = defineProps({
  ticketDetail: {
    type: Object,
    default: () => ({}),
  },
});

let emits = defineEmits(["updateTicket"]);

function updateTicket() {
  emits("updateTicket");
}
</script>

<style scoped lang="scss">
table {
  Table-layout: fixed;
}
::v-deep(.el-descriptions__label){
  width:150px;
}
.AfterSpuProve ::v-deep(.el-descriptions__label){
  width:150px;
}


::v-deep(.el-descriptions__body .el-descriptions__table.is-bordered .el-descriptions__cell){
  border: none !important;
  padding: 8px 0px !important;
}
::v-deep(.el-descriptions__label.el-descriptions__cell.is-bordered-label ){
  background: none !important;
}
p,ol{
  margin-bottom: 0px !important;
}

::v-deep(.el-descriptions__content.el-descriptions__cell.is-bordered-content){
 word-wrap:break-word;word-break:normal;
}

</style>
<style>
table{
  Table-layout: fixed;
}
</style>
