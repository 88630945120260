<!-- 富文本编辑器 / 预设消息 -->
<template>
  <el-dialog
      v-model="dialogFormVisible" :before-close="beforeClose"
      :title='$t("common.message.message")' width="900" align-center draggable class="mgs-dialog"
  >
    <el-button @click="addMgs" size="small" class="add-box">
      <el-icon><Plus /></el-icon>
    </el-button>
    <el-tabs v-model="activeName" type="card" class="demo-tabs" @tab-change="handleClick">
      <el-tab-pane v-for="item in ditchList" :key="item.dictValue" :label="item.dictKey" :name="item.dictValue">
        <ul v-infinite-scroll="load" class="infinite-list" v-if="messageList.length > 0" style="overflow: auto; margin-top: 10px">
          <li v-for="(msg, index) in messageList" :key="msg.id" @click="setEditor(msg)" class="infinite-list-item">
            <div style="width: 85%; padding-right: 20px">
              <el-text line-clamp="1">{{ index+1 }}. {{ msg.content }}</el-text>
            </div>
            <div style="width: 12%;">
              <el-button type="warning" @click.stop="editMgs(msg)" link>
                {{ $t('common.button.edit') }}
              </el-button>
              <el-button type="danger" @click.stop="deleteMgs(msg, index)" link>
                {{ $t('common.button.delete') }}
              </el-button>
            </div>
          </li>
        </ul>
        <el-empty
            v-else
            style="height: 400px; margin-top: 10px"
            image-size="168px"
            :description="$t('common.table.noData')"
        />
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
  <mgs-operate ref="operate" @operateClick="getNewMessageData" />
</template>

<script>

import {deleteFlowMessageById, flowMessageByCategory} from "@/http/toolModule/flowMessageApi";
import mgsOperate from "@/views/toolModule/flowMessage/mgs-operate";
import {ElNotification} from "element-plus";
import {getDictListByCode} from "@/util/longin";

export default {

  name: "EditorMgs",
  components: {mgsOperate},
  // data: 对象就是要渲染到页面上的数据
  data: function () {
    return {
      // 控制对话框大开闭
      dialogFormVisible: false,
      // tab
      activeName : '',
      ditchList: [],

      messageList: [],
      pageNum: 1,         //当前页码
      pageSize: 30,        // 每页条数
      pages: 0,           // 总页数
    }
  },

  // moubted: 页面加载完毕后执行
  mounted() {

  },

  // methods: 对象中的方法，可以在页面中调用
  methods: {
    // 初始化获取分类
    async getData() {
      this.messageList = [];
      this.ditchList = await getDictListByCode('XXYS');
      this.activeName = this.ditchList[0].dictValue;
      this.getMessageData();

      // getDictPullDownClientele({dictCode: 'XXYS'}).then(res => {
      //   if (res.data.status === 200) {
      //     this.activeName = res.data.data[0].dictValue;
      //     this.ditchList = res.data.data;
      //     this.getMessageData();
      //   }
      // });
    },
    // 获取消息列表
    getMessageData() {
      let params = {
        categoryDitch: this.activeName,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }
      flowMessageByCategory(params).then(res => {
        if (res.data.status === 200) {
          this.pages = res.data.data.pages;
          this.messageList = [...this.messageList, ...res.data.data.list];
        }
      });
    },
    // 点击tab切换
    handleClick(tab) {
      this.pageNum = 1;
      this.activeName = tab;
      this.messageList = [];
      this.getMessageData();
    },
    // 滚动加载
    load() {
      if (this.pageNum >= this.pages) return;
      this.pageNum = this.pageNum + 1;
      this.getMessageData();
    },
    // 消息点击
    setEditor(item) {
      this.dialogFormVisible = false;
      this.$emit('setEditor', item);
    },
    // 新增 / 修改 弹出框
    addMgs() {
      this.$refs.operate.type = 'add';
      this.$refs.operate.dialogFormVisible = true;
      this.$refs.operate.ditchList = this.ditchList.slice(2);
    },
    editMgs(item) {
      this.$refs.operate.type = 'edit';
      this.$refs.operate.dialogFormVisible = true;
      this.$refs.operate.ditchList = this.ditchList.slice(2);
      this.$refs.operate.form = JSON.parse(JSON.stringify(item));
    },
    // 弹出框的回调
    getNewMessageData() {
      this.messageList = [];
      this.getMessageData();
    },
    deleteMgs(item, index) {
      this.$confirm(this.$t('common.dialog.deleteTips') , this.$t('common.dialog.deleteTitle'), {
        confirmButtonText: this.$t('common.button.confirm'), cancelButtonText: this.$t('common.button.cancel'), type: 'warning'
      }).then(() => {
        // 后台请求删除数据库数据
        deleteFlowMessageById({ id: item.id}).then(res => {
          if (res.data.status === 200) {
            // 删除前端渲染数组的数据
            this.messageList.splice(index, 1);
            ElNotification({title: this.$t('common.message.successTitle'), message: res.data.msg, type: 'success',})
          }
        }).catch(error => { });
      })

    },

    // 点击遮罩和x时执行
    beforeClose(done) {
      this.closeButton();
      done(); // 必须调用 done，以继续关闭对话框
    },
    // 取消按钮
    closeButton() {
      this.dialogFormVisible= false;
      this.activeName = ''
      this.ditchList = [];
      this.messageList = [];
      this.pageNum = 1;
      this.pageSize = 30;
      this.$emit('setEditor', null);
    },
    // 确认按钮
    submitButton() {
      this.dialogFormVisible= false;
      // this.$emit('operateClick', item);
    },
  },
}
</script>

<!--组件css样式 scoped:表示该样式只在此组件生效-->
<style scoped>
/deep/ .el-dialog__body {
  /*相對定位*/
  position: relative;
  padding: 0 20px !important;
}
.add-box {
  position: absolute;
  right: 30px;
  top: 65px;
  z-index: 99;
}
/deep/ .el-tabs__header {
  margin: 0px 64px 0px 0px;
}
.infinite-list {
  height: 400px;
  padding: 0;
  margin: 0;
  list-style: none;
}
.infinite-list .infinite-list-item {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 30px;
  border-bottom: 2px var(--el-color-primary-light-9) solid;
}
.infinite-list .infinite-list-item:hover {
  background: var(--el-color-primary-light-9);
  border-radius: 5px;
  cursor: pointer;
}
</style>
