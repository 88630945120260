<template>
  <div id="styleVeiw">
    <el-drawer
        v-model="drawer"
        :title="$t('file.uploadLabel')"
        :direction="direction"
        :before-close="handleClose">
      <div class="demo-drawer__content">

        <el-upload
            style="width: 100%;" class="upload-demo" drag multiple
            v-model:file-list="files" :limit="6"
            :action="httpRrl + '/clientele/uploadFileClientele'"
            :data="fileData" :headers="headers"
              :on-progress="handleProgress"
          :on-error="handleError"
            :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove"
            :on-exceed="handleExceed" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
        >
          <el-icon class="el-icon--upload"><upload-filled /></el-icon>
          <div class="el-upload__text">
            {{ $t('file.text') }} <em>{{ $t('file.textEm') }}</em>
          </div>
          <template #tip>
            <div class="el-upload__tip">
              {{ $t('file.textTip') }}
            </div>
          </template>
        </el-upload>
      </div>

      <template #footer>
        <div style="flex: auto">
          <el-button type="primary" :disabled="fileUploadStatus" @click="ok()">
            {{ $t('common.button.confirm') }}
          </el-button>
          <el-button type="info" @click="closeButton()">
            {{ $t('common.button.cancel') }}
          </el-button>
        </div>
      </template>
    </el-drawer>
  </div>
</template>

<script>

import {FILE_SIZE_LIMIT, HTTP_URL_API} from "@/enumbag/StyleEnum";
import {getSysLanguage} from "@/util/system";
import {getFileDictList} from "@/http/authApi/clienteleApi";
import {extractFileExtension} from "@/util/MyUtil";
import {ElMessage} from "element-plus";

export default {

  name: "FileUploadDrawer",
  data() {
    return {
      drawer: false,
      direction: 'rtl',
      fileUploadStatus:false,
      httpRrl: HTTP_URL_API, // 请求接口地址前缀
      // 请求接口需要携带的参数
      fileData: {
        folder: 'file'
      },
      headers: {}, // 上传请求的请求头 存放token校验
      fileTypeList: [], // 文件上传类型

      files: [], // 文件上传对象列表
      fileList: [], // 文件上传地址列表
    };
  },

  // moubted: 页面加载完毕后执行
  mounted() {

  },

  // created : 页面加载完毕后执行
  created () {
    // 后端如果验证 token 会报空 因为请求不是异步不会被路由守卫装配token 所以需要在发送请求时带上token
    this.headers = {
      token: localStorage.getItem("token"),
      language: getSysLanguage()
    };
    getFileDictList().then(res => {
      if (res.data.status === 200) this.fileTypeList = res.data.data;
    })
  },

  methods: {
   handleProgress(evt, uploadFile, uploadFiles){
        this.fileUploadStatus = true;
    },
    handleError(error, uploadFile, uploadFiles){
        this.fileUploadStatus = false;
    },
    initData() {
      this.drawer = true;
    },

    ok() {
      if (this.fileList.length === 0) {
        ElMessage({message: this.$t('file.mgsFileNull'), type: 'warning',})
        return
      }
      // 关闭抽屉 调用父组件方法进行对应的储存
      this.$emit('childClick', this.fileList);
      this.drawer = false;
      this.closeButton();
    },

    closeButton() {
      this.drawer = false;
      this.files = [];
      this.fileList = [];
    },

    handleClose(done) {
      this.closeButton();
      done();
    },

    // 上传文件之前的钩子 http://192.168.1.177:15892/tmp/test/2024-06-05/9bd37cfdfefc4efc910633a3f8cce3cd.mp4
    beforeAvatarUpload(file) {
      // 限制上传文件大小 20Mb
      const isLt2M = file.size < FILE_SIZE_LIMIT;
      if (!isLt2M) {
        this.$message.warning(this.$t('common.message.uploadFileSizeError'));
      }
      let ext = extractFileExtension(file.name)
      let isType = this.fileTypeList.includes(ext)
      if (!isType) {
        this.$message.warning(this.$t('common.message.uploadFileFormatErr'));
      }
      return isLt2M && isType;
    },
    // 上传之后的res结果集 /tmp/test/2024-06-05/9bd37cfdfefc4efc910633a3f8cce3cd.mp4
    handleAvatarSuccess(res, file) {
      if (res.status === 200) {
        if (this.files && this.files.length > 0) {
          let imgs = [];
          for (let i = 0; i < this.files.length; i++) {
            let fileData = {
              file: this.files[i].response.data,
              name: this.files[i].name
            }
            imgs.push(fileData);
          }
          this.fileList = imgs;
        }
      } else {
        this.$message.warning(res.msg);
        let arr = []
        for (const key of this.files) {
          if (key.response.status === 200) arr.push(key)
        }
        this.files = arr
      }
      this.fileUploadStatus = false;
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      if (this.files && this.files.length > 0) {
        let imgs = [];
        for (let i = 0; i < this.files.length; i++) {
          imgs.push(this.files[i].response.data);
        }
        this.fileList = imgs;
      }
      // console.log(file, fileList);
    },
    // 文件列表预览文件时的钩子
    handlePreview(file) {
      // console.log(file);
      // console.log(this.fileList, 'fileList');
    },
    // 文件超出个数限制时的钩子
    handleExceed(files, fileList) {
      // this.$message.warning(`当前限制选择 6 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      this.$message.warning(this.$t('common.message.uploadFileNumError'));
    },
    // 删除文件之前的钩子，参数为上传的文件和文件列表，若返回 false 或者返回 Promise 且被 reject，则停止删除。
    beforeRemove(file, fileList) {
      this.fileUploadStatus = false;
      return true;
    }
  }
};
</script>

<!--组件css样式 scoped:表示该样式只在此组件生效-->
<style scoped>
/deep/ .el-drawer__header {
  margin-bottom: 0;
}
</style>
