import request from '../request'


// 获取列表
export function flowMessageByCategory(data) {
    return request({
        url: '/flowMessage/flowMessageByCategory',
        method: 'post',
        data
    })
}

// 新增 / 修改
export function newFlowMessage(data) {
    return request({
        url: '/flowMessage/newFlowMessage',
        method: 'post',
        data
    })
}

// 删除
export function deleteFlowMessageById(params) {
    return request({
        url: '/flowMessage/deleteFlowMessageById',
        method: 'get',
        params: params
    });
}
