<template>
  <head>
    <title>{{ $t("elTag.headerTitle") }}</title>
  </head>
  <div id="app">
     <!-- 主路由渲染 -->
    <el-config-provider :locale="locale">
     
      <transition
        enter-active-class="animate__animated animate__fadeInRight"
        leave-active-class="animate__animated  animate__fadeOutRight"
      >
        <router-view @childClick="upApp"></router-view>
      </transition>
    </el-config-provider>

  </div>
</template>

<script>
import { defineComponent } from "vue";
import { ElConfigProvider } from "element-plus";
import en from "element-plus/dist/locale/en.mjs";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import {getSysConfigFront} from "@/http/authApi/clienteleApi";
import useSysStore from "@/stores/sysStore";
export default defineComponent({
  name: "app",
  components: {
    ElConfigProvider,
  },
  data() {
    return {
      locale: zhCn,
      sysStore: useSysStore(),
    };
  },
  mounted() {
    // 设置语言
    this.setLanguage();
    // 设置主题
    this.setTheme();
    this.sysStore.getSysConfig()

  },
  // 根据子组件传递的值进行切换组件的国际化语言
  methods: {
    upApp(type) {
      if (type === "theme") {
        // 设置主题
        this.setTheme();
      }
    },
    setLanguage() {
      // 获取浏览器语言 - 语言切换 (zh-CN - 中文，en - 英文)
      let languageStr = navigator.language;
      // 从缓存中获取语言 - 语言切换 (zh-CN - 中文，en-US - 英文)
      let language = window.localStorage.getItem("navigatorLanguage");
      // 如果缓存中有值，就用缓存中的值
      if (language) {
        languageStr = language;
      }
      if (languageStr === "zh-CN") {
        this.locale = zhCn;
        this.$i18n.locale = "zh"; // 切换 I18n 语言包 选项式写法
      } else if (languageStr === "en-US") {
        this.locale = en;
        this.$i18n.locale = "en"; // 切换 I18n 语言包 选项式写法
      } else {
        this.locale = en;
        this.$i18n.locale = "en"; // 切换 I18n 语言包 选项式写法
      }
    },
    // 设置主题
    setTheme() {
      // 从缓存中获取主题
      let themeSwitch = window.localStorage.getItem("themeSwitch");
      if (themeSwitch === "true") {
        // 设置主题 - 暗黑主题 组件内部切换
        let html = document.documentElement;
        html.className = "dark";
        // 引入全局样式 - 暗黑主题
        import("@/assets/css/app_dark.css");
      } else {
        // 设置主题 - 亮白主题 组件内部切换
        let html = document.documentElement;
        html.className = "light";
        // 引入全局样式 - 亮白主题
        import("@/assets/css/app_light.css");
      }
    },
  },
});
</script>

<!-- 全局样式 -->
<style >
@import "@/assets/css/fonts.css";
@import "@/assets/css/app.css";
@import "@/assets/css/sys_style.css";
/* @import '@/style/styles.scss'; */
@import "@/style/element.scss";

/* 覆盖 Bootstrap 对组件表单的影响 */
label {
  margin-bottom: 0 !important;
}
</style>
<style lang="scss">
::v-deep(.el-form--inline .el-form-item) {
  display: unset !important;
}
.vuepress-markdown-body {
  color: var(--w-e-textarea-color) !important;
  font-size: var(--bs-body-font-size) !important;
  font-weight: var(--bs-body-font-weight) !important;
  font-family: "display_semibold", "text_regular", sans-serif !important;
}
.vuepress-markdown-body a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration: underline !important;
}

:focus-visible {
  outline: none !important; /* 移除聚焦轮廓 */
}
.el-drawer__header {
  margin-bottom: 0 !important;
}
.card-header {
  border-bottom: 0 solid rgba(0, 0, 0, 0.125) !important;
}

.v-md-editor-preview {
}
.autosize{
  width:800px;
  height:500px;
  border: 1px solid red;
}
</style>
