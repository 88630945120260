import request from '../request'

// 根据用户id获取用户信息和客户工单数据
export function getUserTicketById(params) {
  return request({
    url: '/userTicket/getUserTicketById',
    method: 'get',
    params: params
  })
}
// 根据用户id获取用户信息和经销商工单数据
export function getUserSidTicketById(params) {
  return request({
    url: '/userTicket/getUserSidTicketById',
    method: 'get',
    params: params
  })
}

// 查询用户关联的工单列表
export function getAuthUserTicketList(data) {
  return request({
    url: '/userTicket/getAuthUserTicketList',
    method: 'post',
    data
  })
}

// 前台用户根据ID恢复工单（重新打开已关闭或者已撤销的工单）
export function fontReopenTicketById(data) {
  return request({
    url: '/userTicket/fontReopenTicketById',
    method: 'post',
    data
  })
}
