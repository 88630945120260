<template>
 <div class="foot-control">
  
        <div  class="button-div">
      
          <div>
            <!-- <el-button type="primary" @click="gotoAffair()">{{ $t('common.button.record') }}</el-button> -->

                <el-button type="primary" :disabled="fileUploadStatus" @click="wordConfirmClick()" class="sendMgs" v-show="isInitTicket">
              {{ $t('common.button.reply') }}
            </el-button>
<!--            <el-button type="success" @click="this.$refs.saveNotes.initData(this.form)" v-show="ticketFormItemInfo.afterStatus !== 4 && ticketFormItemInfo.afterStatus !== 5 && isInitTicket && ">-->
<!--              {{ $t('common.button.after') }}-->
<!--            </el-button>-->
            <!-- <el-button type="info" @click="getAfterFlow()" v-show="ticketFormItemInfo.afterStatus !== 4 && ticketFormItemInfo.afterStatus !== 5">{{ $t('common.button.search') }}</el-button> -->
          </div>
        
          <div>
                <el-button v-if="ticketFormItemInfo.afterStatus != 1" type="primary" @click="gotoAffair()">{{ $t('common.button.record') }}</el-button>
               <el-button type="warning" v-if="ticketFormItemInfo.afterStatus==1&&ticketFormItemInfo.pid<1 " @click="setTicketDisposeUser()">{{ $t('common.button.receive') }}</el-button>

            <el-button type="warning"  :disabled="fileUploadStatus" @click="updateAfterStatus(true)"  v-show="isInitTicket">
              {{ $t('common.button.ReplayAndClose') }}
            </el-button>
            <el-button type="danger" @click="updateAfterStatus(false)" v-show="isInitTicket">
              {{ $t('common.button.close') }}
            </el-button>
               <el-button type="primary" @click="refresh()" style="margin-left: 10px">
              {{ $t('HomeView.itemDropdown.refresh') }}
            </el-button>
          </div>
        </div>

              
      </div>


        <!-- 新建工单事件 -->
  <SaveNotes ref="saveNotesRef" @refreshListAndDetail="refreshListAndDetail"/>

  <!-- 记录 弹出框 -->
  <afterSpu-affair ref="affairRef" @childClick="affairAndNew"/>
  <!-- 用户 维修事件 填入 -->
  <logisticsDialog ref="logisticsDialogRef" @childClick="affairAndNew"/>
  <!-- 用户 退款账户 填入 -->
  <accountDialog ref="accountDialogRef" @childClick="affairAndNew"/>

  <editor-dialog ref="editorDialogRef" @operateClick="callBack"/>
  <file-dialog ref="fileDialogRef"/>
</template>

<script setup >
import { ref, reactive, toRefs, onMounted,defineProps, computed,defineEmits,nextTick} from 'vue'
import SaveNotes from "@/views/afterTool/notes/saveTicketNotes.vue";
import afterSpuAffair from "@/views/afterTicket/afterSpu/afterSpu-affair";
import logisticsDialog from "@/views/afterTicket/afterSpu/dialog/logistics-dialog";
import accountDialog from "@/views/afterTicket/afterSpu/dialog/account-dialog";
import EditorDialog from "@/components/editor/Editor-Dialog";
import FileDialog from "@/components/file/File-Dialog.vue";
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  finishAfter,
  listAfterFlowBySelcetUrl,
  saveAfterFlow,
  updateAfterById
} from "@/http/afterTicket/afterSpu/afterApi";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
let $t = t;

let emits=defineEmits(['wordConfirmClick','updateAfterStatus','affairAndNew','refresh'])

let props = defineProps({
    ticketFormItemInfo:{  //点击外层工单表单项赋值该字段
      type: Object,
      default: () => ({
        userId:'',
        id:'',
        selcetUrl:'',
        afterStatus:'',
      })
    },
    fileUploadStatus:{
      type: Boolean,
      default: () => (false)
    }
})

let affairRef=ref()
let saveNotesRef=ref()
let logisticsDialogRef=ref()
let accountDialogRef=ref()
let editorDialogRef=ref()
let fileDialogRef=ref()
 // 打开记录详情页面
   function gotoAffair() {
 
     affairRef.value.dialogFormVisible = true;
   
     affairRef.value.row =props.ticketFormItemInfo;
     affairRef.value.gotoExecute();
    }

     // 操作按钮的显隐
  let  isInitTicket=computed(() =>{
      // 显隐规则：
      // 当前tab工单为带分配则为false，需要线上领取按钮
      if (![1,4,5].includes(props.ticketFormItemInfo.afterStatus) && props.ticketFormItemInfo.pid < 1) {
        return true
      } else {
        return false
      }
      // returnprops.ticketFormItemInfo.id === this.formRow.id
    })

        // 待分配的领取
  function  setTicketDisposeUser() {
      ElMessageBox.confirm($t('AfterSpu.receiveTxt'), $t('common.dialog.title'), {confirmButtonText: $t('common.button.confirm'), cancelButtonText: $t('common.button.cancel'), type: 'warning'}).then(() => {
        let user = JSON.parse(window.localStorage.getItem("account"));
        updateAfterById({
          id:props.ticketFormItemInfo.id,
          disposeUser: user.id,
          afterStatus: 2,
        }).then(res => {
          if (res.data.status === 200) {
            // 刷新左侧工单列表和详情页组件
            
           props.ticketFormItemInfo.afterStatus = 2
           refresh()
          }
        });
      })
    }

    // 获取留言数据
  function  getAfterFlow() {
      listAfterFlowBySelcetUrl({
        selcetUrl:props.ticketFormItemInfo.selcetUrl
      }).then(res => {
        if (res.data.status === 200) {
          this.afterFlowVoList = formatArray(res.data.data.afterFlowVoList);
          // 确保 DOM 更新后再使用
         nextTick(() => {
            // 根据标签class属性获取容器元素
            let container = document.getElementsByClassName('scrollContainer')[0];
            // 设置滚动条位置到底部
            container.scrollTop = container.scrollHeight;
          });
        }
      });
    }


//消息回复按钮
function wordConfirmClick(){
    emits('wordConfirmClick')
}

function affairAndNew(){
   emits('affairAndNew')
}
function refresh(){
    emits('refresh')
    }

 // 结束售后工单 type_true 回复消息后再结束工单
  function  updateAfterStatus(type) {
     emits('updateAfterStatus',type)
  }
</script>
<style scoped lang="scss">
.foot-control{
    display: flex;
    width: 100%;
    margin: 10px 10px 30px;
    padding:0px 10px 30px 0px;
}
.button-div{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.sendMgs{
  // margin: 0px 10px;
}
</style>