import request from '../../request'


// 获取列表
export function listAfter(data) {
    return request({
        url: '/after/listAfter',
        method: 'post',
        data
    })
}

// 获取列表
export function listAfterNum(data) {
    return request({
        url: '/after/listAfterNum',
        method: 'post',
        data
    })
}

// 新增
export function saveAfter(data) {
    return request({
        url: '/after/saveAfter',
        method: 'post',
        data
    })
}

// 修改
export function updateAfter(data) {
    return request({
        url: '/after/updateAfter',
        method: 'post',
        data
    })
}

// 领取工单
export function updateAfterById(data) {
    return request({
        url: '/after/updateAfterById',
        method: 'post',
        data
    })
}

// 指定工单
export function updateAfterDisposeUser(data) {
    return request({
        url: '/after/updateAfterDisposeUser',
        method: 'post',
        data
    })
}

// 删除
export function deleteAfterById(params) {
    return request({
        url: '/after/deleteAfterById',
        method: 'get',
        params: params
    });
}

// 根据ID查询详情
export function getAfterById(params) {
    return request({
        url: '/after/getAfterById',
        method: 'get',
        params: params
    });
}

// 根据工单的客户ID查询所有相关联的工单数据
export function getAllTicketByUserId(data) {
    return request({
        url: '/userTicket/getAllTicketByUserId',
        method: 'post',
        data
    });
}




// 根据售后单号查询售后单流程
export function listAfterFlowBySelcetUrl(data) {
    return request({
        url: '/after/listAfterFlowBySelcetUrl',
        method: 'post',
        data
    });
}

// 分享页根据售后单号查询售后单流程
export function shareListAfterFlowBySelcetUrl(data) {
    return request({
        url: '/after/ticketShareSelectUrl',
        method: 'post',
        data
    });
}


// 新增回复数据
export function saveAfterFlow(data) {
    return request({
        url: '/after/saveAfterFlow',
        method: 'post',
        data
    });
}

// 结束工单
export function finishAfter(data) {
    return request({
        url: '/after/finishAfter',
        method: 'post',
        data
    });
}

// 获取首页统计数据 卡片
export function getStatisticsData() {
    return request({
        url: '/after/getStatisticsData',
        method: 'get',
    });
}

// 获取首页图表数据 可视化
export function getHomeCharData(data) {
    return request({
        url: '/after/getHomeCharData',
        method: 'post',
        data
    });
}

// 售后看板数据
export function getBoardAfter(data) {
    return request({
        url: '/after/getBoardAfter',
        method: 'post',
        data
    });
}


// 导入表格 - 导入Excel
export function importExcel(file) {
    const formData = new FormData();
    formData.append('file', file);
    return request({
        url: '/after/importExcel',
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data' // 设置请求头为multipart/form-data
        }
    })
}

// 导出表格 - 导出Excel
export function exportExcel(data) {
    return request({
        url: '/after/exportExcel',
        method: 'post',
        data: JSON.stringify(data), // 将数据转换为 JSON 字符串
        headers: {
            'Content-Type': 'application/json' // 指定内容类型为 JSON
        },
        responseType: 'blob'
    })
}

// 上传工单附件发票
export function uploadTicketInvoice(data) {
    return request({
        url: '/after/uploadTicketInvoice',
        method: 'post',
        data
    });
}



// 获取列表
export function listMergeTicket(data) {
    return request({
        url: '/after/listMergeTicket',
        method: 'post',
        data
    })
}
// 客户工单合并
export function mergeTicket(data) {
    return request({
        url: '/after/mergeTicket',
        method: 'post',
        data
    });
}
// 取消客户工单合并
export function cancelMergeTicket(data) {
    return request({
        url: '/after/cancelMergeTicket',
        method: 'post',
        data
    });
}

// 根据ID恢复工单状态
export function reopenTicket(params) {
    return request({
        url: '/after/reopenTicket',
        method: 'get',
        params: params
    });
}
