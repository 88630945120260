<template>
  <div id="style-operate">
    <!-- 对话框 dialogFormVisible 控制开关 -->
    <el-dialog v-model="dialogFormVisible" :before-close="beforeClose" :title="titleName" width="1200" draggable>

      <!-- form 查询条件 -->
      <el-form :inline="true" :model="fromData" class="demo-form-inline" :label-width="80" label-position="right">
        <el-form-item :label="$t('SpuView.spuNameQuery')">
          <el-input v-model="fromData.spuName" :placeholder="$t('SpuView.spuNameQueryPlaceholder')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('SpuView.series')">
          <el-select clearable filterable v-model="fromData.series" :placeholder="$t('SpuView.seriesPlaceholder')">
            <el-option v-for="item in selectData.seriesList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('SpuView.function')">
          <el-select clearable filterable v-model="fromData.function" :placeholder="$t('SpuView.functionPlaceholder')">
            <el-option v-for="item in selectData.functionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('SpuView.createUser')">
          <el-select clearable filterable v-model="fromData.createUser" :placeholder="$t('SpuView.createUserPlaceholder')">
            <el-option v-for="item in selectData.userList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="listData()" > {{ $t('common.button.search') }} </el-button>
          <el-button type="info" @click="reset()" >  {{ $t('common.button.reset') }} </el-button>
        </el-form-item>
      </el-form>

      <!-- table 数据列表 -->
      <div class="tabBox">
        <el-table :data="tableData" ref="multipleTable" max-height="400" @select-all="allClick" @select="handleRowClick" stripe border fit highlight-current-row :empty-text="$t('common.table.noData')" style="width: 100%; margin-bottom: 5px;">
          <!-- 多选: settype_false -->
          <el-table-column v-if="!setType" type="selection" width="55" show-overflow-tooltip></el-table-column>
          <!-- 单选: settype_true -->
          <el-table-column align="center" v-if="setType" fixed="left" :label="$t('common.button.select')" width="100">
            <template #default="scope">
              <el-button type="primary" size="small" @click="chooseRow(scope.$index, scope.row)">{{ $t('common.button.select') }}</el-button>
            </template>
          </el-table-column>
          <!-- 表格列数据  -->
          <el-table-column fixed type="index" :label="$t('common.table.number')" width="90"/>
          <el-table-column prop="spuCode" :label="$t('SpuView.spuCode')" width="265" show-overflow-tooltip></el-table-column>
          <el-table-column prop="spuName" :label="$t('SpuView.spuName')" width="150" show-overflow-tooltip v-if="languageStr === 'zh-CN'"></el-table-column>
          <el-table-column prop="nameEn" :label="$t('SpuView.nameEn')" width="150" show-overflow-tooltip v-if="languageStr === 'en-US'"></el-table-column>
          <el-table-column prop="spuImg" :label="$t('SpuView.spuImg')" width="100">
            <template v-slot="{ row }">
              <el-avatar shape="square" :size="40" :src="getUrl(row.spuImg)" />
            </template>
          </el-table-column>
          <el-table-column prop="seriesName" :label="$t('SpuView.series')" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="functionName" :label="$t('SpuView.function')" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="specs" :label="$t('SpuView.specs')" width="180" show-overflow-tooltip></el-table-column>
          <el-table-column prop="remarks" :label="$t('SpuView.remarks')" width="250" show-overflow-tooltip></el-table-column>
          <el-table-column prop="remarksEn" :label="$t('SpuView.remarksEn')" width="350" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createUserNmae" :label="$t('SpuView.createUser')" width="180" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createTime" :label="$t('SpuView.createTime')" width="180" show-overflow-tooltip></el-table-column>
        </el-table>
      </div>

      <!-- pagination 底部分页 -->
      <div style="margin-top: 10px; margin-bottom: 50px">
        <el-pagination background v-model:current-page="pageNum" v-model:page-size="pageSize" :page-sizes="[10, 30, 50, 75, 100]" :small="false" layout="prev, pager, next, sizes, jumper, total" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
      </div>

      <!-- 对话框底部按钮 setType选入隐藏确认按钮 -->
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeButton()">
            {{ $t('common.button.cancel') }}
          </el-button>
          <el-button v-if="!setType" type="primary" @click="submitButton()">
            {{ $t('common.button.confirm') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {getFunctionPullDown, getSeriesPullDown, getUserAdminPullDown} from "@/http/home/PullDownApi";
import {resourceIsUser} from "@/util/resource";
import {ElLoading} from "element-plus";
import {listSpuInfo} from "@/http/spuData/spuApi";
import {getSysLanguage} from "@/util/system";
import {HTTP_URL_API} from "@/enumbag/StyleEnum";

export default {
  name: "style-operate",
  // data: 对象就是要渲染到页面上的数据
  data: function () {
    return {
      languageStr: getSysLanguage(),
      // 单选: settype_true 多选: settype_false
      setType: true,
      // 对话框标题
      titleName: this.$t('common.dialog.selectTitle'),
      // 控制对话框大开闭
      dialogFormVisible: false,

      // 查询条件宽度
      formLabelWidth: '80px',
      // 下拉框数据集合对象
      selectData: {},
      // 查询条件对象
      fromData: {},

      // 列表数据
      tableData: [],
      total: 0,           // 总条数
      pages: 0,           // 总页数
      pageNum: 1,         //当前页码
      pageSize: 30,        // 每页条数

      // 父组件的已选中的数据
      chooseMap: new Map(),
      // 父组件的已选中的数据 原始数据用于替换
      formerMap: new Map(),
    }
  },

  // moubted: 页面加载完毕后执行
  mounted() {

  },

  // methods: 对象中的方法，可以在页面中调用
  methods: {
    // 获取按钮权限
    isButton(symbolStr) {
      return resourceIsUser(symbolStr);
    },
    // 指定图片的前缀
    getUrl(photoUrl) {
      // return process.env.VUE_APP_BACKEND_API_URL + photoUrl;
      return HTTP_URL_API + photoUrl;
    },
    // 父组件调用负责子组件的初始化: 查询条件集合 数据列表 回显
    getPullDownData() {
      getUserAdminPullDown().then(res => {
        if (res.data.status === 200) this.selectData.userList = res.data.data;
      });
      getSeriesPullDown().then(res => {
        if (res.data.status === 200) this.selectData.seriesList = res.data.data;
      });
      getFunctionPullDown().then(res => {
        if (res.data.status === 200) this.selectData.functionList = res.data.data;
      });
      // 查询选入框列表数据
      this.listData();
    },

    // 重置按钮
    reset() {
      this.fromData = {};
      this.listData();
    },

    // 列表数据请求
    listData() {
      const loading = ElLoading.service({lock: true, background: 'rgba(252,252,252,0.54)',})
      this.fromData.pageSize = this.pageSize;
      this.fromData.pageNum = this.pageNum;
      listSpuInfo(this.fromData).then(res => {
        this.tableData = res.data.data.list;
        // 给分页组件的数据赋值
        this.total = res.data.data.total;
        this.pages = res.data.data.pages;
        this.pageNum = res.data.data.pageNum;
        this.pageSize = res.data.data.pageSize;
        // 如果是多选的时候回显选中的数据
        if (!this.setType) {
          this.$nextTick(() => {
            // 在下一次 DOM 更新周期中获取到新的 optionData
            this.setSelection();
          }, 300);
        }
      }).finally(() => {
        // 请求无论成功/失败 都执行关闭loading
        loading.close();
      });
    },
    // 设置每页条数方法 val=条数
    handleSizeChange(val) {
      this.pageNum = 1;    // 修改当前页为第1页
      this.pageSize = val; // 修改每页条数
      this.listData();
    },
    // 点击 前一页、后一页、页码 事件
    handleCurrentChange(val) {
      this.pageNum = val; // 修改当前页码
      this.listData();
    },

    // ----------- 按钮方法 ------------
    // 点击遮罩和x时执行
    beforeClose(done) {
      this.closeButton();
      done(); // 必须调用 done，以继续关闭对话框
    },
    // 取消按钮
    closeButton() {
      this.dialogFormVisible = false;
      this.selectData = {};
      this.fromData = {};
      this.tableData = [];
      this.chooseMap = new Map();
      this.formerMap = new Map();
    },


    // ----------- 单选: settype_true ------------
    // 单选按钮 选中行到父组件
    chooseRow(index, row) {
      // 调用父组件的方法 赋值
      this.$emit('childClick', row);
      // 关闭选入框 清理数据
      this.closeButton();
    },

    // ----------- 多选: settype_false ------------
    // 查询列表数据执行 / 回显选择数据 注意异步问题
    setSelection() {
      if (this.chooseMap.size == 0) {
        return;
      }
      let ids = [];
      // 获取父组件传递的数据id必须跟列表数据的id一致
      this.chooseMap.forEach((item, id) => {
        ids.push(id);
      });
      this.tableData.forEach((item) => {
        if (ids.includes(item.id)) {
          this.$refs.multipleTable.toggleRowSelection(item);
        }
      });
    },
    // 用户点击全选的时候触发 list: 选中的数据
    allClick(list) {
      // 判断是否全选
      if (list.length == 0) {
        this.tableData.forEach(item => {
          this.chooseMap.delete(item.id);
        });
      } else {
        this.tableData.forEach(item => {
          this.chooseMap.set(item.id, item)
        });
      }
    },
    // 用户点击行勾选的时候触发 list: 选中的数据 row: 当前行数据
    handleRowClick(list, row) {
      // 判断 row 是否选中
      if (list.length == 0 && row) {
        this.chooseMap.delete(row.id);
        return
      }
      list.forEach(item => {
        if (item.id == row.id) {
          // 选中
          this.chooseMap.set(row.id, row);
        } else {
          // 取消选中
          this.chooseMap.delete(row.id);
        }
      });
    },

    // 确认按钮 关闭页面传递选择的数据到父组件
    submitButton() {
      // 判断选择的数据是否存在之前的数据 有则替换
      this.formerMap.forEach((item, id) => {
        if (this.chooseMap.has(id)) {
          this.chooseMap.set(id, item)
        }
      });
      // 获取选中的数据发送到父组件
      this.$emit('childClick', Array.from(this.chooseMap.values()));
      // 关闭选入框
      this.closeButton();
    },
  },
}
</script>

<!--组件css样式 scoped:表示该样式只在此组件生效-->
<style scoped>

.dialog-footer button:first-child {
  margin-right: 10px;
}

.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .el-dialog__body {
  padding-top: 5px;
  padding-bottom: 5px;
}

.tabBox{
  width: 100%;
  height: 400px;
  border: 1px solid rgba(70, 69, 69, 0.27);
  border-radius: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
  overflow: auto;
}
</style>
