<!-- 附件上传与查询下载 -->
<template>
    <el-drawer
        v-model="dialogFormVisible"
        :before-close="beforeClose"
        :title="$t('file.invoiceTable')"
        size="700px"
    >
      <el-table :data="tableData" height="740" :empty-text="$t('common.table.noData')" stripe border fit highlight-current-row style="width: 100%; margin-bottom: 5px;">
        <el-table-column fixed type="index" width="50"/>
        <el-table-column prop="name" :label="$t('file.name')" show-overflow-tooltip></el-table-column>
        <el-table-column v-if="deleteVisible" prop="createTime" :label="$t('file.time')" width="180" show-overflow-tooltip></el-table-column>
        <el-table-column fixed="right" :label="$t('common.table.operation')" width="130">
          <template #default="scope">
            <el-button type="success" size="small" circle @click="downloadFile(scope.row.file)">
              <el-icon><Download /></el-icon>
            </el-button>
            <el-button v-if="deleteVisible" type="danger" size="small" circle @click="deleteFile(scope.$index, scope.row)" >
              <el-icon><DeleteFilled /></el-icon>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="closeButton">{{ $t('common.button.back') }}</el-button>
        </div>
      </template>
    </el-drawer>
</template>

<script>

import {HTTP_URL_API} from "@/enumbag/StyleEnum";
import {ElNotification} from "element-plus";
import {deleteFileStrById} from "@/http/system/fileApi";
import {formatArray} from "@/util/MyUtil";

export default {
  name: "FileOperate",
  // data: 对象就是要渲染到页面上的数据
  data: function () {
    return {
      // 控制对话框大开闭
      dialogFormVisible: false,
      deleteVisible: false,
      // 文件列表
      tableData: [],
    }
  },

  // moubted: 页面加载完毕后执行
  mounted() {

  },

  // methods: 对象中的方法，可以在页面中调用
  methods: {
    // 数据初始化
    initData(fileList) {
      this.deleteVisible = true;
      this.initFile(fileList);
    },
    // 数据初始化 (客户端仅查看)
    initSelectData(fileList) {
      this.deleteVisible = false;
      this.initFile(fileList);
    },
    // 数据初始化
    initFile(fileList) {
      this.tableData = formatArray(fileList);
      this.dialogFormVisible = true;
    },

    downloadFile(url) {
      window.open(HTTP_URL_API + url);
    },
    deleteFile(index, row) {
      this.$confirm(this.$t('common.dialog.deleteTips') , this.$t('common.dialog.deleteTitle'), {
        confirmButtonText: this.$t('common.button.confirm'), cancelButtonText: this.$t('common.button.cancel'), type: 'warning'
      }).then(() => {
        // 后台请求删除数据库数据
        deleteFileStrById([row.id]).then(res => {
          if (res.data.status === 200) {
            // 删除前端渲染数组的数据
            this.tableData.splice(index, 1);
            ElNotification({title: this.$t('common.message.successTitle'), message: res.data.msg, type: 'success',})
          }
        })
      })
    },

    // 点击遮罩和x时执行
    beforeClose(done) {
      this.closeButton();
      done(); // 必须调用 done，以继续关闭对话框
    },
    // 取消按钮
    closeButton() {
      this.dialogFormVisible= false;
    },
    // 确认按钮
    submitButton() {
      this.dialogFormVisible= false;
    }
  },
}
</script>

<!--组件css样式 scoped:表示该样式只在此组件生效-->
<style scoped>

</style>
