import {Boot} from '@wangeditor/editor';

/**
 * 自定义菜单 (消息预设按钮)
 */
class editorMgsButton {
    constructor() {
        // 自定义菜单标题
        this.title = 'Message';
        // 菜单显示图标
        this.iconSvg = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" data-v-6fbb019e=""><path fill="currentColor" d="M160 826.88 273.536 736H800a64 64 0 0 0 64-64V256a64 64 0 0 0-64-64H224a64 64 0 0 0-64 64zM296 800 147.968 918.4A32 32 0 0 1 96 893.44V256a128 128 0 0 1 128-128h576a128 128 0 0 1 128 128v416a128 128 0 0 1-128 128z"></path><path fill="currentColor" d="M352 512h320q32 0 32 32t-32 32H352q-32 0-32-32t32-32m0-192h320q32 0 32 32t-32 32H352q-32 0-32-32t32-32"></path></svg>' // 可选
        this.tag = 'button';
    }

    // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
    getValue(editor) {
        return '';
    }

    // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
    isActive(editor) {
        return false;
    }

    // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
    isDisabled(editor) {
        return false;
    }

    // 点击菜单时触发的函数
    exec(editor, value) {
        if (this.isDisabled(editor)) return;
        editor.insertText(value); // 插入文本
        // 触发 Vue 组件中的事件 | 在编辑器的@onCreated="onCreated"中进行监听
        editor.emit("formulaClick")
    }
}

export const editorMgsConf = {
    key: 'menuMgs', // 定义 menu key：要保证唯一、不重复（重要）
    factory() {
        return new editorMgsButton(); // 实例化菜单
    }
};
Boot.registerMenu(editorMgsConf);
