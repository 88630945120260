import {sysEnum, windowEnum} from "@/enumbag/SysEnum";
import {getDictPullDownClientele} from "@/http/authApi/clienteleApi";
import {getSysLanguage} from "@/util/system";
import {getDetailByDictCode, getDictList} from "@/http/auth/authUserApi";
import {getSysConfigClienteleData} from "@/http/auth/authNoGain";

/**
 * 登录缓存基础数据
 * @returns {any}
 */
export const setLogin = async () => {
    // 缓存字典的基础数据
    const res = await getDictList();
    if (res.data.status !== 200) return;
    let dictMap = new Map();
    res.data.data.forEach(item => {
        if (dictMap.has(item.dictCode)) {
            dictMap.get(item.dictCode).push(item);
        } else {
            dictMap.set(item.dictCode, [item]);
        }
    });
    localStorage.setItem(windowEnum.dict, JSON.stringify(Array.from(dictMap.entries())));
}

/**
 * 根据字典的code 获取字典子集列表
 * @param code
 * @returns {Promise<*[]|*>}
 */
export async function getDictListByCode(code) {
    const res = await getDetailByDictCode(code);
    if (res.data.data) {
        let dictArr = [];
        const list = res.data.data;
        // 判断目前的语言
        list.forEach(item => {
            item.dictKey = getSysLanguage() === 'zh-CN' ? item.dictKey :item.keyEn;
            dictArr.push(item)
        })
        return dictArr;
    } else {
        const res = await getDictPullDownClientele({dictCode: code})
        return res.data.data;
    }
}

/**
 * 获取登录页面的icp对象
 * @returns {Promise<*[]|*>}
 * @param data
 */
export async function getTcpData(data) {
    await getSysConfigClienteleData(sysEnum.system.ipc_str).then(res => {
        if (res.data.status === 200) data.str = res.data.data.configValue
    });
    await getSysConfigClienteleData(sysEnum.system.ipc_url).then(res => {
        if (res.data.status === 200) data.url = res.data.data.configValue
    });
}
