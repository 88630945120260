import request from '../../request'


// 根据 售后单号 查询 成本费用 列表
export function listAffairCost(data) {
    return request({
        url: '/affairCost/listAffairCost',
        method: 'post',
        data
    })
}

// 新增
export function saveAffairCost(data) {
    return request({
        url: '/affairCost/saveAffairCost',
        method: 'post',
        data
    })
}

// 修改
export function updateAffairCost(data) {
    return request({
        url: '/affairCost/updateAffairCost',
        method: 'post',
        data
    })
}

// 删除
export function deleteAffairCostById(params) {
    return request({
        url: '/affairCost/deleteAffairCostById',
        method: 'get',
        params: params
    });
}




















// 新增事件流程
export function saveAffairDetail(data) {
    return request({
        url: '/affair/saveAffairDetail',
        method: 'post',
        data
    })
}

// 根据事件id获取未签收的物流
export function getAffairDetailByIdAndExp(params) {
    return request({
        url: '/affair/getAffairDetailByIdAndExp',
        method: 'get',
        params: params
    });
}

// 修改
export function updateAffair(data) {
    return request({
        url: '/affair/updateAffair',
        method: 'post',
        data
    })
}
