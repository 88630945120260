<!-- 富文本编辑器 / 预设消息 -->
<template>
  <el-dialog
      v-model="dialogFormVisible" :before-close="beforeClose" :title="titleName"
      width="620" align-center draggable class="mgs-dialog"
  >
    <el-form :rules="rules" ref="ruleForm" :model="form" label-width="140px" label-position="top" class="dialogBox">
      <el-form-item :label="$t('flowMessage.category')" prop="categoryDitch">
        <el-select
            filterable clearable
            v-model="form.categoryDitch"
            :placeholder="$t('flowMessage.categoryPlaceholder')"
            style="width: 100%"
        >
          <el-option v-for="item in ditchList" :key="item.dictValue" :label="item.dictKey" :value="item.dictValue"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('flowMessage.content')" prop="content">
        <el-input
            v-model="form.content"
            type="textarea"
            :placeholder="$t('flowMessage.contentPlaceholder')"
            rows="4"
        />
      </el-form-item>
    </el-form>
    <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeButton()">
            {{ $t('common.button.cancel') }}
          </el-button>
          <el-button type="primary" v-show="type !== 'detail'" @click="submitButton()"> {{ $t('common.button.confirm') }} </el-button>
        </span>
    </template>
  </el-dialog>
</template>

<script>

import {ElNotification} from "element-plus";
import {newFlowMessage} from "@/http/toolModule/flowMessageApi";
import {isTypeGetTitle} from "@/util/system";

export default {

  name: "EditorMgs",
  components: {},
  // data: 对象就是要渲染到页面上的数据
  data: function () {
    return {
      // 控制对话框大开闭
      dialogFormVisible: false,
      // 进入类型: 新增 详情 编辑
      type: '',
      titleName: this.$t('common.dialog.defaultTitle'),
      // 表单的只读
      isReadonly: false,
      // 下拉数据
      ditchList: [],
      // 行数据副本
      form: {},
      // 表单验证规则
      rules: {
        categoryDitch: [
          {required: true, message: this.$t('flowMessage.categoryPlaceholder'), trigger: 'change'},
        ],
        content: [
          {required: true, message: this.$t('flowMessage.contentPlaceholder'), trigger: 'blur'},
        ],
      },
    }
  },

  // watch 侦听器 能够侦听到data数据的变化 且获取对应的 新值 和 旧值
  watch: {
    type(newVal, oldVal) {
      this.titleName = isTypeGetTitle(newVal)
    }
  },

  // moubted: 页面加载完毕后执行
  mounted() {

  },

  // methods: 对象中的方法，可以在页面中调用
  methods: {
    // 点击遮罩和x时执行
    beforeClose(done) {
      this.closeButton();
      done(); // 必须调用 done，以继续关闭对话框
    },
    // 取消按钮
    closeButton() {
      this.dialogFormVisible= false;
      this.form = {};
      this.$refs.ruleForm.resetFields(); // 重置表单验证
    },
    // 确认按钮
    submitButton() {
      // 提交校验
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          newFlowMessage(this.form).then(res => {
            if (res.data.status === 200) {
              ElNotification({title: this.$t('common.message.successTitle'), message: res.data.msg, type: 'success',})
              this.closeButton();
              // 刷新列表
              this.$emit('operateClick');
            }
          });
        } else {
          return false;
        }
      });
    },
  },
}
</script>

<!--组件css样式 scoped:表示该样式只在此组件生效-->
<style scoped>
/deep/ .el-dialog__body {
  /*相對定位*/
  position: relative;
  padding: 0 20px !important;
}
.add-box {
  position: absolute;
  right: 30px;
  top: 65px;
  z-index: 99;
}
/deep/ .el-tabs__header {
  margin: 0px 64px 0px 0px;
}
.infinite-list {
  height: 400px;
  padding: 0;
  margin: 0;
  list-style: none;
}
.infinite-list .infinite-list-item {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 30px;
  border-bottom: 2px var(--el-color-primary-light-9) solid;
}
.infinite-list .infinite-list-item:hover {
  background: var(--el-color-primary-light-9);
  border-radius: 5px;
  cursor: pointer;
}
</style>
