<!-- 富文本编辑器弹出框版 -->
<template>
  <div id="MdEditor">
    <el-dialog v-model="dialogFormVisible" :before-close="beforeClose" :title='$t("elTag.editorTitle")' width="1200" align-center draggable>

      <editor-view ref="setEditor" :modeStr="'default'" style="height: 500px"/>

      <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeButton()">
          {{ $t('common.button.cancel') }}
        </el-button>
        <el-button type="primary" @click="submitButton()">
          {{ $t('common.button.confirm') }}
        </el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import EditorView from "@/components/editor/EditorView";

export default {

  name: "EditorDialog",
  components: {EditorView},
  // data: 对象就是要渲染到页面上的数据
  data: function () {
    return {
      // 控制对话框大开闭
      dialogFormVisible: false,
      html: '',
      type: 0,
    }
  },

  // moubted: 页面加载完毕后执行
  mounted() {

  },

  // methods: 对象中的方法，可以在页面中调用
  methods: {
    // 初始化赋值
    goto() {
      this.$nextTick(() => {
        this.$refs.setEditor.html = this.html;
      });
    },
    // 点击遮罩和x时执行
    beforeClose(done) {
      this.closeButton();
      done(); // 必须调用 done，以继续关闭对话框
    },
    // 取消按钮
    closeButton() {
      this.dialogFormVisible= false;
      this.type = 0;
      this.text = '';
    },
    // 确认按钮
    submitButton() {
      this.dialogFormVisible= false;
      let item = {
        type: this.type,
        text: this.$refs.setEditor.html,
      }
      this.$emit('operateClick', item);
    }
  },
}
</script>

<!--组件css样式 scoped:表示该样式只在此组件生效-->
<style scoped>

</style>
