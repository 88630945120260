<!-- 文件上传 组件 -->
<template>
  <el-form-item v-if="type === 1">
    <el-badge
      :value="files.length"
      style="width: 100%"
      class="item"
      :hidden="!showBadge || type != 1"
    >
      <el-upload
        style="width: 100%"
        class="upload-demo"
        drag
        :multiple="limit === 1 ? false : true"
        v-model:file-list="files"
        :limit="limit"
        :action="httpRrl + '/clientele/uploadFileClientele'"
        :data="fileData"
        :headers="headers"
        :show-file-list="!hiddenFileList"
        :on-progress="handleProgress"
        :on-error="handleError"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :on-exceed="handleExceed"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
      >
        <el-icon class="el-icon--upload"><upload-filled /></el-icon>
        <div class="el-upload__text">
          {{ $t("file.text") }} <em>{{ $t("file.textEm") }}</em>
        </div>
        <template #tip>
          <div class="el-upload__tip">
            {{ $t("file.textTip") }}
          </div>
        </template>
      </el-upload>
    </el-badge>
  </el-form-item>

  <div  v-if="type === 2">
  <el-badge
    style="width: 100%"
    :value="files.length"
    class="item"
    :hidden="!showBadge || type != 2"
  >
    <el-upload
      style="width: 100%"
      v-model:file-list="files"
      :show-file-list="!hiddenFileList"
      :on-progress="handleProgress"
      :on-error="handleError"
      class="upload-demo"
      :limit="limit"
      :action="httpRrl + '/uploadFile/uploadFile'"
      :data="fileData"
      :headers="headers"
      :multiple="limit === 1 ? false : true"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      :on-exceed="handleExceed"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <el-tooltip
        class="box-item"
        effect="dark"
        placement="bottom"
        :content="$t('common.message.uploadFileSizeError')"
      >
       <slot name="uploadBtn">
         <el-button type="primary">{{
          $t("common.button.uploadFile")
        }}</el-button>
       </slot>
      </el-tooltip>
    </el-upload>
  </el-badge>
</div>

 <div  v-if="type === 3">
  <el-badge
    style="width: 100%"
    :value="files.length"
    class="item"
    :hidden="!showBadge || type != 3"
  >
    <el-upload
     
      style="width: 100%; height: 100%"
      class="upload-demo"
      drag
      :multiple="limit === 1 ? false : true"
      v-model:file-list="files"
      :limit="limit"
      :action="httpRrl + '/clientele/uploadFileClientele'"
      :data="fileData"
      :headers="headers"
      :show-file-list="!hiddenFileList"
      :on-progress="handleProgress"
      :on-error="handleError"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      :on-exceed="handleExceed"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <el-icon class="el-icon--upload"><upload-filled /></el-icon>
      <div class="el-upload__text">
        {{ $t("file.text") }} <em>{{ $t("file.textEm") }}</em>
      </div>
      <template #tip>
        <div class="el-upload__tip">
          {{ $t("file.textTip") }}
        </div>
      </template>
    </el-upload>
  </el-badge>
 </div>
</template>

<script>
import { FILE_SIZE_LIMIT, HTTP_URL_API } from "@/enumbag/StyleEnum";
import { extractFileExtension } from "@/util/MyUtil";
import { getSysLanguage } from "@/util/system";
import { getFileDictList } from "@/http/authApi/clienteleApi";

export default {
  name: "FileUpload",
  // 在子组件内部 通过props+属性名称直接获取来自父组件的数据
  props: {
    hiddenFileList: {
      type: Boolean, // 根据实际情况指定数据类型
      default: false, // 如果需要确保该属性必须传递，设置为true
    },
    showBadge: {
      type: Boolean, // 根据实际情况指定数据类型
      default: false, // 如果需要确保该属性必须传递，设置为true
    },
    fileType: {
      type: Number, // 根据实际情况指定数据类型
      required: true, // 如果需要确保该属性必须传递，设置为true
    },
    fileFolder: {
      type: String, // 根据实际情况指定数据类型
      required: true, // 如果需要确保该属性必须传递，设置为true
    },
    limit: {
      type: Number, // 根据实际情况指定数据类型
      default: 6,
    },
    modelValue: {
      type: Array, //
      default: () => [], //
    },
  },
  // data: 对象就是要渲染到页面上的数据
  data: function () {
    return {
      type: 1, // 1_区域 2_按钮

      httpRrl: HTTP_URL_API, // 请求接口地址前缀
      // 请求接口需要携带的参数
      fileData: {
        folder: "file",
      },
      headers: {}, // 上传请求的请求头 存放token校验
      fileTypeList: [], // 文件上传类型

      files: [], // 文件上传对象列表
      fileList: [], // 文件上传地址列表
      fileNameList: [], // 文件上传地址和原始名称对象列表
    };
  },

  // moubted: 页面加载完毕后执行
  mounted() {
    this.type = this.fileType;
    this.fileData.folder = this.fileFolder;
  },
  // created : 页面加载完毕后执行
  created() {
    // 后端如果验证 token 会报空 因为请求不是异步不会被路由守卫装配token 所以需要在发送请求时带上token
    this.headers = {
      token: localStorage.getItem("token"),
      language: getSysLanguage(),
    };
    getFileDictList().then((res) => {
      if (res.data.status === 200) this.fileTypeList = res.data.data;
    });
  },

  // methods: 对象中的方法，可以在页面中调用
  methods: {
    handleProgress(evt, uploadFile, uploadFiles) {
      this.$emit("startUpload", evt, uploadFile, uploadFiles);
    },
    handleError(error, uploadFile, uploadFiles) {
      this.$emit("errorUpload", error, uploadFile, uploadFiles);
      this.$emit("endUpload");
    },

    // 上传文件之前的钩子 http://192.168.1.177:15892/tmp/test/2024-06-05/9bd37cfdfefc4efc910633a3f8cce3cd.mp4
    beforeAvatarUpload(file) {
      // 限制上传文件大小 20Mb
      const isLt2M = file.size < FILE_SIZE_LIMIT;
      if (!isLt2M) {
        this.$message.warning(this.$t("common.message.uploadFileSizeError"));
      }
      let ext = extractFileExtension(file.name);
      let isType = this.fileTypeList.includes(ext);
      if (!isType) {
        this.$message.warning(this.$t("common.message.uploadFileFormatErr"));
      }
      return isLt2M && isType;
    },
    // 上传之后的res结果集 /tmp/test/2024-06-05/9bd37cfdfefc4efc910633a3f8cce3cd.mp4
    handleAvatarSuccess(res, file) {
      if (res.status === 200) {
        if (this.files && this.files.length > 0) {
          let imgs = [];
          let imgNames = [];
          for (let i = 0; i < this.files.length; i++) {
            imgs.push(this.files[i].response.data);
            imgNames.push({
              file: this.files[i].response.data,
              name: this.files[i].name,
            });
          }
          this.fileList = imgs;
          this.fileNameList = imgNames;
        }
      } else {
        this.$message.warning(res.msg);
        let arr = [];
        for (const key of this.files) {
          if (key.response.status === 200) arr.push(key);
        }
        this.files = arr;
      }
      this.$emit("endUpload", this.files);
      this.$emit("update:modelValue", this.files);
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      if (this.files && this.files.length > 0) {
        let imgs = [];
        for (let i = 0; i < this.files.length; i++) {
          imgs.push(this.files[i].response.data);
        }
        this.fileList = imgs;
      }
      // console.log(file, fileList);
    },
    // 文件列表预览文件时的钩子
    handlePreview(file) {
      // console.log(file);
      // console.log(this.fileList, 'fileList');
    },
    // 文件超出个数限制时的钩子
    handleExceed(files, fileList) {
      // this.$message.warning(`当前限制选择 6 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      this.$message.warning(
        this.$t("common.message.uploadFileNumError", { number: this.limit })
      );
    },
    // 删除文件之前的钩子，参数为上传的文件和文件列表，若返回 false 或者返回 Promise 且被 reject，则停止删除。
    beforeRemove(file, fileList) {
      this.$emit("endUpload", this.files);

      return true;
    },
  },
};
</script>

<!--组件css样式 scoped:表示该样式只在此组件生效-->
<style scoped>
/* 引入外部我css样式 */
/* @import '@/assets/css/common.scss'; */
</style>
