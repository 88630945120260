<template>
  <!-- 发起新建工单事件 -->
  <el-dialog
      v-model="newAffairVisible"
      :before-close="eventBeforeClose"
      :title="$t('common.dialog.eventTitle')"
      width="750"
      draggable
  >
    <el-form
        :rules="eventRules"
        ref="eventForm"
        :model="newAffairForm"
        label-width="130px"
        label-position="top"
    >

      <el-form-item :label="$t('AfterSpu.afterOrderNumber')">
        <el-input v-model="newAffairForm.afterOrderNumber" :readonly="true"></el-input>
      </el-form-item>

      <el-form-item :label="$t('AfterSpu.flow.ditchName')" prop="ditchName">
        <el-select filterable v-model="newAffairForm.ditchName" :placeholder="$t('AfterSpu.flow.ditchNamePlaceholder')" style="width: 100%">
          <el-option v-for="item in selectData.ditchNameList" :key="item.dictValue" :label="item.dictKey" :value="item.dictValue"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('AfterSpu.flow.newDitchStatus')" prop="ditchStatus" v-if="newAffairForm.ditchName === 'SHSJ-003'">
        <el-select filterable v-model="newAffairForm.ditchStatusMaintain" :disabled="true" :placeholder="$t('AfterSpu.flow.newDitchStatusPlaceholder')" style="width: 100%">
          <el-option v-for="item in selectData.ditchStatusMaintainList" :key="item.dictValue" :label="item.dictKey" :value="item.dictValue"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('AfterSpu.flow.newDitchStatus')" prop="ditchStatus" v-else>
        <el-select filterable v-model="newAffairForm.ditchStatus" :disabled="true" :placeholder="$t('AfterSpu.flow.newDitchStatusPlaceholder')" style="width: 100%">
          <el-option v-for="item in selectData.ditchStatusList" :key="item.dictValue" :label="item.dictKey" :value="item.dictValue"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('AfterSpu.flow.newRemarks')">
        <el-input type="textarea" v-model="newAffairForm.remarks" :placeholder="$t('AfterSpu.flow.newRemarksPlaceholder')"></el-input>
      </el-form-item>

    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="newAffairClick">{{ $t('common.button.back') }}</el-button>
        <el-button type="primary" @click="newAffairAdd">
          {{ $t('common.button.submit') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>

import {getDictListByCode} from "@/util/longin";
import {ElLoading} from "element-plus";
import {saveAffairInquire} from "@/http/afterTicket/afterSpu/affairApi";

export default {

  name: "saveNotes",
  // data: 对象就是要渲染到页面上的数据
  data: function () {
    return {
      // 售后工单新增事件
      newAffairVisible: false,
      // 当前工单对象
      form: {},
      // 下拉数据
      selectData: {
        ditchNameList: [],
        ditchStatusMaintainList: [],
      },
      // 新增售后工单表单
      newAffairForm: {
        afterOrderNumber: '',
        ditchName: '',
        ditchStatus: 'LCZT-001',
        remarks: '',
      },
      // 事件框 - 表单验证规则
      eventRules: {
        ditchName: [
          {required: true, message: this.$t('AfterSpu.flow.ditchNamePlaceholder'), trigger: 'change'},
        ],
        ditchStatus: [
          {required: true, message: this.$t('AfterSpu.flow.newDitchStatusPlaceholder'), trigger: 'change'},
        ],
      },
    }
  },
  // moubted: 页面加载完毕后执行
  mounted() {

  },
  // methods: 对象中的方法，可以在页面中调用
  methods: {
    // 组件初始化
    initData(row) {
      this.form = row;
      this.getPull()
      this.newAffairVisible = true;
    },

    async getPull() {
      // 事件名称
      this.selectData.ditchNameList = await getDictListByCode('SHSJ');
      // 事件状态
      this.selectData.ditchStatusList = await getDictListByCode('LCZT');
      this.newAffairForm.ditchStatus = this.selectData.ditchStatusList[0].dictValue;
      this.selectData.ditchStatusMaintainList = await getDictListByCode('GDWX');
      this.newAffairForm.ditchStatusMaintain = this.selectData.ditchStatusMaintainList[0].dictValue;
      this.newAffairForm.afterOrderNumber = this.form.afterOrderNumber;
    },
    // 新增售后工单的事件
    newAffairAdd() {
      // 提交校验
      this.$refs.eventForm.validate((valid) => {
        if (valid) {
          const loading = ElLoading.service({
            lock: true, text: this.$t('common.loadingText'), background: 'rgba(0, 0, 0, 0.7)',}
          );
          this.newAffairForm.webUrl = window.location.origin + "/#" + "/ticket/";
          // 判断是事件是否是维修 是的话状态为维修状态的初始化状态
          if (this.newAffairForm.ditchName === 'SHSJ-003') {
            this.newAffairForm.ditchStatus = this.newAffairForm.ditchStatusMaintain;
          }
          this.newAffairForm.dataJson = JSON.stringify(this.newAffairForm);
          saveAffairInquire(this.newAffairForm).then(res => {
            if (res.data.status === 200) {
              this.newAffairVisible = false;
              this.newAffairClick();
              // 回调
              this.$emit('refreshListAndDetail');
            }
          }).finally(() => {
            // 请求无论成功/失败 都执行关闭loading
            loading.close();
          });
        } else {
          return false;
        }
      });
    },
    // 点击遮罩和x时执行
    eventBeforeClose(done) {
      this.newAffairClick();
      done(); // 必须调用 done，以继续关闭对话框
    },
    // 关闭
    newAffairClick() {
      this.newAffairVisible = false;
      this.newAffairForm = {
        afterOrderNumber: '',
        ditchName: '',
        ditchStatus: 'LCZT-001',
        remarks: '',
      };
      this.$refs.eventForm.resetFields(); // 重置表单验证
    },
  },
}
</script>

<!--组件css样式 scoped:表示该样式只在此组件生效-->
<style scoped lang="scss">

</style>
