import request from '../../request'


// 新增事件前的询问
export function saveAffairInquire(data) {
    return request({
        url: '/affair/saveAffairInquire',
        method: 'post',
        data
    })
}

// 新增
export function saveAffair(data) {
    return request({
        url: '/affair/saveAffair',
        method: 'post',
        data
    })
}

// 查询
export function listAffair(data) {
    return request({
        url: '/affair/listAffair',
        method: 'post',
        data
    })
}

// 新增事件流程
export function saveAffairDetail(data) {
    return request({
        url: '/affair/saveAffairDetail',
        method: 'post',
        data
    })
}

// 新增维修事件流程
export function saveMaintainAffairDetail(data) {
    return request({
        url: '/affair/saveMaintainAffairDetail',
        method: 'post',
        data
    })
}

// 根据事件id获取未签收的物流
export function getAffairDetailByIdAndExp(params) {
    return request({
        url: '/affair/getAffairDetailByIdAndExp',
        method: 'get',
        params: params
    });
}

// 修改
export function updateAffair(data) {
    return request({
        url: '/affair/updateAffair',
        method: 'post',
        data
    })
}
