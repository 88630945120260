import {convertDateTimeFormat, formatDate} from "@/util/DateUtil";
import {getSysLanguage} from "@/util/system";
import {i18n} from '@/main';
import {convertShanghaiTimeToTimeZone} from "@/util/zoneDate";

/**
 * 根据上一次的事件获取下一次的事件流程 key 作用于事件新增的下拉
 * @param value
 * @returns {string}
 */
export function setDictByValue(value) {
    let tk = "LCZT";
    let wx = "GDWX";
    // 将字符串拆分为前缀和后缀
    let parts = value.split("-");
    let prefix = parts[0];
    let suffix = parseInt(parts[1]);
    // 检查原始字符串是否包含子字符串
    if (value.includes(tk)) {
        if (suffix > 5) {
            return 'LCZT-006';
        }
    } else if (value.includes(wx)) {
        if (suffix > 7) {
            return 'GDWX-008';
        }
    } else {
        return '';
    }
    // 增加后缀的值
    suffix++;
    // 将后缀转换回字符串，并确保为三位数
    let newSuffix = suffix.toString().padStart(3, '0');
    // 组合成新的字符串
    let newString = prefix + "-" + newSuffix;
    return newString;
}

/**
 * 过滤对象的空值
 * @param obj
 * @returns {{}}
 */
export function removeEmptyProperties(obj) {
    const newObj = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] !== undefined && obj[key] !== null && obj[key] !== '') {
            newObj[key] = obj[key];
        }
    }
    return newObj;
}

/**
 * 获取文件名
 * @param filePath
 * @returns {*}
 */
export function extractFileName(filePath) {
    // 将路径按照路径分隔符进行拆分
    const parts = filePath.split(/[/\\]/);
    // 返回最后一个部分，即文件名
    return parts[parts.length - 1];
}

/**
 * 获取文件后缀
 * @param filePath
 * @returns {string|*}
 */
export function extractFileExtension(filePath) {
    // 获取文件名
    const fileName = filePath.split(/[/\\]/).pop();
    // 获取文件后缀
    const extension = fileName.split('.').pop();
    // 如果文件名中没有 . 或 . 在最后，说明没有后缀
    if (fileName === extension) {
        return ''; // 无后缀返回空字符串
    }
    return extension;
}

/**
 * 获取文件类型通过路径
 * @param filePath
 */
export const PICTURE = '1';
export const VIDEO = '2';
export const ELSETYPE = '3';
export function getFileType(filePath) {
    let ext = extractFileExtension(filePath)
    if (ext === '') return 0
    // 判断文件类型 和 视频类型
    if (ext === 'jpg' || ext === 'png' || ext === 'jpeg' || ext === 'gif') {
        return PICTURE
    } else if (ext === 'mp4' || ext === 'avi' || ext === 'mov' || ext === 'mkv' || ext === 'wmv' || ext === 'flv' || ext === 'rmvb' || ext === 'rm' || ext === '3gp' || ext === 'webm' || ext === 'ts' || ext === 'm4v' || ext === 'm4a') {
        return VIDEO
    } else {
        return ELSETYPE
    }
}

/**
 * 邮箱格式验证
 * @param email
 * @returns {boolean}
 */
export function emailVerify(email) {
    if (!email || email === '') {
        return false
    }
    // const emailReg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;
    const emailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
    return emailReg.test(email)
}

/**
 * 遍历数组 根据当前语言修改数组内的时间字段进行重新格式化 、 根据当前时区进行转换
 */
export function formatArray(dataList) {

    // 判断是否为空 语言为中文则不进行格式化 反之进行格式化
    if (dataList && dataList.length > 0) {

        // 获取当前时区
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // console.log('当前时区：', timeZone);

        dataList.forEach((item) => {

            if (timeZone !== 'Asia/Shanghai') {
                if (item.createTime) {
                    // console.log('东八区时间：', item.createTime)
                    item.createTime = convertShanghaiTimeToTimeZone(item.createTime, timeZone);
                    // console.log(timeZone, '时区时间：', item.createTime)
                }
                if (item.updateTime) {
                    item.updateTime = convertShanghaiTimeToTimeZone(item.updateTime, timeZone);
                }
                if (item.revampTime) {
                    item.revampTime = convertShanghaiTimeToTimeZone(item.revampTime, timeZone);
                }
                if (item.finishTime) {
                    item.finishTime = convertShanghaiTimeToTimeZone(item.finishTime, timeZone);
                }
                if (item.revokedTime) {
                    item.revokedTime = convertShanghaiTimeToTimeZone(item.revokedTime, timeZone);
                }
                if (item.registerDate) {
                    item.registerDate = convertShanghaiTimeToTimeZone(item.registerDate, timeZone);
                }
                if (item.updateDate) {
                    item.updateDate = convertShanghaiTimeToTimeZone(item.updateDate, timeZone);
                }
                if (item.loginDate) {
                    item.loginDate = convertShanghaiTimeToTimeZone(item.loginDate, timeZone);
                }
                if (item.loginTime) {
                    item.loginTime = convertShanghaiTimeToTimeZone(item.loginTime, timeZone);
                }
                if (item.opTime) {
                    item.opTime = convertShanghaiTimeToTimeZone(item.opTime, timeZone);
                }
                if (item.sendTime) {
                    item.sendTime = convertShanghaiTimeToTimeZone(item.sendTime, timeZone);
                }
            }

            if (getSysLanguage() !== 'zh-CN') {
                if (item.buyTime) {
                    item.buyTime = formatDate(item.buyTime);
                }
                if (item.createTime) {
                    item.createTime = convertDateTimeFormat(item.createTime);
                }
                if (item.updateTime) {
                    item.updateTime = convertDateTimeFormat(item.updateTime);
                }
                if (item.revampTime) {
                    item.revampTime = convertDateTimeFormat(item.revampTime);
                }
                if (item.finishTime) {
                    item.finishTime = convertDateTimeFormat(item.finishTime);
                }
                if (item.revokedTime) {
                    item.revokedTime = convertDateTimeFormat(item.revokedTime);
                }

                if (item.sendoutDate) {
                    item.sendoutDate = formatDate(item.sendoutDate);
                }

                if (item.birthDate) {
                    item.birthDate = formatDate(item.birthDate);
                }
                if (item.registerDate) {
                    item.registerDate = convertDateTimeFormat(item.registerDate);
                }
                if (item.updateDate) {
                    item.updateDate = convertDateTimeFormat(item.updateDate);
                }
                if (item.loginDate) {
                    item.loginDate = convertDateTimeFormat(item.loginDate);
                }
                if (item.loginTime) {
                    item.loginTime = convertDateTimeFormat(item.loginTime);
                }

                if (item.startDate) {
                    item.startDate = formatDate(item.startDate);
                }
                if (item.endData) {
                    item.endData = formatDate(item.endData);
                }

                if (item.opTime) {
                    item.opTime = convertDateTimeFormat(item.opTime);
                }
                if (item.sendTime) {
                    item.sendTime = convertDateTimeFormat(item.sendTime);
                }
            }

        })
    }
    return dataList;
}

/**
 * 根据当前语言修改对象的时间字段进行重新格式化 、 根据当前时区进行转换
 * @param data 对象 部分对应的时间字段为日期表单则不能对其进行格式化
 * @returns {*}
 */
export function formatData(data) {

    if (data) {
        // 获取当前时区
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // console.log('当前时区：', timeZone);
        if (timeZone !== 'Asia/Shanghai') {
            if (data.createTime) {
                data.createTime = convertShanghaiTimeToTimeZone(data.createTime, timeZone);
            }
            if (data.updateTime) {
                data.updateTime = convertShanghaiTimeToTimeZone(data.updateTime, timeZone);
            }
            if (data.revampTime) {
                data.revampTime = convertShanghaiTimeToTimeZone(data.revampTime, timeZone);
            }
            if (data.finishTime) {
                data.finishTime = convertShanghaiTimeToTimeZone(data.finishTime, timeZone);
            }
            if (data.revokedTime) {
                data.revokedTime = convertShanghaiTimeToTimeZone(data.revokedTime, timeZone);
            }
            if (data.registerDate) {
                data.registerDate = convertShanghaiTimeToTimeZone(data.registerDate, timeZone);
            }
            if (data.updateDate) {
                data.updateDate = convertShanghaiTimeToTimeZone(data.updateDate, timeZone);
            }
            if (data.loginDate) {
                data.loginDate = convertShanghaiTimeToTimeZone(data.loginDate, timeZone);
            }
        }

        if (getSysLanguage() !== 'zh-CN') {
            if (data.createTime) {
                data.createTime = convertDateTimeFormat(data.createTime);
            }
            if (data.updateTime) {
                data.updateTime = convertDateTimeFormat(data.updateTime);
            }
            if (data.revampTime) {
                data.revampTime = convertDateTimeFormat(data.revampTime);
            }
            if (data.finishTime) {
                data.finishTime = convertDateTimeFormat(data.finishTime);
            }
            if (data.revokedTime) {
                data.revokedTime = convertDateTimeFormat(data.revokedTime);
            }
            if (data.registerDate) {
                data.registerDate = convertDateTimeFormat(data.registerDate);
            }
            if (data.updateDate) {
                data.updateDate = convertDateTimeFormat(data.updateDate);
            }
            if (data.loginDate) {
                data.loginDate = convertDateTimeFormat(data.loginDate);
            }
        }
    }
    return data;
}

/**
 * 通过工单状态值获取其语言显示值
 */
export function getTicketStatus(status) {
    if (status === 1) {
        return i18n.global.t('pullDown.afterStatus.allocation')
    } else if (status === 2) {
        return i18n.global.t('pullDown.afterStatus.pending')
    } else if (status === 3) {
        return i18n.global.t('pullDown.afterStatus.processing')
    } else if (status === 4) {
        return i18n.global.t('pullDown.afterStatus.completed')
    } else if (status === 5) {
        return i18n.global.t('pullDown.afterStatus.revoked')
    }  else if (status === 6) {
        return i18n.global.t('pullDown.afterStatus.maintain')
    } else {
        return i18n.global.t('pullDown.afterStatus.unknown')
    }
}

/**
 * @description 根据对象的属性值进行分组
 * @param {*} arr 原始数组
 * @param {*} property  需要分组的字段属性
 * @returns 
 */

export  function groupByProperty(arr, property) {
    // 创建一个空对象来存储分组结果
    const grouped = {};

    // 遍历数组中的每个元素
    arr.forEach(item => {
        // 获取当前元素的分组键值
        const key = item[property];

        // 如果当前键值在grouped对象中不存在，则初始化为一个空数组
        if (!grouped[key]) {
            grouped[key] = [];
        }

        // 将当前元素添加到对应的分组中
        grouped[key].push(item);
    });

    // 将分组结果转换为二维数组
    return Object.values(grouped);
}

//递归拍平多级数组 data为多级数组 fieldName为需要拍平的字段名
export function flattenChildren(data,fieldName) {
    let result = [];
  
    function recurse(items) {
      items.forEach(item => {
        result.push(item);
        if (item[fieldName] && item[fieldName].length > 0) {
          recurse(item[fieldName]);
        }
      });
    }
  
    recurse(data);
    return result;
  }