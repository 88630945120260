<template>
  <div>
    <el-drawer
        v-model="drawer"
        size="700px"
        :title="$t('file.invoiceTable')"
        :direction="direction"
        :before-close="handleClose"
    >
      <div class="demo-drawer__content">
        <el-table
            :data="tableData"
            height="470"
            :empty-text="$t('common.table.noData')"
            stripe border fit highlight-current-row
            style="width: 100%; margin-bottom: 10px"
        >
          <el-table-column fixed type="index" width="70"/>
          <el-table-column prop="name" :label="$t('file.name')" show-overflow-tooltip></el-table-column>
          <el-table-column v-if="deleteVisible" prop="createTime" :label="$t('file.time')" width="180" show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" :label="$t('common.table.operation')" width="120">
            <template #default="scope">
              <el-button type="success" circle size="small" @click="downloadFile(scope.row.file)">
                <el-icon><Download /></el-icon>
              </el-button>
              <el-button type="danger" v-if="isFileShow" circle size="small" @click="deleteFile(scope.$index, scope.row)" >
                <el-icon><DeleteFilled /></el-icon>
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <FileUpload v-if="isFileShow"  @startUpload='fileUploadStatus=true'
                    @endUpload='fileUploadStatus=false' ref="fileUpload" :fileFolder="'sidebInvoice'" :fileType="1" />
      </div>
      <template #footer>
        <div style="float: left">
          <el-button type="primary" :disabled="fileUploadStatus" @click="ok" v-if="isFileShow">
            {{ $t('common.button.confirm') }}
          </el-button>
          <el-button type="info" @click="closeButton()">
            {{ $t('common.button.cancel') }}
          </el-button>
        </div>
      </template>
    </el-drawer>
  </div>
</template>

<script>



import FileUpload from "@/components/file/FileUpload.vue";
import {getSidebDetailInvoiceById, uploadSidebInvoice} from "@/http/sideTicket/sidebAfterApi";
import {ElNotification} from "element-plus";
import {HTTP_URL_API} from "@/enumbag/StyleEnum";
import {deleteFileStrById} from "@/http/system/fileApi";

export default {
  name: "invoiceFile",
  components: {FileUpload},
  // data: 对象就是要渲染到页面上的数据
  data: function () {
    return {
      fileUploadStatus:false,
      drawer: false,
      direction: 'rtl',

      isFileShow: false,
      data: {},
      tableData: [],
    }
  },
  // moubted: 页面加载完毕后执行
  mounted() {

  },
  // methods: 对象中的方法，可以在页面中调用
  methods: {
    // 打开抽屉
    initData(row, isFileShow) {
      this.data = row;
      this.isFileShow = isFileShow;
      this.getRowData();
      this.drawer = true;
    },
    // 获取列表数据
    getRowData() {
      getSidebDetailInvoiceById({
        id: this.data.id,
      }).then(res => {
        if (res.data.status === 200) {
          this.tableData = res.data.data;
        }
      });
    },
    // 行点击事件
    getRowClick(row) {

    },
    ok() {
      console.log()
      uploadSidebInvoice({
        sourceId: this.data.id,
        files: this.$refs.fileUpload.fileNameList,
      }).then(res => {
        if (res.data.status === 200) {
          this.closeFile()
          this.getRowData()
          ElNotification({title: this.$t('common.message.successTitle'), message: res.data.msg, type: 'success',})
        }
      });
    },
    closeFile() {
      if (this.isFileShow) {
        // 清除文件上传组件的文件列表 刷新产品发票列表
        this.$refs.fileUpload.fileNameList = []
        this.$refs.fileUpload.fileList = []
        this.$refs.fileUpload.files = []
      }
    },
    closeButton() {
      this.data = {}
      this.tableData = []
      this.closeFile()
      this.drawer = false;
    },
    handleClose(done) {
      this.closeButton();
      done();
    },

    downloadFile(url) {
      window.open(HTTP_URL_API + url);
    },
    deleteFile(index, row) {
      this.$confirm(this.$t('common.dialog.deleteTips') , this.$t('common.dialog.deleteTitle'), {
        confirmButtonText: this.$t('common.button.confirm'), cancelButtonText: this.$t('common.button.cancel'), type: 'warning'
      }).then(() => {
        // 后台请求删除数据库数据
        deleteFileStrById([row.id]).then(res => {
          if (res.data.status === 200) {
            // 删除前端渲染数组的数据
            this.tableData.splice(index, 1);
            ElNotification({title: this.$t('common.message.successTitle'), message: res.data.msg, type: 'success',})
          }
        })
      })
    },
  },
}
</script>

<!--组件css样式 scoped:表示该样式只在此组件生效-->
<style scoped>
/deep/ .el-drawer__header {
  margin-bottom: 0 !important;
}

/deep/ .el-tree-node__content {
  height: 33px !important;
  line-height: 33px !important;
}
.file-box {
  margin-top: 10px;
}
</style>
