<!-- 富文本编辑器弹完整版 -->
<template>
  <div style="height: 100%;" class="bor-box">
    <Toolbar
        class="bor-box"
        :editor="editor"
        :defaultConfig="toolbarConfig"
        :mode="mode"
    />
    <Editor
        style="height: 200px;"
        class="bor-box"
        v-model="html"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="onCreated"
    />
  </div>
</template>

<script>

import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import {DomEditor, i18nChangeLanguage} from '@wangeditor/editor'
import {getSysLanguage} from "@/util/system";
import {defaultExcludeKeys, simpleExcludeKeys} from "@/enumbag/StyleEnum";

export default {
  components: { Editor, Toolbar },
  props: {
    htmlTxt: {
      type: String,
      required: false
    },
    modeStr: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      editor: null,
      html: '',
      toolbarConfig: { },
      editorConfig: { placeholder: this.$t('elTag.editorTip') },
      mode: 'default',

      toolbar: null,
      curToolbarConfig : null,
    }
  },

  mounted() {
    // 初始化设置国际化和主题
    this.setI18nAndTheme();
  },

  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy()
  },

  methods: {
    setI18nAndTheme() {
      let language = getSysLanguage();
      if (language === 'en-US') {
        i18nChangeLanguage('en')
      } else if (language === 'zh-CN'){
        i18nChangeLanguage('zh-CN')
      }
      let themeSwitch = window.localStorage.getItem("themeSwitch");
      if (themeSwitch === 'true') {
        import("@/style/editor-dark.css");
      }
    },
    onCreated(editor) {
      this.editor = Object.seal(editor)
      this.mode = this.modeStr;
      // this.html = this.htmlTxt;
      if (this.mode === 'default') {
        this.toolbarConfig.excludeKeys = defaultExcludeKeys;
      } else {
        this.toolbarConfig.excludeKeys = simpleExcludeKeys;
      }
      this.$nextTick(() => {
        this.toolbar = DomEditor.getToolbar(editor)
        this.curToolbarConfig = this.toolbar.getConfig()
        // console.log(this.toolbar.getConfig().toolbarKeys, 'this.toolbar.getConfig().toolbarKeys')
      });
    },
  },
}
</script>

<!-- 导入样式 -->
<style src="../../../node_modules/@wangeditor/editor/dist/css/style.css"></style>
<!-- 组件样式 -->
<style scoped>
.bor-box {
  border: 1px solid #f1f1f1;
}
</style>
