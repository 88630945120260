import {Boot} from '@wangeditor/editor';

/**
 * 自定义菜单 (消息预设按钮)
 */
class EditorTxtButton {
    constructor() {
        // 自定义菜单标题
        this.title = 'Template Mgs';
        // 菜单显示图标
        this.iconSvg = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" data-v-d2e47025=""><path fill="currentColor" d="M832 384H576V128H192v768h640zm-26.496-64L640 154.496V320zM160 64h480l256 256v608a32 32 0 0 1-32 32H160a32 32 0 0 1-32-32V96a32 32 0 0 1 32-32m160 448h384v64H320zm0-192h160v64H320zm0 384h384v64H320z"></path></svg>' // 可选
        this.tag = 'button';
    }

    // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
    getValue(editor) {
        return '';
    }

    // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
    isActive(editor) {
        return false;
    }

    // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
    isDisabled(editor) {
        return false;
    }

    // 点击菜单时触发的函数
    exec(editor, value) {
        if (this.isDisabled(editor)) return;
        editor.insertText(value); // 插入文本
        // 触发 Vue 组件中的事件 | 在编辑器的@onCreated="onCreated"中进行监听
        editor.emit("editorTxtClick")
    }
}

export const editorTxtConf = {
    key: 'menuTxt', // 定义 menu key：要保证唯一、不重复（重要）
    factory() {
        return new EditorTxtButton(); // 实例化菜单
    }
};
Boot.registerMenu(editorTxtConf);
