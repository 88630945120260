import request from '../request'

/**
 * 无需登录的数据获取API类
 */
// 根据key获取系统对象
export function getSysConfigClienteleData(key) {
    return request({
        url: '/authNo/getSysConfigClienteleData',
        method: 'get',
        params: {
            key: key
        }
    })
}
